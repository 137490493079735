import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

import { ModalService } from '../_modal';
import { CompanyService } from './company.service';
import { CompanyUpsert } from './company-upsert';
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { switchMap } from 'rxjs/operators';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog.service';
import { environment } from '../../environments/environment';
import { UserInfoService } from '../services/user-info.service';

@Component({
  selector: 'app-company-upsert',
  templateUrl: './company-upsert.component.html',
  styleUrls: ['./company-upsert.component.css', '../quote/quote.component.css']
})

export class CompanyUpsertComponent implements OnInit {
  parentCompanies;
  roles;
  apiMSG;
  isNewCompany = false;
  activateProducts = false;
  newCompanyID;
  companyDetails = new CompanyUpsert();
  addressCompanyID;
  routeParamID;
  selectedIndex: number = 0;
  isCompanyPopup = false;
  brokerComission = false;
  imagePath: string = environment.imageURL;
  editMode: boolean = false;
  products = [];
  selectedProducts = [];
  maximum_broker_commission_and_ratained_commission: number = 0;
  maximum_broker_commission_and_ratained_commission_and_peliwica_pay_away_commission: number = 0;

  constructor(private formBuilder: FormBuilder, private companyService: CompanyService,
    private modalService: ModalService, private router: Router, private route: ActivatedRoute,
    private confirmDialogService: ConfirmDialogService, private userService: UserInfoService) {

  }

  ngOnInit(): void {
    this.userService.getUserDetails();

    // GET MAX COMMISSION LIMIT
    this.companyService.maxCommission(environment.EQUINE_ID)
    .subscribe(
      (response) => {
        if ((response as any).success) {
          this.maximum_broker_commission_and_ratained_commission= (response as any).result.maximum_broker_commission_and_ratained_commission; 
          this.maximum_broker_commission_and_ratained_commission_and_peliwica_pay_away_commission= (response as any).result.maximum_broker_commission_and_ratained_commission_and_peliwica_pay_away_commission; 
        } else {
          console.log((response as any).message);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    // GET COMPANY ROLES
    this.companyService.getroles()
      .subscribe(
        (response) => {
          if ((response as any).success) {
            this.roles = (response as any).result;
          } else {
            console.log((response as any).message);
          }
        },
        (error) => {
          console.log(error);
        }
      );

    // GET COMPANY BY ID
    if (this.route.snapshot.params.id) {
      this.isNewCompany = true;
      this.activateProducts = true;
      this.editMode = true;
      this.companyService.getCompanyByID(this.route.snapshot.params.id)
        .subscribe(
          (response: any) => {
            this.companyDetails = response.result;
            // GET PARENT COMPANIES LIST

            if (response.result.roles != null && response.result.roles.name == 'Broker') {
              this.brokerComission = true;
            }
            if (response.result.address == null) {
              this.companyDetails.address = new CompanyUpsert().address;
            }
            if (response.result.products) {
              this.selectedProducts = response.result.products;
              // response.result.products.forEach((res) => {
              //   this.selectedProducts.push(res.product_id);
              // });
            }
          },
          (error) => {
            console.log(error);
          }
        );
      this.getParetCompanies(this.route.snapshot.params.id);

    } else {
      // GET PARENT COMPANIES LIST
      this.getParetCompanies(0);
    }

    // GET PRODUCTS
    this.companyService.getProducts()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.products = response.result;
            // if(this.route.snapshot.params.id) {
            //   this.selectedProducts.forEach((res) => {
            //     alert(this.products.indexOf(res));
            //   })
            // }
            this.products.forEach((res) => {
              let index = this.selectedProducts.findIndex(x => x.product_id === res.id);
              res.is_checked = false;
            })
          }
        },
        (error) => {
          console.log(error);
        }
      );



  }

  getParetCompanies(company_id) {
    this.companyService.getParentCompanies(company_id)
      .subscribe(
        (response) => {
          if ((response as any).success) {
            this.parentCompanies = (response as any).result;
          } else {
            console.log((response as any).message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  roleChange(event) {
    let text = event.target.options[event.target.options.selectedIndex].text;
    if (text == 'Broker') {
      this.brokerComission = true;
    } else {
      this.brokerComission = false;
    }
  }

  /*
  * Get Uploaded Logo
  */
  fileName: string = '';
  fileToUpload: File = null;
  getUploadedFile(files) {
    this.fileToUpload = files.item(0);
    this.fileName = this.fileToUpload.name;
  }

  // Submit form
  reachedBrokerCommAndRetainedCommMaxLimit: boolean = false;
  reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit: boolean = false;
  onSubmit(f: NgForm) {
    let status = '0';
    if (f.value.status) {
      status = '1';
    }

    if(Number(this.companyDetails.commission_percentage) + Number(this.companyDetails.peliwica_ratained_commission) > this.maximum_broker_commission_and_ratained_commission) {
      this.reachedBrokerCommAndRetainedCommMaxLimit = true;
      return false;
    } 
    if(Number(this.companyDetails.commission_percentage) + Number(this.companyDetails.peliwica_ratained_commission) + Number(this.companyDetails.peliwica_pay_away_commission) > this.maximum_broker_commission_and_ratained_commission_and_peliwica_pay_away_commission) {
      this.reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit = true;
      return false;
    } 

    this.reachedBrokerCommAndRetainedCommMaxLimit = false;
    this.reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit = false;

    let data: any = f.value;

    let formData = new FormData();
    formData.append("logo_path", this.fileToUpload);
    formData.append("id", this.companyDetails.id);
    formData.append("name", this.companyDetails.name);
    formData.append("parent_id", this.companyDetails.parent_id);
    formData.append("contact_name", this.companyDetails.contact_name);
    formData.append("website", this.companyDetails.website);
    formData.append("terms_of_business", this.companyDetails.terms_of_business);
    formData.append("role_id", this.companyDetails.role_id);
    formData.append("registration_number", this.companyDetails.registration_number);
    formData.append("email", this.companyDetails.email);
    formData.append("phone", this.companyDetails.phone);
    formData.append("status", status);
    formData.append("commission_percentage", this.companyDetails.commission_percentage.toString());
    formData.append("peliwica_ratained_commission", this.companyDetails.peliwica_ratained_commission.toString());
    formData.append("peliwica_pay_away_commission", this.companyDetails.peliwica_pay_away_commission.toString());
    formData.append("uw_fees", this.companyDetails.uw_fees.toString());
    formData.append("loading_percentage", this.companyDetails.loading_percentage.toString());

    this.companyService.saveDetails(formData)
      .subscribe(
        (response) => {
          this.apiMSG = (response as any).message;
          if ((response as any).success) {
            this.companyDetails.id = (response as any).result.company_id;
            this.companyDetails.logo_path = (response as any).result.logo_path;
            this.isCompanyPopup = true;
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'success',
              yesNoBtns: true
            };
            this.confirmDialogService.open(options);
            this.confirmDialogService.confirmed().subscribe(confirmed => {
              if (confirmed) {
                this.selectedIndex += 1;
              }
            });
            this.newCompanyID = (response as any).result.company_id;
            if ((response as any).result.company_id != '') {
              this.isNewCompany = true;
              this.newCompanyID = (response as any).result.company_id;
            }

          } else {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'error',
              yesNoBtns: false
            };
            this.confirmDialogService.open(options);
            this.confirmDialogService.confirmed().subscribe(confirmed => {
              if (confirmed) {
                // this.updateCompany(data);
              }
            });
          }

        },
        (error) => {
          console.log(error);
        }
      );
  }



  tabChanged(event) {
    this.selectedIndex = event.index;
    this.products.forEach((res) => {
      let index = this.selectedProducts.findIndex(x => x.product_id === res.id);
      if (index >= 0) {
        res.is_checked = true;
      }

    })
    if (event.index == 1) {
      if (this.route.snapshot.params.id) {
        this.addressCompanyID = this.route.snapshot.params.id;

      } else {
        this.addressCompanyID = this.newCompanyID;
      }
    }
  }

  // NEXT BUTTON ACTION
  NextBtnAction() {
    this.selectedIndex += 1;
    this.modalService.close("successMsg");
  }

  // Previous BUTTON ACTION
  PreviousBtnAction() {
    this.selectedIndex = this.selectedIndex - 1;
  }


  // SAVE ADDRESS
  onAddressSubmit(fa: NgForm) {
    fa.value.status = 1;

    let data: any = fa.value;

    this.companyService.saveAddressDetails(data)
      .subscribe(
        (response) => {
          this.apiMSG = (response as any).message;
          if ((response as any).success) {
            this.activateProducts = true;
            this.companyDetails.address.id = (response as any).result.address_id;
            this.isCompanyPopup = false;
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'success',
              yesNoBtns: true
            };
            this.confirmDialogService.open(options);
            this.confirmDialogService.confirmed().subscribe(confirmed => {
              if (confirmed) {
                this.selectedIndex += 1;
              }
            });
          } else {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'error',
              yesNoBtns: false
            };
            this.confirmDialogService.open(options);
          }



        },
        (error) => {
          console.log(error);
        }
      );
  }

  /*
   * SAVE PRODUCTS
   */
  productIDS;
  onSubmitProducts(f: NgForm) {
    this.productIDS = [];
    this.products.forEach((value, index) => {
      if (value.is_checked) {
        this.productIDS.push(value.id);
      }
    });

    let data: any = {
      'company_id': this.companyDetails.id,
      'product_ids': this.productIDS.toString()
    };

    this.companyService.saveProducts(data)
      .subscribe(
        (response: any) => {
          const options = {
            title: '',
            message: response.message,
            imageType: 'success',
            yesNoBtns: false
          };
          this.confirmDialogService.open(options);
        }
      )
  }



}

<form #f="ngForm" (ngSubmit)="onSubmit(f)" enctype="multipart/form-data">
    <input type="hidden" name="id" placeholder="id" class="form-control form-control-sm" [(ngModel)]="quote.id">
    <div *ngFor="let statementOfFact of statementOfFacts; let i = index;">
        <div class="form-group row">
            <div class="col-md-12">
                <div class="quote_radio w-90">
                    <label class="p-3">
                        {{statementOfFact.description.replace('[HORSENAME]',this.quote.horse_details.name).replace('[POLICY_HOLDER_NAME]',quote.policy_holder.first_name + ' ' + quote.policy_holder.last_name)}}
                    </label>
                </div>
                <span (click)="helpTextPopup(content,statementOfFact.description)"><i
                        class="mdi mdi-comment-question-outline ml-1"></i></span>
            </div>
        </div> 
        <div class="form-group row" *ngIf="statementOfFact.type == 1 && quote.statement_of_facts.length > 0 && quote.statement_of_facts[i]">
            <div class="col-md-3"> 
                <mat-radio-group aria-label="Select an option"
                    name="statementOfFact_{{quote.statement_of_facts[i].question_id}}"
                    [(ngModel)]="quote.statement_of_facts[i].response" required #statementOfFact="ngModel">
                    <div class="quote_radio row mb-3">
                        <div class="col-md-6">
                            <mat-radio-button [value]="1" 
                                name="statementOfFact_{{quote.statement_of_facts[i].question_id}}"
                                ngDefaultControl [(ngModel)]="quote.statement_of_facts[i].response"
                                required #statementOfFactControl="ngModel">Yes</mat-radio-button>
                        </div>
                        <div class="col-md-6">
                            <mat-radio-button [value]="2" 
                                name="statementOfFact_{{quote.statement_of_facts[i].question_id}}"
                                ngDefaultControl [(ngModel)]="quote.statement_of_facts[i].response"
                                required #statementOfFactControl="ngModel">No</mat-radio-button>
                        </div>
                    </div>
                    <div *ngIf="f.submitted && statementOfFactControl.invalid" class="invalid-feedback col-md-12">
                        Please choose an option.
                    </div>
                </mat-radio-group>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-11" *ngIf="quote.statement_of_facts[i] && ((quote.statement_of_facts[i].response == 1 && (statementOfFact.id !='4c890e3b-9a87-4f01-9454-4201187a7e60' && statementOfFact.id !='4b890e3b-9a87-4f01-9454-4201187a7e59')) || (quote.statement_of_facts[i].response == 2 && (statementOfFact.id =='4c890e3b-9a87-4f01-9454-4201187a7e60' || statementOfFact.id =='4b890e3b-9a87-4f01-9454-4201187a7e59')))">
                <textarea name="response_text_{{quote.statement_of_facts[i].question_id}}"
                    class="form-control" placeholder="Please provide response text"
                    [(ngModel)]="quote.statement_of_facts[i].response_text" #response_text="ngModel"
                    required [ngClass]="{ 'is-invalid': f.submitted && response_text.invalid }">
                </textarea>
                <div *ngIf="f.submitted && response_text.invalid" class="invalid-feedback">
                    Response text is required.
                </div>
            </div>
        </div>
        <div class="form-group row" *ngIf="statementOfFact.type == 2">
            <div class="col-md-11">
                <input class="form-control" type="textbox" placeholder="Please provide details"
                    name="statementOfFact_{{statementOfFact.id}}" [(ngModel)]="statementOfFact.response_text">
            </div>
        </div>
    </div>

    <div class="form-group row">
        <div class="col-md-11">
            <label>Please upload any Clinical History, Veterinary Certificates/Reports, Purchase Reciepts or other
                relevant information</label><br>
            <div class="form-group">
                <a (click)="uploadDocumentBtn(documents)" class="btn btn-success">Upload Document</a>
            </div>
        </div>
    </div>
    <ag-grid-angular
    style="width: 100%; height: 230px;float:left;"
    class="ag-theme-bootstrap"
    [gridOptions]="gridOptions"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    animateRows
    pagination
    paginationPageSize = 5
    rowHeight = 27
    headerHeight=35
    (rowClicked)='onGridRowClicked($event,documents,documentsDelete)' 
    >
</ag-grid-angular>
    <div class="form-group">
        <a (click)="backToFullDetailsBtn()" class="btn btn-default btn-gray mr-3">Back</a>
        <input type="submit" name="proceed" class="btn btn-success btn-gray" value="Submit for Underwriting">
    </div>
</form>
<ng-template #content let-modal>
    <div class="modal-body">
        <div [innerHTML]="helptext.replace('[HORSENAME]',this.quote.horse_details.name)"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
    </div>
</ng-template>

<ng-template #documents let-modal>
    <div class="modal-header">
        <h4>Document</h4>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label>Title</label>
            <input type="text" name="document_title" [(ngModel)]="docTitle" class="form-control form-control-sm">
        </div>
        <div class="form-group" *ngIf="!documentsEditMode">
            <label>Document</label>
            <div class="custom-file">
                <input type="file" name="file_name" (change)="getUploadedFile($event.target.files)"
                    class="custom-file-input" id="customFile">
                <label class="custom-file-label" for="customFile">{{quote.quote_document}}</label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" *ngIf="!documentsEditMode" class="btn btn-success" (click)="documentUpload()">Upload</button>
        <button type="button" *ngIf="documentsEditMode" class="btn btn-success" (click)="updateDocument()">Update</button>
        <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
    </div>
</ng-template>

<ng-template #documentsDelete let-modal>
    <div class="modal-header">
        <h4>Delete Document</h4>
    </div>
    <div class="modal-body text-center">
        <img src="assets/images/deactivate.png" width="50"><br>
        Are you sure you want to delete document {{docTitle}}?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="deleteDocument()">Yes</button>
        <button type="button" class="btn btn-default" (click)="modal.close()">No</button>
    </div>
</ng-template>
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SharedService } from '../services/shared.service';
import { CompanyUpsert }    from './company-upsert';

 
@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private sharedService: SharedService,private httpClient: HttpClient) { }

  // GET LIST OF PARENT COMPANIES
  getParentCompanies(company_id) {
    return this.sharedService.getAPI('companies/parent/' + company_id);
  }

  // GET LIST OF COMPANY ROLES
  getroles() {
    return this.sharedService.getAPI('roles');
  }
  

  // SAVE COMPANY DETAILS
  saveDetails(data) {
    return this.sharedService.postFormData('company',data);
  }
  // SAVE COMPANY DETAILS
  saveAddressDetails(data) {
    return this.sharedService.postAPI('company/address',data);
  }

  // GET LIST OF ALL COMPANIES
  getAllCompanies() {
    return this.sharedService.getAPI('companies');
  }

  // GET Company DETAILS BY ID 
  getCompanyByID(id) {
    //return this.httpClient.get<CompanyUpsert>('company/' + id);
    return this.sharedService.getAPI('company/' + id);
    
  }

  // GET PRODUCTS
  getProducts() {
    return this.sharedService.getAPI('products');
  }
  // SAVE PRODUCTS
  saveProducts(data) {
    return this.sharedService.postAPI('company/products',data);
  }

  // GET COMMISSION LIMIT
  maxCommission(company_id) {
    return this.sharedService.getAPI('staticdata/get_maximum_commission_limit/' + company_id);
  }
}

export class QuoteList {
    company_id: string = '';
    policy_holder: string = '';
    broker_id: string = '';
    quote_ref_number: string = '';    
    insurance_status: string = '';    
    type: number = 0;    
    from: any = '';    
    to: any = '';    
    total_records: number = 0;    
    total_net_rate: number = 0;    
    total_gross_premium: number = 0;    
    total_fees: number = 0;    
    product_id: string = '';    
}

import { Injectable } from '@angular/core';
import { SharedService } from '../services/shared.service';
import { LocalStorageService } from 'ngx-webstorage';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  constructor(private sharedService: SharedService, private sessionSt: LocalStorageService) { }

  // Get Agreed Questions
  getAgreedQues(equine_id) {
    if (equine_id) {
      return this.sharedService.getAPI('staticdata/agreed_questions/' + equine_id);
    }
  }

  // Get All Static Data
  getStaticData(rating_version) {
    return this.sharedService.getAPI('staticdata/index/' + rating_version);
  }

  // Get Policy Data By Horse Value
  getPolicyData(class_value, policy_cover_index, horse_value, rating_version) {
    return this.sharedService.getAPI('staticdata/get_policy_data_by_horse_value/' + class_value + '/' + policy_cover_index + '/' + horse_value + '/' + rating_version);
  }

  // Get All Brokers
  getBrokers(product_type = 1) {
    return this.sharedService.getAPI('companies/allbrokersbyloggedinuser/' + this.sessionSt.retrieve('userInfo').id + '/' + product_type);
  }

  // Get Horse Static Data
  getHorseDetails() {
    return this.sharedService.getAPI('staticdata/horse');
  }

  // Save Indicative Quote
  saveIndicativeQuote(data) {
    return this.sharedService.postAPI('quote', data);
  }

  // Get Statement Of Fact Details
  getStatementOfFacts(product_id) {
    if (product_id) {
      return this.sharedService.getAPI('staticdata/statement_of_fact/' + product_id);
    }
  }

  // Get All Quotes
  getAllQuotes(company_id) {
    if (company_id) {
      return this.sharedService.getAPI('quotes/company/' + company_id);
    }
  }

  // Save Quote Document
  saveQuoteDocument(formData, is_policy = 0) {
    if (is_policy == 1) {
      return this.sharedService.postFormData('policy/document', formData);
    } else {
      return this.sharedService.postFormData('quote/document', formData);
    }
  }

  // POSTCODE LOOKUP
  getAddressPostcodeLookup(postcode) {
    return this.sharedService.getOtherAPI(environment.ADDRESSLOOKUP_API_URL + postcode + '?api-key=' + environment.ADDRESSLOOKUP_API_KEY + '&expand=true&fuzzy=true');
  }

  // Search Quote Details
  searchQuote(data) {
    return this.sharedService.postAPI('quotes/search', data);
  }

  // Get Quote Details
  getQuoteDetails(quote_meta_id) {
    if (quote_meta_id) {
      return this.sharedService.getAPI('quote/' + quote_meta_id);
    }
  }

  // Save Endorsement
  saveEndorsement(data) {
    return this.sharedService.postAPI('quote/endorsement/save', data);
  }
  // Get Endorsement
  getEndorsement(quote_meta_id) {
    if (quote_meta_id) {
      return this.sharedService.getAPI('quote/endorsement/list/' + quote_meta_id);
    }
  }

  // Delete Endorsement
  deleteEndorsement(quote_meta_id) {
    let data = {
      "id": quote_meta_id
    }
    return this.sharedService.postAPI('quote/endorsement/delete', data);
  }

  // Get Documents
  getQuoteDocuments(quote_id, type = 1) {              // Type 1: quote, 2: policy
    if (quote_id) {
      return this.sharedService.getAPI('quote/documents/' + quote_id + '/' + type);
    }
  }
  // Download Documents
  downloadDocument(documentID) {
    return this.sharedService.download('download/' + documentID);
  }

  // Save Notes
  saveNotes(data) {
    return this.sharedService.postAPI('quote/notes/save', data);
  }

  // Get Notes
  getNotes(quote_id) {
    if (quote_id) {
      return this.sharedService.getAPI('quote/notes/list/' + quote_id);
    }
  }

  // Delete Notes
  deleteNotes(quote_meta_id) {
    let data = {
      "id": quote_meta_id
    }
    return this.sharedService.postAPI('quote/notes/delete', data);
  }

  // Get Underwriters
  getUnderwriters() {
    return this.sharedService.getAPI('user/list/peliwica');
  }

  // Get Insurers
  getInsurers() {
    return this.sharedService.getAPI('user/list/insurer');
  }

  //Get Quote Versions
  getQuoteVersions(quote_meta_id) {
    if (quote_meta_id) {
      return this.sharedService.getAPI('quote/versions/' + quote_meta_id);
    }
  }
  //Get Quote->policy Versions
  getQuotePolicyVersions(quote_meta_id) {
    if (quote_meta_id) {
      return this.sharedService.getAPI('document/quote/versions/' + quote_meta_id);
    }
  }

  // Update Quote Status
  updateQuoteStatus(data) {
    return this.sharedService.postAPI('quote/status', data);
  }

  // Update Quote Status
  updateAnyQuoteStatus(data) {
    return this.sharedService.postAPI('quote/update_quote_status', data);
  }

  // Convert To Policy
  convertToPolicy(data) {
    return this.sharedService.postAPI('quote/convert_to_policy', data);
  }


  // Get Quote Types
  getQuoteTypes(type = 0) {
    return this.sharedService.getAPI('staticdata/get_policy_type/' + type);
  }

  /*
  * Get Quote Details By Quote ID
  * Type => 0: Quote, 1: Policy
  */
  getQuoteDetailsByQuoteID(quote_id, type = 0) {
    if (quote_id) {
      return this.sharedService.getAPI('quote/details/' + quote_id + '/' + type);
    }
  }

  // Update Document Details
  updateDocument(data) {
    return this.sharedService.postAPI('quote/document/edit', data);
  }

  // Delete Document Details
  deleteDocument(data) {
    return this.sharedService.postAPI('quote/document/delete', data);
  }

  // Get Claims
  getQuoteClaims(quote_id) {
    if (quote_id) {
      return this.sharedService.getAPI('quote/claims/' + quote_id);
    }
  }

  // Get Claims By ID
  getQuoteClaimsByID(id) {
    if (id) {
      return this.sharedService.getAPI('quote/claim/' + id);
    }
  }

  // Save Claims
  saveClaims(data) {
    return this.sharedService.postAPI('quote/claim', data);
  }
  // Save Claims
  saveClaimDocument(formData) {
    return this.sharedService.postFormData('quote/claim/document', formData);
  }
  // Get Claim Documents
  getClaimDocuments(claim_id, claim_doc_type) {
    if (claim_id && claim_doc_type) {
      return this.sharedService.getAPI('quote/claim/documents/' + claim_id + '/' + claim_doc_type);
    }
  }

  // Delete Quote
  deleteQuote(data) {
    return this.sharedService.postAPI('quote/delete', data);
  }

  downloadDocs(fileDetails) {
    return this.sharedService.download('download/' + fileDetails.id)
      .subscribe((response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        let fileExt = fileDetails.file_name.substr(fileDetails.file_name.lastIndexOf('.') + 1);
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        downloadLink.setAttribute('download', fileDetails.title + "." + fileExt);
        document.body.appendChild(downloadLink);
        downloadLink.click()
      },
        error => console.log('oops', error)
      );
  }

  getEmailsList(quote_id) {
    if (quote_id) {
      return this.sharedService.getAPI('emails/' + quote_id);
    }
  }
  getEmailsDetails(email_id) {
    if (email_id) {
      return this.sharedService.getAPI('email/' + email_id);
    }
  }
  sendEmail(formData) {
    return this.sharedService.postFormData('email', formData);
  }
  submitVetCertificate(data) {
    return this.sharedService.postAPI('quote/document/submit_vet_certificate', data);
  }
  bdxOptIn(data) {
    return this.sharedService.postAPI('quote/bdx_opt_in', data);
  }

  lapseQuote(data) {
    return this.sharedService.postAPI('quote/lapse_quote', data);
  }

}


<!-- Endorsement -->
<div class="form_sections">
    <p>Endorsement <a (click)="addEndorsement(content)" *ngIf="isNotBroker" class="btn btn-success btn-sm ml-3">Add
            Endorsement</a></p>
    <ag-grid-angular style="width: 100%;min-height: 300px;" class="ag-theme-bootstrap mt-3" [gridOptions]="gridOptions"
        [rowData]="rowData" [columnDefs]="columnDefs" animateRows pagination=false paginationPageSize=5 rowHeight=100
        [defaultColDef]="defaultColDef"
        headerHeight=35 domLayout='autoHeight' [editType]='editType'
        (columnResized)="onColumnResized($event)"
        (rowClicked)='onGridRowClicked($event,content,deleteModel,endorsementViewModel)'>
    </ag-grid-angular>
</div>

<ng-template #content let-modal>
    <form #f="ngForm" (ngSubmit)="f.form.valid && onEndorsementSubmit(f)">
        <div class="modal-body">
            <div class="form-group">
                <label>Title</label>
                <input type="text" name="title" class="form-control form-control-sm" [(ngModel)]="endorsement.title"
                    [ngClass]="{ 'is-invalid': f.submitted && title.invalid }" #title="ngModel" required>
                <div *ngIf="f.submitted && title.invalid" class="invalid-feedback">
                    Title is required.
                </div>
            </div>
            <div class="form-group">
                <label>Type</label>
                <select [(ngModel)]="endorsement.type" class="form-control form-control-sm" name="endorsement_type"
                    [ngClass]="{ 'is-invalid': f.submitted && endorsement_type.invalid }" #endorsement_type="ngModel"
                    required style="border: 1px solid #f1f6f8;">
                    <option [value]="">Please select</option>
                    <option [value]="1">Endorsement</option>
                    <option [value]="2">Subjectivity</option>
                    <option [value]="3">Exclusion</option>
                </select>
                <div *ngIf="f.submitted && endorsement_type.invalid" class="invalid-feedback">
                    Type is required.
                </div>
            </div>
            <div class="form-group">
                <label>Endorsement</label>
                <textarea name="endorsement_text" class="form-control" [(ngModel)]="endorsement.endorsement_text"
                    rows="5" required #endorsement_text="ngModel"
                    [ngClass]="{ 'is-invalid': f.submitted && endorsement_text.invalid }"></textarea>
                <div *ngIf="f.submitted && endorsement_text.invalid" class="invalid-feedback">
                    Endorsement text is required.
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" name="submit" class="btn btn-success btn-sm pl-4 pr-4 pt-2 pb-2">Save</button>
            <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
        </div>
    </form>
</ng-template>
<ng-template #deleteModel let-modal>
    <div class="modal-body">
        <p>Are you sure you want to delete endorsement?</p>
    </div>

    <div class="modal-footer">
        <button type="button" name="ok" (click)="deleteEndorsement(endorsement.id,selectedRow)"
            class="btn btn-success btn-sm pl-4 pr-4 pt-2 pb-2">OK</button>
        <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
    </div>
</ng-template>
<ng-template #endorsementViewModel let-modal>
    <div class="modal-body">
        {{ endorsementText }}
    </div>
</ng-template>
// import { Quote } from '@angular/compiler';
// import { parse } from 'path';

export class CalculationsDetails {
    full_class_net_rate: number = 0.0;
    full_class_net_rate_amount: number = 0.0;
    full_vet_fee_amount: number = 0.0;
    full_net_vet_fee_amount: number = 0.0;
    full_vet_fee_excess_amount: number = 0.0;
    full_vet_fee_excess_discount: number = 0.0;
    full_public_liability_amount: number = 0.0;
    full_personal_accident_amount: number = 0.0;
    full_net_public_liability_amount: number = 0.0;
    full_net_personal_accident_amount: number = 0.0;
    full_main_discount: number = 0.0;
    full_class_gross_amount: number = 0.0;
    full_vet_gross_amount: number = 0.0;
    full_public_gross_amount: number = 0.0;
    full_net_Premium_amount: number = 0.0;
    full_personal_gross_amount: number = 0.0;
    full_ipt_gross_amount: number = 0.0;
    full_total_gross_primum: number = 0.0;
    full_total_premium: number = 0.0;
    full_total_amount_payable: number = 0.0;
    full_broker_amount: number = 0.0;
    full_co_pay_discount = 0.0;

    aeio_class_net_rate: number = 0.0;
    aeio_class_net_rate_amount: number = 0.0;
    aeio_vet_fee_amount: number = 0.0;
    aeio_net_vet_fee_amount: number = 0.0;
    aeio_vet_fee_excess_amount: number = 0.0;
    aeio_vet_fee_excess_discount: number = 0.0;
    aeio_public_liability_amount: number = 0.0;
    aeio_personal_accident_amount: number = 0.0;
    aeio_net_public_liability_amount: number = 0.0;
    aeio_net_personal_accident_amount: number = 0.0;
    aeio_net_Premium_amount: number = 0.0;
    aeio_main_discount: number = 0.0;
    aeio_class_gross_amount: number = 0.0;
    aeio_vet_gross_amount: number = 0.0;
    aeio_public_gross_amount: number = 0.0;
    aeio_personal_gross_amount: number = 0.0;
    aeio_total_gross_primum: number = 0.0;
    aeio_ipt_gross_amount: number = 0.0;
    aeio_total_premium: number = 0.0;
    aeio_total_amount_payable: number = 0.0;
    aeio_broker_amount: number = 0.0;
    aeio_co_pay_discount = 0.0;

    broker_commission: number = 0.0;
    peliwica_pay_away_commission: number = 0.0;
    peliwica_retained_commission: number = 0.0;
    ipt_fee: number = 0.0;
    full_underwriting_fee: number = 0.0;
    aeio_underwriting_fee: number = 0.0;
    sum_insured: number = 0.0;
    policy_minimum_premium: number = 0.0;
    class_minimum_premium: number = 0.0;
    vet_minimum_premium: number = 0.0;
    sat_minimum_premium: number = 0.0;
    trailer_minimum_premium: number = 0.0;

    sat_sum_insured: number = 0.0;
    trailer_sum_insured: number = 0.0;
    sat_net_rate: number = 0.0;
    trailer_net_rate: number = 0.0;
    sat_net_premium: number = 0.0;
    trailer_net_premium: number = 0.0;

    sat_gross_amount: number = 0.0;
    trailer_gross_amount: number = 0.0;
    class_age_loading_amount: number = 0.0;
    vet_age_loading_amount: number = 0.0;

    life_saving_surgery: number = 0.0;
    is_life_saving_surgery: boolean = false;
    annual_agg_life_saving: number = 0.0;

    premium_loading: number = 0;
    total_premium_loading: number = 0;
    premium_loading_percentage: number = 0;
    is_mta: boolean = false;
    is_cancel: boolean = false;
    is_lapse: boolean = false;
    is_NTU: boolean = false;
    mta_date;
    tigerlab_premium_loading: number = 0;
    old_tigerlab_premium_loading: number = 0;
    old_premium_loading_percentage: number = 0;

    //mta_quote_data ;
    public full_calculate_data(mta_quote_data) {
        //this.mta_quote_data = mta_quote_data;
        // let IsMTA = true;
        let total_commission = Number(this.broker_commission) + Number(this.peliwica_pay_away_commission) + Number(this.peliwica_retained_commission); // main class
        // let oCalculationModel : CalculationModel;
        var net_rate_amount = this.sum_insured * Number(this.full_class_net_rate) / 100;

        // oCalculationModel.sum_insured = this.sum_insured;
        this.full_class_net_rate_amount = this.main_class_net(net_rate_amount, this.class_minimum_premium,
            this.class_age_loading_amount, false, this.full_main_discount, this.full_vet_fee_excess_discount, this.full_co_pay_discount);



        this.full_class_gross_amount = this.gross_amount(this.full_class_net_rate_amount, total_commission);
        // Vat Fee
        this.full_net_vet_fee_amount = this.main_class_net(this.full_vet_fee_amount, this.vet_minimum_premium,
            this.vet_age_loading_amount, true, this.full_main_discount, this.full_vet_fee_excess_discount, this.full_co_pay_discount);
        this.full_vet_gross_amount = this.gross_amount(this.full_net_vet_fee_amount, total_commission);

        // public liabilty 
        this.full_net_public_liability_amount = this.main_class_net(this.full_public_liability_amount, 0, 0, false, this.full_main_discount,
            this.full_vet_fee_excess_discount, this.full_co_pay_discount);
        this.full_public_gross_amount = this.gross_amount(this.full_net_public_liability_amount, total_commission);
        // Personal Accident 
        this.full_net_personal_accident_amount = this.main_class_net(this.full_personal_accident_amount, 0, 0, false, this.full_main_discount
            , this.full_vet_fee_excess_discount, this.full_co_pay_discount);
        this.full_personal_gross_amount = this.gross_amount(this.full_net_personal_accident_amount, total_commission);
        // Saddlery and Tack 
        var sat_net_rate_amount = parseFloat(Number(this.sat_sum_insured * this.sat_net_rate / 100).toFixed(2));
        this.sat_net_premium = this.main_class_net(sat_net_rate_amount, this.sat_minimum_premium, 0, false, 0
            , this.full_vet_fee_excess_discount, 0);

        this.sat_gross_amount = this.gross_amount(this.sat_net_premium, total_commission);
        //  Trailers and Horsedrawn Vehicles
        var trailer_net_rate_amount = parseFloat(Number(this.trailer_sum_insured * this.trailer_net_rate / 100).toFixed(2));
        this.trailer_net_premium = this.main_class_net(trailer_net_rate_amount, this.trailer_minimum_premium, 0, false, 0,
            this.full_vet_fee_excess_discount, 0);
        this.trailer_gross_amount = this.gross_amount(this.trailer_net_premium, total_commission);

        // calcualte primum loading amount 

        var net_policy_amount = parseFloat(Number((this.full_class_net_rate_amount) + (this.full_net_vet_fee_amount) + (this.full_net_public_liability_amount) + (this.full_net_personal_accident_amount)
            + (this.trailer_net_premium) + (this.sat_net_premium)).toFixed(2));;
        this.premium_loading = this.calculate_percentage_amount(net_policy_amount, this.premium_loading_percentage);

        this.total_premium_loading = this.gross_amount(this.premium_loading, total_commission);


        this.full_net_Premium_amount = parseFloat(Number((this.full_class_net_rate_amount) + (this.full_net_vet_fee_amount) + (this.full_net_public_liability_amount) + (this.full_net_personal_accident_amount)
            + (this.trailer_net_premium) + (this.sat_net_premium) + Number(this.premium_loading)).toFixed(2));;


        this.full_total_gross_primum = parseFloat(Number((this.full_class_gross_amount) + (this.full_vet_gross_amount) + (this.full_public_gross_amount) + (this.full_personal_gross_amount)
            + (this.trailer_gross_amount) + (this.sat_gross_amount) + (this.total_premium_loading) + Number ( this.tigerlab_premium_loading)).toFixed(2));;

        let minimum_premium :number = 0.0;
        minimum_premium = this.gross_amount(this.policy_minimum_premium, total_commission);

        if (Number(this.full_total_gross_primum) < minimum_premium) {
            //this.full_total_gross_primum = Number(this.policy_minimum_premium); 
            this.full_total_gross_primum = this.gross_amount(this.policy_minimum_premium, total_commission);
        }
        else {
            // this.full_total_gross_primum =  this.gross_amount(this.full_total_gross_primum, total_commission);  
        }


        var Difference_In_Days_cancle;
        var Difference_In_Days_old;

        if (this.is_mta) {
            if (parseFloat(Number(mta_quote_data.mta_sum_policy_premium).toFixed(2)) != parseFloat(Number(this.full_total_gross_primum).toFixed(2))) {

                this.full_total_gross_primum = parseFloat(Number(Number(this.full_total_gross_primum) - Number(mta_quote_data.mta_sum_policy_premium)).toFixed(2));
                let newDate = new Date(this.mta_date);
                let policy_end_date = new Date(mta_quote_data.latest_policy_end_date)
                var Difference_In_Time = (policy_end_date.getTime() - newDate.getTime()) + 86400000;
                var Difference_In_Days_MTA = Difference_In_Time / (1000 * 3600 * 24);

                let oldDate = new Date(mta_quote_data.latest_policy_start_date);
                // let policy_end_date = new Date(mta_quote_data.policy_end_date)
                Difference_In_Time_old = (policy_end_date.getTime() - oldDate.getTime()) + 86400000;
                Difference_In_Days_old = Difference_In_Time_old / (1000 * 3600 * 24);

                this.full_total_gross_primum = parseFloat(Number(this.full_total_gross_primum / Number(Difference_In_Days_old) * Number(Difference_In_Days_MTA)).toFixed(2));
                
                if (this.full_total_gross_primum  <= 0.10 && this.full_total_gross_primum >= -0.10)
                {
                    this.full_total_gross_primum  = 0;
                }
            }
            else {
                this.full_total_gross_primum = 0
            }
            
        }


        if (this.is_cancel) {
            // this.full_total_gross_primum =parseFloat(Number(Number(this.full_total_gross_primum)-Number(mta_quote_data.quote_versions[0].financial_details.policy_premium)).toFixed(2));
            let newDate = new Date(this.mta_date);
            let policy_end_date = new Date(mta_quote_data.latest_policy_end_date)
            var Difference_In_Time = (policy_end_date.getTime() - newDate.getTime()) + 86400000;
            Difference_In_Days_cancle = Difference_In_Time / (1000 * 3600 * 24);

            let policy_ntu_date = new Date()


            let oldDate = new Date(mta_quote_data.latest_policy_start_date);
            // let policy_end_date = new Date(mta_quote_data.policy_end_date)
            var Difference_In_ntu_Time = policy_ntu_date.getTime() - oldDate.getTime();
            Difference_In_Days_cancle = Difference_In_Time / (1000 * 3600 * 24);
            var Difference_In_Time_old = (policy_end_date.getTime() - oldDate.getTime()) + 86400000;
            Difference_In_Days_old = Difference_In_Time_old / (1000 * 3600 * 24);
            if (newDate >= oldDate) {
                if (parseFloat(Number(mta_quote_data.mta_sum_policy_premium).toFixed(2)) == Number(this.full_total_gross_primum)) {
                    this.full_total_gross_primum = - parseFloat(Number(this.full_total_gross_primum / Number(Difference_In_Days_old) * Number(Difference_In_Days_cancle)).toFixed(2));
                }
                else {
                    let full_return_amount :number = 0;
                    full_return_amount = - parseFloat(Number(mta_quote_data.mta_sum_policy_premium / Number(Difference_In_Days_old) * Number(Difference_In_Days_cancle)).toFixed(2));
                    if(Number(this.old_tigerlab_premium_loading) != this.tigerlab_premium_loading || Number(this.old_premium_loading_percentage) != this.premium_loading_percentage )
                    {
                        this.full_total_gross_primum =  parseFloat(Number(this.full_total_gross_primum / Number(Difference_In_Days_old) * Number(Difference_In_Days_cancle)).toFixed(2));
                        this.full_total_gross_primum =  parseFloat(Number(Number(this.full_total_gross_primum) + Number(full_return_amount)).toFixed(2));
                        this.full_total_gross_primum = parseFloat(Number(Number(this.full_total_gross_primum) + Number(full_return_amount)).toFixed(2));
                    }
                    else
                    {

                        this.full_total_gross_primum = full_return_amount;
                    }
                  
                }
            }
            // else
            // {
            //     this.is_NTU = true; 
            // }

            // if(Difference_In_ntu_Time / (1000 * 3600 * 24) < 14)
            // {
            //     this.is_NTU = true;
            // }

        }

        this.full_ipt_gross_amount = parseFloat(Number(Number(this.full_total_gross_primum) * Number(this.ipt_fee) / 100).toFixed(2));;


        this.full_total_premium = parseFloat(Number(this.full_total_gross_primum + (this.full_total_gross_primum * this.ipt_fee / 100)).toFixed(2));


        this.full_underwriting_fee = this.calculate_UW_fee(this.full_total_premium);

        if (this.is_mta) {
            this.full_underwriting_fee = 0;
        }

        if (this.is_cancel && !this.is_NTU) {
            //   this.full_underwriting_fee =  - parseFloat(Number(this.full_underwriting_fee *  (Number(Difference_In_Days_old) - Number(Difference_In_Days_cancle))/Number(Difference_In_Days_old)).toFixed(2));

            //   this.full_underwriting_fee = Number(mta_quote_data.quote_versions[0].financial_details.uw_fee);
            // this.full_underwriting_fee =  - parseFloat(Number(this.full_underwriting_fee /Number(Difference_In_Days_old) *   Number(Difference_In_Days_cancle)).toFixed(2));
            this.full_underwriting_fee = 0;
        }

        this.full_total_amount_payable = parseFloat(Number(Number(this.full_total_premium) + Number(this.full_underwriting_fee)).toFixed(2));


        this.full_broker_amount = parseFloat(Number(parseFloat(Number(this.full_total_gross_primum).toFixed(2)) * this.broker_commission / 100).toFixed(2));
        if (this.is_lapse || this.is_NTU) {

            this.full_total_gross_primum = parseFloat(Number(Number(mta_quote_data.sum_policy_premium)).toFixed(2));
            this.full_ipt_gross_amount = parseFloat(Number(Number(this.full_total_gross_primum) * Number(this.ipt_fee) / 100).toFixed(2));;
            this.full_total_premium = parseFloat(Number(this.full_total_gross_primum + (this.full_total_gross_primum * this.ipt_fee / 100)).toFixed(2));
            this.full_total_amount_payable = parseFloat(Number(Number(this.full_total_premium) + Number(this.full_underwriting_fee)).toFixed(2));
            this.full_broker_amount = parseFloat(Number(parseFloat(Number(this.full_total_gross_primum).toFixed(2)) * this.broker_commission / 100).toFixed(2));
            this.full_underwriting_fee = this.calculate_UW_fee(this.full_total_premium);
             this.full_total_gross_primum = -Number(this.full_total_gross_primum);
            this.full_ipt_gross_amount = -Number(this.full_ipt_gross_amount)
            this.full_total_premium = -Number(this.full_total_premium);
            this.full_underwriting_fee = -Number(this.full_underwriting_fee);
            this.full_total_amount_payable = - Number(this.full_total_amount_payable);
            this.full_broker_amount = -Number(this.full_broker_amount);
            //  this.full_underwriting_fee = - Number(this.full_underwriting_fee);
        }


        this.aeio_calculate_data(mta_quote_data);
    }

    /* AEIO Calcuations */
    public aeio_calculate_data(mta_quote_data) {
        let total_commission = Number(this.broker_commission) + Number(this.peliwica_pay_away_commission) + Number(this.peliwica_retained_commission);
        // main class
        // let oCalculationModel : CalculationModel;
        var net_rate_amount: number = this.sum_insured * this.aeio_class_net_rate / 100;
        // oCalculationModel.sum_insured = this.sum_insured;
        // AS asked by WILL no Minimum premium required in case of AEIO
        // AS asked by WILL - set this.aeio_co_pay_discount = 0, i.e. no copay-discount for AEIO
        // this.aeio_co_pay_discount = 0;
        // this.vet_age_loading_amount = 0;
        //  this.aeio_main_discount = 0;
        this.aeio_class_net_rate_amount = this.main_class_net(net_rate_amount, 0,
            0, false, 0, this.aeio_vet_fee_excess_discount, 0);
        this.aeio_class_gross_amount = this.gross_amount(this.aeio_class_net_rate_amount, total_commission);
        // Vat Fee
        this.aeio_net_vet_fee_amount = this.main_class_net(this.aeio_vet_fee_amount, 0,
            0, true, 0, this.aeio_vet_fee_excess_discount, 0);
        this.aeio_vet_gross_amount = this.gross_amount(this.aeio_net_vet_fee_amount, total_commission);

        // public liabilty 
        this.aeio_net_public_liability_amount = this.main_class_net(this.aeio_public_liability_amount, 0, 0, false,
            0, this.aeio_vet_fee_excess_discount, 0);
        this.aeio_public_gross_amount = this.gross_amount(this.aeio_net_public_liability_amount, total_commission);
        // Personal Accident 
        this.aeio_net_personal_accident_amount = this.main_class_net(this.aeio_personal_accident_amount, 0, 0, false,
            0, this.aeio_vet_fee_excess_discount, 0);
        this.aeio_personal_gross_amount = this.gross_amount(this.aeio_net_personal_accident_amount, total_commission);
        // Saddlery and Tack 
        var sat_net_rate_amount = parseFloat(Number(this.sat_sum_insured * this.sat_net_rate / 100).toFixed(2));
        this.sat_net_premium = this.main_class_net(sat_net_rate_amount, 0, 0, false,
            0, this.aeio_vet_fee_excess_discount, 0);
        this.sat_gross_amount = this.gross_amount(this.sat_net_premium, total_commission);
        //  Trailers and Horsedrawn Vehicles
        var trailer_net_rate_amount = parseFloat(Number(this.trailer_sum_insured * this.trailer_net_rate / 100).toFixed(2));
        this.trailer_net_premium = this.main_class_net(trailer_net_rate_amount, 0, 0, false,
            0, this.aeio_vet_fee_excess_discount, 0);
        this.trailer_gross_amount = this.gross_amount(this.trailer_net_premium, total_commission);
        this.total_premium_loading = this.gross_amount(this.premium_loading, total_commission);

        this.aeio_net_Premium_amount = parseFloat(Number(Number(this.aeio_class_net_rate_amount) + Number(this.aeio_net_vet_fee_amount) + Number(this.aeio_net_public_liability_amount) + Number(this.aeio_net_personal_accident_amount)
            + Number(this.trailer_net_premium) + Number(this.sat_net_premium) + Number(this.premium_loading)).toFixed(2));;

        this.aeio_total_gross_primum = parseFloat(Number(Number(this.aeio_class_gross_amount) + Number(this.aeio_vet_gross_amount) + Number(this.aeio_public_gross_amount) + Number(this.aeio_personal_gross_amount)
            + Number(this.trailer_gross_amount) + Number(this.sat_gross_amount) + Number(this.total_premium_loading) + Number(this.tigerlab_premium_loading)).toFixed(2));

            let minimum_premium :number = 0.0;
            minimum_premium = this.gross_amount(this.policy_minimum_premium, total_commission);
    
            if (Number(this.aeio_total_gross_primum) < minimum_premium) {
            //this.full_total_gross_primum = Number(this.policy_minimum_premium); 
            this.aeio_total_gross_primum = this.gross_amount(this.policy_minimum_premium, total_commission);
        }
        else {
            // this.aeio_total_gross_primum =  this.gross_amount(this.aeio_total_gross_primum, total_commission);  
        }
        var Difference_In_Days_cancle;
        var Difference_In_Days_old;
        if (this.is_mta) {
            if (parseFloat(Number(mta_quote_data.sum_policy_premium).toFixed(2)) != parseFloat(Number(this.aeio_total_gross_primum).toFixed(2))) {

                this.aeio_total_gross_primum = parseFloat(Number(Number(this.aeio_total_gross_primum) - Number(mta_quote_data.mta_sum_policy_premium)).toFixed(2));
                let newDate = new Date(this.mta_date);
                let policy_end_date = new Date(mta_quote_data.latest_policy_end_date)
                var Difference_In_Time = policy_end_date.getTime() - newDate.getTime() + 86400000;;
                var Difference_In_Days_MTA = Difference_In_Time / (1000 * 3600 * 24);

                let oldDate = new Date(mta_quote_data.latest_policy_start_date);
                // let policy_end_date = new Date(mta_quote_data.policy_end_date)
                Difference_In_Time_old = policy_end_date.getTime() - oldDate.getTime() + 86400000;;
                Difference_In_Days_old = Difference_In_Time_old / (1000 * 3600 * 24);

                this.aeio_total_gross_primum = parseFloat(Number(this.aeio_total_gross_primum / Number(Difference_In_Days_old) * Number(Difference_In_Days_MTA)).toFixed(2));
                if (this.aeio_total_gross_primum  <= 0.10 && this.aeio_total_gross_primum >= -0.10)
                {
                    this.aeio_total_gross_primum  = 0;
                }
            }
            else {
                this.aeio_total_gross_primum = 0
            }
        }


        if (this.is_cancel) {


            let newDate = new Date(this.mta_date);
            let policy_end_date = new Date(mta_quote_data.latest_policy_end_date)
            var Difference_In_Time = policy_end_date.getTime() - newDate.getTime() + 86400000; 
            Difference_In_Days_cancle = Difference_In_Time / (1000 * 3600 * 24);

            let policy_ntu_date = new Date()


            let oldDate = new Date(mta_quote_data.latest_policy_start_date);
            // let policy_end_date = new Date(mta_quote_data.policy_end_date)
            var Difference_In_ntu_Time = policy_ntu_date.getTime() - oldDate.getTime() + 86400000;
            Difference_In_Days_cancle = Difference_In_Time / (1000 * 3600 * 24);
            var Difference_In_Time_old = policy_end_date.getTime() - oldDate.getTime() + 86400000;
            Difference_In_Days_old = Difference_In_Time_old / (1000 * 3600 * 24);
            if (newDate >= oldDate) {
                if (parseFloat(Number(mta_quote_data.mta_sum_policy_premium).toFixed(2)) == Number(this.aeio_total_gross_primum)) {
                    this.aeio_total_gross_primum = - parseFloat(Number(this.aeio_total_gross_primum / Number(Difference_In_Days_old) * Number(Difference_In_Days_cancle)).toFixed(2));
                }
                else {
                    let full_return_amount :number = 0;
                    full_return_amount = - parseFloat(Number(mta_quote_data.mta_sum_policy_premium / Number(Difference_In_Days_old) * Number(Difference_In_Days_cancle)).toFixed(2));
                    if(Number(this.old_tigerlab_premium_loading) != this.tigerlab_premium_loading || Number(this.old_premium_loading_percentage) != this.premium_loading_percentage )
                    {
                                this.aeio_total_gross_primum =  parseFloat(Number(this.aeio_total_gross_primum / Number(Difference_In_Days_old) * Number(Difference_In_Days_cancle)).toFixed(2));
                                this.aeio_total_gross_primum =  parseFloat(Number(Number(this.aeio_total_gross_primum) + Number(full_return_amount)).toFixed(2));
                                this.aeio_total_gross_primum = parseFloat(Number(Number(this.aeio_total_gross_primum) + Number(full_return_amount)).toFixed(2));
                    }
                    else
                    {

                        this.aeio_total_gross_primum = full_return_amount;
                    }
                }
            }
            // this.aeio_total_gross_primum =parseFloat(Number(Number(this.aeio_total_gross_primum)-Number(mta_quote_data.quote_versions[0].financial_details.policy_premium)).toFixed(2));
            // let newDate = new Date(this.mta_date);
            // let policy_end_date = new Date(mta_quote_data.policy_end_date)
            // var Difference_In_Time = policy_end_date.getTime() - newDate.getTime();
            // var Difference_In_Days_cancle = Difference_In_Time / (1000 * 3600 * 24);

            // let policy_ntu_date = new Date()


            // let oldDate = new Date(mta_quote_data.latest_policy_start_date);
            // // let policy_end_date = new Date(mta_quote_data.policy_end_date)
            // var Difference_In_ntu_Time = policy_ntu_date.getTime() - oldDate.getTime();
            // var Difference_In_Time_old = policy_end_date.getTime() - oldDate.getTime();
            // var Difference_In_Days_old = Difference_In_Time_old / (1000 * 3600 * 24);
            // if (newDate >= oldDate) {
            //     if (Number(mta_quote_data.sum_policy_premium) == Number(this.aeio_total_gross_primum)) {
            //         this.aeio_total_gross_primum = - parseFloat(Number(this.aeio_total_gross_primum / Number(Difference_In_Days_old) * Number(Difference_In_Days_cancle)).toFixed(2));
            //     }
            //     else {
            //         this.aeio_total_gross_primum = - parseFloat(Number(mta_quote_data.sum_policy_premium / Number(Difference_In_Days_old) * Number(Difference_In_Days_cancle)).toFixed(2));
            //     }
            // }
            // else
            // {
            //      this.is_NTU = true;

            // }

            //      if(Difference_In_ntu_Time / (1000 * 3600 * 24) < 14)
            //     {
            //         this.is_NTU = true;
            //     }
        }

        this.aeio_ipt_gross_amount = parseFloat(Number(this.aeio_total_gross_primum * this.ipt_fee / 100).toFixed(2));
        this.aeio_total_premium = parseFloat(Number(this.aeio_total_gross_primum + (this.aeio_total_gross_primum * this.ipt_fee / 100)).toFixed(2));
        this.aeio_underwriting_fee = this.calculate_UW_fee(this.aeio_total_premium);
        if (this.is_mta) {
            this.aeio_underwriting_fee = 0;
        }
        if (this.is_cancel && !this.is_NTU) {
            // this.aeio_underwriting_fee =  - parseFloat(Number(this.aeio_underwriting_fee *  (Number(Difference_In_Days_old) - Number(Difference_In_Days_cancle))/Number(Difference_In_Days_old)).toFixed(2));
            // this.aeio_underwriting_fee = Number(mta_quote_data.quote_versions[0].financial_details.uw_fee);

            // this.aeio_underwriting_fee =  - parseFloat(Number(this.aeio_underwriting_fee /Number(Difference_In_Days_old) *   Number(Difference_In_Days_cancle)).toFixed(2));
            this.aeio_underwriting_fee = 0;
        }

        this.aeio_total_amount_payable = parseFloat(Number(Number(this.aeio_total_premium) + Number(this.aeio_underwriting_fee)).toFixed(2));

        this.aeio_broker_amount = parseFloat(Number(parseFloat(Number(this.aeio_total_gross_primum).toFixed(2)) * this.broker_commission / 100).toFixed(2));
        if (this.is_lapse || this.is_NTU) {
          

            this.aeio_total_gross_primum = parseFloat(Number(Number(mta_quote_data.sum_policy_premium)).toFixed(2));
            this.aeio_ipt_gross_amount = parseFloat(Number(Number(this.aeio_total_gross_primum) * Number(this.ipt_fee) / 100).toFixed(2));;
            this.aeio_total_premium = parseFloat(Number(this.aeio_total_gross_primum + (this.aeio_total_gross_primum * this.ipt_fee / 100)).toFixed(2));
            this.aeio_total_amount_payable = parseFloat(Number(Number(this.aeio_total_premium) + Number(this.aeio_underwriting_fee)).toFixed(2));
            this.aeio_broker_amount = parseFloat(Number(parseFloat(Number(this.aeio_total_gross_primum).toFixed(2)) * this.broker_commission / 100).toFixed(2));
            this.aeio_underwriting_fee = this.calculate_UW_fee(this.aeio_total_premium);
             this.aeio_total_gross_primum = -Number(this.aeio_total_gross_primum);
            this.aeio_ipt_gross_amount = -Number(this.aeio_ipt_gross_amount)
            this.aeio_total_premium = -Number(this.aeio_total_premium);
            this.aeio_underwriting_fee = -Number(this.aeio_underwriting_fee);
            this.aeio_total_amount_payable = - Number(this.aeio_total_amount_payable);
            this.aeio_broker_amount = -Number(this.aeio_broker_amount);
        }
    }

    /* Calcluate basic values */

    private main_class_net(basic_value, minimum_premium, age_loading, isVet, main_discount, vet_fee_excess_discount, full_co_pay_discount): number {
        if (basic_value <= minimum_premium) {
            basic_value = Number(minimum_premium);
        }
        if (age_loading > 0) {
            basic_value = Number(basic_value) + Number(basic_value * age_loading / 100);
        }
        if (isVet) {
            basic_value = Number(basic_value) + Number(this.life_saving_surgery);
            // if (this.is_life_saving_surgery) {
            //     basic_value =Number( basic_value) + Number(this.life_saving_surgery);
            // }
            // else {

            //     if (this.sum_insured > 3000) {
            //         this.annual_agg_life_saving = 0;
            //     }
            //     else {
            //         basic_value = Number(basic_value) + Number(this.annual_agg_life_saving);
            //     }
            // }
            basic_value = Number(basic_value) + Number(this.annual_agg_life_saving);
            basic_value = Number(basic_value) - (Number(basic_value) * Number(vet_fee_excess_discount) / 100);

            basic_value = Number(basic_value) - (Number(basic_value) * Number(full_co_pay_discount) / 100);
        }

        if (main_discount > 0) {
            basic_value = Number(basic_value) - (Number(basic_value) * Number(main_discount / 100));

        }
        return parseFloat(Number(basic_value).toFixed(2));
    }

    private gross_amount(basic_net_value: number, total_commission: number): number {

        basic_net_value = Number(basic_net_value) / (100 - Number(total_commission)) * 100;
        return parseFloat(Number(basic_net_value).toFixed(2));
    }

    private calculate_percentage_amount(value: number, percentage: number): number {

        value = (Number(value) * Number(percentage)) / 100;
        return parseFloat(Number(value).toFixed(2));
    }

    private calculate_UW_fee(primum: number): number {
        var uw_fee: number = 0;
        if (Number(primum < 400)) {
            uw_fee = Number(primum * 10 / 100);
        }
        else if (Number(primum >= 400 && Number(primum < 1200))) {
            uw_fee = 40;
        }
        else {
            uw_fee = 65;
        }
        return parseFloat(Number(uw_fee).toFixed(2));

    }

}


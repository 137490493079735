<h4>Full Quote</h4>
<form #f="ngForm" (ngSubmit)="onSubmit(f)">
    <div class="row">
        <div class="col-md-8">
            <div class="form_sections">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="text-white f13">Policy Start Date</label>
                            <div class="w-100">
                                <input type="text" required name="policy_start_date" placeholder="Policy Start Date"
                                    class="form-control form-control-sm datepicker" [min]="minPolicyDate"
                                    [(ngModel)]="quote.quote_versions[0].policy_start_date" #policy_start_date="ngModel"
                                    [ngClass]="{ 'is-invalid': f.submitted && policy_start_date.invalid }" matInput
                                    (focus)="myDatepicker.open()" [matDatepicker]="myDatepicker">
                                <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #myDatepicker></mat-datepicker>
                                <div *ngIf="f.submitted && policy_start_date.invalid" class="invalid-feedback">
                                    Policy date is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6" *ngIf="!isBroker">
                        <mat-form-field class="w-100" style="margin-top: -10px;">
                            <span class="text-white f13">Broker</span>
                            <mat-select [(ngModel)]="quote.company_id" class="form-control form-control-sm"
                                name="broker_id" [ngClass]="{ 'is-invalid': f.submitted && broker.invalid }"
                                #broker="ngModel" required (selectionChange)="onChangeBroker($event)">
                                <mat-option *ngFor="let brk of brokers" [value]="brk.id">
                                    {{brk.name}}
                                </mat-option>
                            </mat-select>
                            <div *ngIf="f.submitted && broker.invalid" class="invalid-feedback">
                                Broker field is required.
                            </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <label class="text-white f13">Transaction Type</label>
                        <mat-form-field class="w-100" style="margin-top: -10px;">
                            <mat-select [(ngModel)]="quote_type" class="form-control form-control-sm" name="quote_type"
                                [disabled]="quote_type >= 3" (selectionChange)="onQuoteTypeChange($event)">
                                <mat-option [value]="1" [hidden]="quote_type >= 3">New Business</mat-option>
                                <mat-option [value]="2" [hidden]="quote_type >= 3">Renewal</mat-option>
                                <mat-option [value]="3" [hidden]="quote_type != 3">MTA</mat-option>
                                <mat-option [value]="4" [hidden]="quote_type != 4">Cancelled</mat-option>
                                <mat-option [value]="5" [hidden]="quote_type != 5">Lapsed</mat-option>
                                <mat-option [value]="6" [hidden]="quote_type != 6">NTU</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6"
                        *ngIf="quote_type == 2 && quote.quote_versions[0].alpaca_public_liability_cover == 1">
                        <div class="form-group">
                            <label class="text-white f13">Retroactive date</label>
                            <div class="w-100">
                                <input type="text" required name="retroactive_date" placeholder="Retroactive date"
                                    class="form-control form-control-sm datepicker"
                                    [(ngModel)]="quote.quote_versions[0].retroactive_date" #retroactive_date="ngModel"
                                    [ngClass]="{ 'is-invalid': f.submitted && retroactive_date.invalid }" matInput
                                    (focus)="retroactiveDatepicker.open()" [matDatepicker]="retroactiveDatepicker">
                                <mat-datepicker-toggle [for]="retroactiveDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #retroactiveDatepicker></mat-datepicker>
                                <div *ngIf="f.submitted && retroactive_date.invalid" class="invalid-feedback">
                                    Retroactive date is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6" *ngIf="quote_type == 3 || quote_type == 4">
                        <label class="text-white f13">Policy Reason</label>
                        <mat-form-field class="w-100" style="margin-top: -10px;">
                            <mat-select [(ngModel)]="quote.quote_versions[0].mta_reason_id"
                                class="form-control form-control-sm" name="fd_mta_reason_id"
                                [ngClass]="{ 'is-invalid': f.submitted && mta_reason_id.invalid }"
                                #mta_reason_id="ngModel" required>
                                <mat-option *ngFor="let rsn of policyReasons" [value]="rsn.id">
                                    {{rsn.reasons}}
                                </mat-option>
                            </mat-select>
                            <div *ngIf="f.submitted && mta_reason_id.invalid" class="invalid-feedback">
                                Please select a specific reason.
                            </div>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" *ngIf="quote.quote_versions[0].mta_reason_id == '877o3763-6a03-4326-b81c-b14fae1b7422'">
                    <div class="col-md-12">
                        <div class="form-group">
                            <textarea name="other_reason" placeholder="Please specify reason" class="form-control"
                                [(ngModel)]="quote.quote_versions[0].mta_reason_other"
                                [ngClass]="{ 'is-invalid': f.submitted && other_reason.invalid }" #other_reason="ngModel"
                                required></textarea>
                            <div *ngIf="f.submitted && other_reason.invalid" class="invalid-feedback">
                                Please provide a specific reason.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6"
                        *ngIf="quote_type == 2">
                        <div class="form-group">
                            <label class="text-white f13">Previous Year Premium</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">&pound;</span>
                                </div>
                                <input type="number" required name="previous_policy_amount"
                                    placeholder="Previous Year Premium" class="form-control form-control-sm" 
                                    [(ngModel)]="quote.quote_versions[0].previous_policy_amount"
                                    #previous_policy_amount="ngModel"
                                    [ngClass]="{ 'is-invalid': f.submitted && previous_policy_amount.invalid }">
                                <div *ngIf="f.submitted && previous_policy_amount.invalid" class="invalid-feedback">
                                    Previous year premium is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form_sections">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            About The Proposer
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="form-group">
                        <mat-radio-group class="row" aria-label="Select an option" name="fd_proposer_type"
                            [(ngModel)]="quote.quote_versions[0].policy_holder.proposer_type">
                            <div class="col-md-4">
                                <div class="quote_radio w-90 mb-3">
                                    <mat-radio-button [value]="1">Individual</mat-radio-button>
                                </div>
                                <span class="ml-1" (click)="helpTextPopup(content,'A person who is acting for purposes which are outside his trade or profession 
                                e.g. a non-profit making hobby such as animals kept as pets.')"><i
                                        class="mdi mdi-comment-question-outline"></i></span>
                            </div>
                            <div class="col-md-4">
                                <div class="quote_radio w-90 mb-3">
                                    <mat-radio-button [value]="2">Micro Enterprise</mat-radio-button>
                                </div>
                                <span class="ml-1"
                                    (click)="helpTextPopup(content,'Smaller businesses that have a turnover or annual balance sheet of not more than 
                                £2m (two million GBP)  and fewer than ten employees. e.g. smallholdings that include animals or small livestock herds.')"><i
                                        class="mdi mdi-comment-question-outline"></i></span>
                            </div>
                            <div class="col-md-4">
                                <div class="quote_radio w-90 mb-3">
                                    <mat-radio-button [value]="3">Commercial</mat-radio-button>
                                </div>
                                <span class="ml-1" (click)="helpTextPopup(content,'Livestock related businesses that are run for profit that have a turnover or annual balance sheet of more than £2m (two million GBP) or more than 10 employees.
                                e.g. large livestock herds / livestock herds.')"><i
                                        class="mdi mdi-comment-question-outline"></i></span>
                            </div>
                        </mat-radio-group>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" *ngIf="quote.quote_versions[0].policy_holder.proposer_type == 1">
                                <input type="text" name="first_name" placeholder="First Name"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="quote.quote_versions[0].policy_holder.first_name"
                                    [ngClass]="{ 'is-invalid': f.submitted && first_name.invalid }"
                                    #first_name="ngModel" required>
                                <div *ngIf="f.submitted && first_name.invalid" class="invalid-feedback">
                                    First name is required.
                                </div>
                            </div>
                            <div class="form-group" *ngIf="quote.quote_versions[0].policy_holder.proposer_type == 1">
                                <input type="text" name="last_name" placeholder="Last Name"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="quote.quote_versions[0].policy_holder.last_name">
                            </div>
                            <div class="form-group" *ngIf="quote.quote_versions[0].policy_holder.proposer_type != 1">
                                <input type="text" name="company_name" placeholder="Name of Company"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="quote.quote_versions[0].policy_holder.company_name">
                            </div>
                            <div class="form-group" *ngIf="quote.quote_versions[0].policy_holder.proposer_type != 1">
                                <input type="text" name="trading_name" placeholder="Trading Name"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="quote.quote_versions[0].policy_holder.trading_name">
                            </div>
                            <div class="form-group">
                                <input type="email" name="email" placeholder="Email Address"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="quote.quote_versions[0].policy_holder.email"
                                    pattern="^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$"
                                    [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" #email="ngModel"
                                    required>
                                <div *ngIf="f.submitted && email.errors && (email.invalid || email.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="email.invalid && !email.touched">Email is required.</div>
                                    <div *ngIf="email.errors.pattern && email.touched">Please provide a valid email
                                        address
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input required type="text" name="phone" placeholder="Contact Number 1"
                                    class="form-control form-control-sm" required #phone="ngModel"
                                    [(ngModel)]="quote.quote_versions[0].policy_holder.phone">
                                <div *ngIf="f.submitted && phone.invalid" class="invalid-feedback">
                                    Mobile number is required.
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" name="mobile" placeholder="Contact Number 2"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="quote.quote_versions[0].policy_holder.mobile">

                            </div>
                        </div>


                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="postcode_lookup" placeholder="Postcode"
                                    class="form-control form-control-sm"
                                    (keyup)="postcode_lookup.errors?'':postcodeLookup($event)"
                                    pattern="^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$"
                                    #postcode_lookup="ngModel"
                                    [(ngModel)]="quote.quote_versions[0].policy_holder.address.postcode"
                                    [ngClass]="{ 'is-invalid': f.submitted && postcode_lookup.invalid }"
                                    [matAutocomplete]="auto" appUpperCase>
                                <div *ngIf="postcode_lookup.errors && (postcode_lookup.invalid || postcode_lookup.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="postcode_lookup.invalid && !postcode_lookup.touched">Postcode is
                                        required.</div>
                                    <div
                                        *ngIf="f.submitted && postcode_lookup.errors?.pattern && postcode_lookup.touched">
                                        Invalid postcode.</div>
                                </div>
                                <div>
                                    <mat-autocomplete #auto="matAutocomplete">
                                        <div
                                            *ngIf="!postcode_lookup.errors && quote.quote_versions[0].policy_holder.address.postcode != ''">
                                            <mat-option [value]="quote.quote_versions[0].policy_holder.address.postcode"
                                                (click)="onSelectAddress(adrs)" *ngFor="let adrs of addresses">
                                                {{adrs.formatted_address.join(', ')}}</mat-option>
                                        </div>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" name="policy_holder_address_line_1" placeholder="Line 1"
                                    [(ngModel)]="quote.quote_versions[0].policy_holder.address.address1"
                                    class="form-control form-control-sm" appTitleCase
                                    [ngClass]="{ 'is-invalid': f.submitted && policy_holder_address_line_1.invalid }"
                                    required #policy_holder_address_line_1="ngModel">
                                <div *ngIf="f.submitted && policy_holder_address_line_1.invalid"
                                    class="invalid-feedback">
                                    Address 1 is required.
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" name="policy_holder_address_line_2" placeholder="Line 2"
                                    [(ngModel)]="quote.quote_versions[0].policy_holder.address.address2"
                                    class="form-control form-control-sm" appTitleCase>
                            </div>

                            <div class="form-group row">
                                <div class="col-md-6">
                                    <input type="text" name="policy_holder_city" placeholder="Town"
                                        [(ngModel)]="quote.quote_versions[0].policy_holder.address.city"
                                        class="form-control form-control-sm" appTitleCase
                                        [ngClass]="{ 'is-invalid': f.submitted && policy_holder_town.invalid }" required
                                        #policy_holder_town="ngModel">
                                    <div *ngIf="f.submitted && policy_holder_town.invalid" class="invalid-feedback">
                                        Town is required.
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="policy_holder_county" placeholder="County"
                                        [(ngModel)]="quote.quote_versions[0].policy_holder.address.country"
                                        class="form-control form-control-sm" appTitleCase
                                        [ngClass]="{ 'is-invalid': f.submitted && policy_holder_country.invalid }"
                                        required #policy_holder_country="ngModel">
                                    <div *ngIf="f.submitted && policy_holder_country.invalid" class="invalid-feedback">
                                        County is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>
            <div class="form_sections" *ngIf="quote.quote_versions[0].alpaca_theft_cover == 1 && quote.quote_versions[0].about_alpaca">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            About the Alpacas / Llamas
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="form-group">
                        <label class="f13 text-white">
                            How old are the animals and whats the total value of them?
                            <span
                                (click)="helpTextPopup(content,'How old are the animals and whats the total value of them')"><i
                                    class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                    </div>
                    <div class="form-group">
                        <label class="text-white f13">Between 7 and 90 days old
                            <span (click)="helpTextPopup(content,'<div>
                                <div>
                                    <div>
                                        <span> The following sums insured apply to crias fewer than 91 days old</span><br/><br/>
                                        <span> From 7 days old to 30 days old - </span>  <span>&pound;500.00</span><br/>		
                                        <span> From 31 days old to 60 days old - </span><span>&pound;750.00</span><br/>
                                        <span> From 61 days old to 89 days old - </span> <span>&pound;1,000.00 </span><br/>
                                        <span> From 90 days old to expiry of policy - </span><span>&pound;1,500.00</span><br/><br/>
                                        <span> The sum insured automatically increases as set out above, warranted the cria is in sound health at each increase date.</span><br/>
                                    </div>
                      
                                </div>
                            </div>')"><i class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-5">
                                <input type="number" appNumbers name="seven_to_ninety_days" placeholder="How many cria?"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="quote.quote_versions[0].about_alpaca.alpaca_7dto90d"
                                    [ngClass]="{ 'is-invalid': (f.submitted && seven_to_ninety_days.invalid) || quote.quote_versions[0].about_alpaca.alpaca_7dto90d > 30 }"
                                    required #seven_to_ninety_days="ngModel" (change)="onAlpacaRatesChange($event, 1)">
                                <div *ngIf="f.submitted && seven_to_ninety_days.invalid" class="invalid-feedback">
                                    Value is required.
                                </div>
                                <div *ngIf="quote.quote_versions[0].about_alpaca.alpaca_7dto90d > 30"
                                    class="invalid-feedback">
                                    Please contact the system administrator if the number of cria are more than 30.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="text-white f13">Between 91 days & 12 years old
                            <span
                                (click)="helpTextPopup(content,'State the number of alpacas for insurance between 91 days and 12 years old and state the total value of these alpacas')"><i
                                    class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <input type="number" appNumbers name="ninetyone_to_twelve_days"
                                        placeholder="How many alpacas?" class="form-control form-control-sm"
                                        [(ngModel)]="quote.quote_versions[0].about_alpaca.alpaca_91dto12y"
                                        [ngClass]="{ 'is-invalid': (f.submitted && ninetyone_to_twelve_days.invalid) || quote.quote_versions[0].about_alpaca.alpaca_91dto12y > 30 }"
                                        required #ninetyone_to_twelve_days="ngModel"
                                        (change)="onAlpacaRatesChange($event, 2)">
                                    <div *ngIf="f.submitted && ninetyone_to_twelve_days.invalid"
                                        class="invalid-feedback">
                                        Value is required.
                                    </div>
                                    <div *ngIf="quote.quote_versions[0].about_alpaca.alpaca_91dto12y > 30"
                                        class="invalid-feedback">
                                        Please contact the system administrator if the number of animals are more than
                                        30.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="text-white f13">Only the Total Amount of Alapacas / Llamas Between 91 days &
                                12 years old</label>
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text bg-success text-white">&pound;</span>
                                        </div>
                                        <input type="number" appNumbers name="sum_insured" style="font-size: 12px;"
                                            placeholder="Enter the total value of insurance cover required"
                                            class="form-control form-control-sm" step="50"
                                            [(ngModel)]="quote.quote_versions[0].about_alpaca.sum_insured"
                                            (blur)="onAlpacaRatesChange($event, 2)"
                                            [ngClass]="{ 'is-invalid': (f.submitted && quote.quote_versions[0].about_alpaca.alpaca_91dto12y > 0 &&  quote.quote_versions[0].about_alpaca.sum_insured == 0)}">
                                    </div>
                                    <div *ngIf="f.submitted  && quote.quote_versions[0].about_alpaca.alpaca_91dto12y > 0 &&  quote.quote_versions[0].about_alpaca.sum_insured == 0"
                                        class="invalid-feedback">
                                        Value is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="quote_type == 2">
                        <label class="text-white f13">Aged 13 years old
                            <span (click)="helpTextPopup(content,'Aged 13 years old')"><i
                                    class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <input type="number" appNumbers name="alpaca_13y" placeholder="How many alpacas?"
                                        class="form-control form-control-sm"
                                        [(ngModel)]="quote.quote_versions[0].about_alpaca.alpaca_13y"
                                        [ngClass]="{ 'is-invalid': (f.submitted && alpaca_13y.invalid) || quote.quote_versions[0].about_alpaca.alpaca_13y > 30 }"
                                        required #alpaca_13y="ngModel" (change)="onAlpacaRatesChange($event, 8)">
                                    <div *ngIf="f.submitted && alpaca_13y.invalid" class="invalid-feedback">
                                        Value is required.
                                    </div>
                                    <div *ngIf="quote.quote_versions[0].about_alpaca.alpaca_13y > 30"
                                        class="invalid-feedback">
                                        Please contact the system administrator if the number of animals are more than
                                        30.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text bg-success text-white">&pound;</span>
                                        </div>
                                        <input type="number" appNumbers name="sum_insured_13y" style="font-size: 12px;"
                                            placeholder="Enter the total value of insurance cover required"
                                            class="form-control form-control-sm" step="50"
                                            [(ngModel)]="quote.quote_versions[0].about_alpaca.sum_insured_13y"
                                            (blur)="onAlpacaRatesChange($event, 8)"
                                            [ngClass]="{ 'is-invalid': (f.submitted && quote.quote_versions[0].about_alpaca.alpaca_13y > 0 &&  quote.quote_versions[0].about_alpaca.sum_insured_13y == 0)}">
                                    </div>
                                    <div *ngIf="f.submitted  && quote.quote_versions[0].about_alpaca.alpaca_13y > 0 &&  quote.quote_versions[0].about_alpaca.sum_insured_13y == 0"
                                        class="invalid-feedback">
                                        Value is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="quote_type == 2">
                        <label class="text-white f13">Aged 14 years old
                            <span (click)="helpTextPopup(content,'Aged 14 years old')"><i
                                    class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <input type="number" appNumbers name="alpaca_14y" placeholder="How many alpacas?"
                                        class="form-control form-control-sm"
                                        [(ngModel)]="quote.quote_versions[0].about_alpaca.alpaca_14y"
                                        [ngClass]="{ 'is-invalid': (f.submitted && alpaca_14y.invalid) || quote.quote_versions[0].about_alpaca.alpaca_14y > 30 }"
                                        required #alpaca_14y="ngModel" (change)="onAlpacaRatesChange($event, 9)">
                                    <div *ngIf="f.submitted && alpaca_14y.invalid" class="invalid-feedback">
                                        Value is required.
                                    </div>
                                    <div *ngIf="quote.quote_versions[0].about_alpaca.alpaca_14y > 30"
                                        class="invalid-feedback">
                                        Please contact the system administrator if number of the animals are more than
                                        30.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text bg-success text-white">&pound;</span>
                                        </div>
                                        <input type="number" appNumbers name="sum_insured_14y" style="font-size: 12px;"
                                            placeholder="Enter the total value of insurance cover required"
                                            class="form-control form-control-sm" step="50"
                                            [(ngModel)]="quote.quote_versions[0].about_alpaca.sum_insured_14y"
                                            (blur)="onAlpacaRatesChange($event, 9)"
                                            [ngClass]="{ 'is-invalid': (f.submitted && quote.quote_versions[0].about_alpaca.alpaca_14y > 0 &&  quote.quote_versions[0].about_alpaca.sum_insured_14y == 0)}">
                                    </div>
                                    <div *ngIf="f.submitted  && quote.quote_versions[0].about_alpaca.alpaca_14y > 0 &&  quote.quote_versions[0].about_alpaca.sum_insured_14y == 0"
                                        class="invalid-feedback">
                                        Value is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="text-white f13">Do you need Economic Slaughter/Loss of Use for STUD MALES ONLY
                            <span
                                (click)="helpTextPopup(content,'Cover for a male alpaca suffering an accident, illness or disease rendering the animal totally and permanently infertile, impotent or otherwise incapable of serving females, by either natural service or artificial insemination, caused by an accident, injury, illness or disease happening or manifesting itself during the period of insurance.')"><i
                                    class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-5">
                                <mat-radio-group class="row" required aria-label="Select an option"
                                    name="fd_does_economic_slaughter_required" #economicSlaughter ngmodel
                                    (change)="onEconomicSlaughterChange($event)"
                                    [(ngModel)]="quote.quote_versions[0].about_alpaca.does_economic_slaughter_required">
                                    <div class="col-md-6">
                                        <div class="quote_radio">
                                            <mat-radio-button [value]="1" name="fd_does_economic_slaughter_required"
                                                ngDefaultControl
                                                [(ngModel)]="quote.quote_versions[0].about_alpaca.does_economic_slaughter_required"
                                                required #economicSlaughterControl="ngModel">Yes</mat-radio-button>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div>
                                            <div class="quote_radio">
                                                <mat-radio-button [value]="2" name="fd_does_economic_slaughter_required"
                                                    ngDefaultControl
                                                    [(ngModel)]="quote.quote_versions[0].about_alpaca.does_economic_slaughter_required"
                                                    required #economicSlaughterControl="ngModel">No</mat-radio-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="f.submitted && economicSlaughterControl.invalid"
                                        class="invalid-feedback col-md-12">
                                        Please select an option.
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="row mt-3"
                            *ngIf="quote.quote_versions[0].about_alpaca.does_economic_slaughter_required == 1">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <input type="number" appNumbers name="economic_slaughter_required_for"
                                        placeholder="How many stud males?" class="form-control form-control-sm"
                                        [max]="quote.total_animals"
                                        [(ngModel)]="quote.quote_versions[0].about_alpaca.economic_slaughter_required_for"
                                        (change)="onAlpacaRatesChange($event, 3)"
                                        (blur)="studMalesConformation(content)">
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text bg-success text-white">&pound;</span>
                                        </div>
                                        <input type="number" appNumbers name="economic_slaughter_sum_insured"
                                            style="font-size: 12px;" step="50"
                                            placeholder="Enter the total value of insurance cover required"
                                            class="form-control form-control-sm" (blur)="onAlpacaRatesChange($event, 3)"
                                            [(ngModel)]="quote.quote_versions[0].about_alpaca.economic_slaughter_sum_insured">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="text-white f13">Do you need life saving surgical fee cover?
                            <span (click)="helpTextPopup(content,'All reasonable and normal veterinary treatment costs for surgical procedures to save the life of the animal carried out by a Vet up to maximum benefit of £3,000 
                            Excess of £350 per surgery applies.
                            ')"><i class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-5">
                                <mat-radio-group class="row" required aria-label="Select an option"
                                    name="fd_lifeSavingCover" #lifeSavingCover ngmodel
                                    (change)="onAlpacaRatesChange($event, 5)"
                                    [(ngModel)]="quote.quote_versions[0].about_alpaca.does_life_saving_cover_required">
                                    <div class="col-md-6">
                                        <div class="quote_radio">
                                            <mat-radio-button [value]="1" name="fd_lifeSavingCover" ngDefaultControl
                                                [(ngModel)]="quote.quote_versions[0].about_alpaca.does_life_saving_cover_required"
                                                required #lifeSavingCoverControl="ngModel">Yes</mat-radio-button>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div>
                                            <div class="quote_radio">
                                                <mat-radio-button [value]="2" name="fd_lifeSavingCover" ngDefaultControl
                                                    [(ngModel)]="quote.quote_versions[0].about_alpaca.does_life_saving_cover_required"
                                                    required #lifeSavingCoverControl="ngModel">No</mat-radio-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="f.submitted && lifeSavingCoverControl.invalid"
                                        class="invalid-feedback col-md-12">
                                        Please select an option.
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="text-white f13">Do you need all reasonable and normal vets treatment costs
                            covered?
                            <span (click)="helpTextPopup(content,'All reasonable and normal veterinary treatment costs carried out by a Vet up to maximum benefit of £5,000 per insured animal for each and every illness, condition or disease of, or physical injury to the insured animal.  This extension does not cover routine veterinary costs including but not limited to costs for pregnancy, vaccinations and worming.
                            Excess £500 per incident applies.')"><i
                                    class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-5">
                                <mat-radio-group class="row" required aria-label="Select an option"
                                    name="fd_vetsTreatmentCost" #vetsTreatmentCost ngmodel
                                    (change)="onAlpacaRatesChange($event, 6)"
                                    [(ngModel)]="quote.quote_versions[0].about_alpaca.does_vet_treatments_covered">
                                    <div class="col-md-6">
                                        <div class="quote_radio">
                                            <mat-radio-button [value]="1" name="fd_vetsTreatmentCost" ngDefaultControl
                                                [(ngModel)]="quote.quote_versions[0].about_alpaca.does_vet_treatments_covered"
                                                required #vetsTreatmentCostControl="ngModel">Yes</mat-radio-button>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div>
                                            <div class="quote_radio">
                                                <mat-radio-button [value]="2" name="fd_vetsTreatmentCost"
                                                    ngDefaultControl
                                                    [(ngModel)]="quote.quote_versions[0].about_alpaca.does_vet_treatments_covered"
                                                    required #vetsTreatmentCostControl="ngModel">No</mat-radio-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="f.submitted && vetsTreatmentCostControl.invalid"
                                        class="invalid-feedback col-md-12">
                                        Please select an option.
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-5">
                            <label class="text-white f13">How many claims have you made in the past 2 years for the
                                animals?</label>
                            <mat-form-field class="w-100">
                                <mat-label>Claims</mat-label>
                                <mat-select [(ngModel)]="quote.quote_versions[0].number_of_claims"
                                    class="form-control form-control-sm" name="number_of_claims"
                                    (selectionChange)="onChangeClaim($event)"
                                    [ngClass]="{ 'is-invalid': f.submitted && number_of_claims.invalid }"
                                    #number_of_claims="ngModel" required>
                                    <mat-option *ngFor="let claim of allClaims" [value]="claim.number_of_claims">
                                        {{ claim.number_of_claims + ((claim.number_of_claims < 2)?' Claim':' Claims') }}
                                            </mat-option>
                                </mat-select>
                                <div *ngIf="f.submitted && number_of_claims.invalid" class="invalid-feedback">
                                    Claim is required.
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>
            <div class="form_sections" *ngIf="quote.quote_versions[0].alpaca_public_liability_cover == 1">
                <mat-expansion-panel *ngIf="quote.quote_versions[0].alpaca_liability">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            About the Public and Product liability cover
                            <span (click)="helpTextPopup(content,'<b>Public Liability </b> </br>
                                Protection against your legal liability for bodily injury to third parties and damage to their property, including obstruction, trespass, nuisance, interference, wrongful arrest and eviction. </br>
                                Excess £250 any one Occurrence applicable to Damage and Defence costs. </br>
                                <b>Products Liability</b> </br>
                                Legal liability for damages and costs following injury or damage by goods supplied, sold, repaired, tested or maintained.</br>
                                Excess £250 any one Occurrence and in the aggregate applicable to Damage and Defence costs.
                                
                                
                            ')"><i class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="form-group row">
                        <div class="col-md-5">
                            <label class="text-white f13">What is the acreage of the farm?</label>
                            <input type="text" appNumbers name="acerage"
                                placeholder="Enter the total acreage of the Smallholding"
                                class="form-control form-control-sm" (blur)="acreageChange($event)"
                                [(ngModel)]="quote.quote_versions[0].alpaca_liability.acerage" #acerage="ngModel"
                                required [ngClass]="{ 'is-invalid': f.submitted && acerage.invalid }">
                            <div *ngIf="f.submitted && acerage.invalid" class="invalid-feedback">
                                Value is required.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="text-white f13">What is the limit of Public liability cover required?
                            <span (click)="helpTextPopup(content,'Please select below either £1m, £2m or £5m')"><i
                                    class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-8">
                                <mat-radio-group class="row" required aria-label="Select an option"
                                    name="fd_publicLiabilityCover" #publicLiabilityCover ngmodel
                                    [(ngModel)]="quote.quote_versions[0].alpaca_liability.public_liability_cover"
                                    (change)="onAlpacaLiabilityLimitChange($event)">
                                    <div class="col-md-4">
                                        <div class="quote_radio">
                                            <mat-radio-button [value]="1" name="fd_publicLiabilityCover"
                                                ngDefaultControl
                                                [(ngModel)]="quote.quote_versions[0].alpaca_liability.public_liability_cover"
                                                required #publicLiabilityCoverControl="ngModel">&pound;1m
                                            </mat-radio-button>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="quote_radio">
                                            <mat-radio-button [value]="2" name="fd_publicLiabilityCover"
                                                ngDefaultControl
                                                [(ngModel)]="quote.quote_versions[0].alpaca_liability.public_liability_cover"
                                                required #publicLiabilityCoverControl="ngModel">&pound;2m
                                            </mat-radio-button>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="quote_radio">
                                            <mat-radio-button [value]="5" name="fd_publicLiabilityCover"
                                                ngDefaultControl
                                                [(ngModel)]="quote.quote_versions[0].alpaca_liability.public_liability_cover"
                                                required #publicLiabilityCoverControl="ngModel">&pound;5m
                                            </mat-radio-button>
                                        </div>
                                    </div>
                                    <div *ngIf="f.submitted && publicLiabilityCoverControl.invalid"
                                        class="invalid-feedback col-md-12">
                                        Please select an option.
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="text-white f13">Do the public interact with the animals
                            <span
                                (click)="helpTextPopup(content,'If yes, please select the main activities from the activities below.')"><i
                                    class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-8">
                                <mat-radio-group class="row" required aria-label="Select an option"
                                    name="fd_publicInteractionAllowed" #publicInteractionAllowed ngmodel
                                    [(ngModel)]="quote.quote_versions[0].alpaca_liability.is_public_interaction_allowed">
                                    <div class="col-md-4">
                                        <div class="quote_radio">
                                            <mat-radio-button [value]="1" name="fd_publicInteractionAllowed"
                                                ngDefaultControl
                                                [(ngModel)]="quote.quote_versions[0].alpaca_liability.is_public_interaction_allowed"
                                                required #publicInteractionAllowedControl="ngModel">Yes
                                            </mat-radio-button>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="quote_radio">
                                            <mat-radio-button [value]="2" name="fd_publicInteractionAllowed"
                                                ngDefaultControl
                                                [(ngModel)]="quote.quote_versions[0].alpaca_liability.is_public_interaction_allowed"
                                                required #publicInteractionAllowedControl="ngModel">No
                                            </mat-radio-button>
                                        </div>
                                    </div>
                                    <div *ngIf="f.submitted && publicLiabilityCoverControl.invalid"
                                        class="invalid-feedback col-md-12">
                                        Please select an option.
                                    </div>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>

                    <div class="form-group"
                        [hidden]="quote.quote_versions[0].alpaca_liability.is_public_interaction_allowed == 2">
                        <label class="text-white f13">What are the main activities undertaken by Smallholding?
                            <span (click)="helpTextPopup(content,'<b>Alpaca/Llama Experience Sessions </b></br>
                                Visitors to your premises for</br>
                                Meet & Greet the animals</br>
                                Walks with the animals on and/or off the insured premises</br>
                                Animals to be on lead reins at all times during walks</br>
                                Maximum of 1 animal per individual during walks</br>
                                
                                <b>Group Visits to the insured premises</b></br>
                                Group visitors such as school pupils, local clubs, special needs children, craft workshops.</br>
                                The visitors will have a tour of the smallholding and observe the animals.  </br>
                                This will not include the visitors walking with the animals unless you have selected the Alpaca/Llama Experience Sessions too.</br>
                                
                                <b>Visits to external venues</b></br>
                                Cover when you are taking your animals to venues such as schools, weddings, hospices, nursing homes and similar venues.</br>
                                This will not include the public walking with the animals unless you have selected the Alpaca/Llama Experience Sessions too.</br>
                                
                                <b>Promoting and selling related products</b></br>
                                Product Liability for the retail sale of products, e.g., dairy, meat, produce, products or fibre.  If you select this activity, you will be contacted to discuss the type of products you sell and your annual turnover from the sale of products.
                                
                                
                                <b>Attending events with a Trade Stand</b></br>
                                If you attend events - markets, organised shows etc. with a Trade Stand, from as little as a table displaying your products to a large trade stand, you should have Public and Products Liability cover in place.  Almost all Organisers of Events require proof of your insurance, or at least your confirmation that you have such cover in place.
                                ')"><i class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-4" [hidden]="liabilities.is_visible_on_ui == 0"
                                            *ngFor="let liabilities of alpacaLiabiities; let i = index;">
                                            <div class="form-group">
                                                <mat-checkbox name="smallholding_activities_{{i}}"
                                                    (change)="onSmallHoldingActivitiesChange($event, liabilities.id)"
                                                    value="{{liabilities.id}}" [checked]="liabilities.is_checked">
                                                    {{liabilities.policy_cover}}
                                                </mat-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>
            <div *ngIf="quote.quote_versions[0].alpaca_theft_cover == 1">
                <div *ngFor="let animals of quote.quote_versions[0].alpaca_details; let i = index;trackBy:indexTracker">
                    <div class="form_sections" *ngIf="animals.is_cria == 1">
                        <mat-expansion-panel expanded>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{(animals.name)?animals.name:'Cria '}}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-2">
                                        <input type="text" name="animal_name_{{i}}" placeholder="Name"
                                            class="form-control form-control-sm" [(ngModel)]="animals.name"
                                            #animal_name="ngModel" required
                                            [ngClass]="{ 'is-invalid': f.submitted && animal_name.invalid }">
                                        <div *ngIf="f.submitted && animal_name.invalid" class="invalid-feedback">
                                            Name is required.
                                        </div>
                                    </div>
                                    <mat-form-field class="w-100 mb-2">
                                        <mat-label>Sex</mat-label>
                                        <mat-select [(ngModel)]="animals.gender" class="form-control form-control-sm"
                                            name="gender_{{quote.quote_versions[0].alpaca_details.length + i}}"
                                            [ngClass]="{ 'is-invalid': f.submitted && gender.invalid }"
                                            #gender="ngModel" required>
                                            <mat-option [value]="">Select Sex</mat-option>
                                            <mat-option [value]="1">Male</mat-option>
                                            <mat-option [value]="2">Female</mat-option>
                                        </mat-select>
                                        <div *ngIf="f.submitted && gender.invalid" class="invalid-feedback">
                                            Gender is required.
                                        </div>
                                    </mat-form-field>
                                    <div class="form-group">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text bg-success text-white">&pound;</span>
                                            </div>
                                            <input type="number" appNumbers [min]="0" step="50"
                                                name="animal_value_{{quote.quote_versions[0].alpaca_details.length + i}}"
                                                placeholder="Value of this animal" class="form-control form-control-sm"
                                                [(ngModel)]="animals.value" #animal_value="ngModel" required
                                                [ngClass]="{ 'is-invalid': (f.submitted && animal_value.invalid) || animals.value > 1500 }"
                                                (blur)="criaTotalValue($event)">
                                            <div *ngIf="f.submitted && animal_value.invalid" class="invalid-feedback">
                                                Cria value is required.
                                            </div>
                                            <div *ngIf="animals.value > 1500" class="invalid-feedback">
                                                Value should be less than 1500.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text"
                                            name="microchip_no_{{quote.quote_versions[0].alpaca_details.length + i}}"
                                            placeholder="Tag/ Microchip no." class="form-control form-control-sm"
                                            [(ngModel)]="animals.microchip" #microchip_no="ngModel" required
                                            [ngClass]="{ 'is-invalid': f.submitted && microchip_no.invalid }">
                                        <div *ngIf="f.submitted && microchip_no.invalid" class="invalid-feedback">
                                            Microchip number is required.
                                        </div>
                                    </div>
                                    <div class="w-100">
                                        <input type="text" required
                                            name="animal_dob_{{quote.quote_versions[0].alpaca_details.length + i}}"
                                            placeholder="Date of Birth" class="form-control form-control-sm datepicker"
                                            [(ngModel)]="animals.horse_purchase_date" #animal_dob="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && animal_dob.invalid }" matInput
                                            (focus)="dobDatepicker.open()" [matDatepicker]="dobDatepicker"
                                            [min]="minDOB" [max]="currDate">
                                        <mat-datepicker-toggle [for]="dobDatepicker"></mat-datepicker-toggle>
                                        <mat-datepicker #dobDatepicker></mat-datepicker>
                                        <div *ngIf="f.submitted && animal_dob.invalid" class="invalid-feedback">
                                            DOB is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </div>
                </div>
                <div *ngFor="let animals of quote.quote_versions[0].alpaca_details; let i = index;trackBy:indexTracker">
                    <div class="form_sections" *ngIf="animals.is_cria != 1">
                        <mat-expansion-panel expanded>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{(animals.name)?animals.name:'Animal '}}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-2">
                                        <input type="text" name="animal_name_{{i}}" placeholder="Name"
                                            class="form-control form-control-sm" [(ngModel)]="animals.name"
                                            #animal_name="ngModel" required
                                            [ngClass]="{ 'is-invalid': f.submitted && animal_name.invalid }">
                                        <div *ngIf="f.submitted && animal_name.invalid" class="invalid-feedback">
                                            Name is required.
                                        </div>
                                    </div>
                                    <mat-form-field class="w-100 mb-2">
                                        <mat-label>Sex</mat-label>
                                        <mat-select [(ngModel)]="animals.gender" class="form-control form-control-sm"
                                            name="gender_{{quote.quote_versions[0].alpaca_details.length + i}}"
                                            [ngClass]="{ 'is-invalid': f.submitted && gender.invalid }"
                                            #gender="ngModel" required>
                                            <mat-option [value]="">Select Sex</mat-option>
                                            <mat-option [value]="1">Male</mat-option>
                                            <mat-option [value]="2">Female</mat-option>
                                        </mat-select>
                                        <div *ngIf="f.submitted && gender.invalid" class="invalid-feedback">
                                            Gender is required.
                                        </div>
                                    </mat-form-field>
                                    <div class="form-group">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text bg-success text-white">&pound;</span>
                                            </div>
                                            <input type="number" appNumbers [min]="0" step="50"
                                                name="animal_value_{{quote.quote_versions[0].alpaca_details.length + i}}"
                                                placeholder="Value of this animal" class="form-control form-control-sm"
                                                [(ngModel)]="animals.value" #animal_value="ngModel" required
                                                [ngClass]="{ 'is-invalid': (f.submitted && animal_value.invalid) || totalAnimalsValue > totalAnimalsSumInsured}"
                                                (blur)="animalsTotalValue($event)">
                                            <div *ngIf="f.submitted && animal_value.invalid" class="invalid-feedback">
                                                Animal value is required.
                                            </div>
                                            <div *ngIf="totalAnimalsValue > totalAnimalsSumInsured"
                                                class="invalid-feedback">
                                                Value should be less than
                                                {{totalAnimalsSumInsured}} for all animals.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text"
                                            name="microchip_no_{{quote.quote_versions[0].alpaca_details.length + i}}"
                                            placeholder="Tag/ Microchip no." class="form-control form-control-sm"
                                            [(ngModel)]="animals.microchip" #microchip_no="ngModel" required
                                            [ngClass]="{ 'is-invalid': f.submitted && microchip_no.invalid }">
                                        <div *ngIf="f.submitted && microchip_no.invalid" class="invalid-feedback">
                                            Microchip number is required.
                                        </div>
                                    </div>
                                    <div class="w-100">
                                        <input type="text" required
                                            name="animal_dob_{{quote.quote_versions[0].alpaca_details.length + i}}"
                                            placeholder="Date of Birth" class="form-control form-control-sm datepicker"
                                            [(ngModel)]="animals.horse_purchase_date" #animal_dob="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && animal_dob.invalid }" matInput
                                            (focus)="dobDatepicker.open()" [matDatepicker]="dobDatepicker"
                                            [min]="minDOB" [max]="currDate">
                                        <mat-datepicker-toggle [for]="dobDatepicker"></mat-datepicker-toggle>
                                        <mat-datepicker #dobDatepicker></mat-datepicker>
                                        <div *ngIf="f.submitted && animal_dob.invalid" class="invalid-feedback">
                                            DOB is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </div>
                </div>
            </div>
            <div class="form_sections" *ngIf="quote.quote_versions[0].alpaca_public_liability_cover == 1">
                <mat-expansion-panel expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            More about your Smallholders Public and Product liability cover
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="form-group">
                        <label class="text-white f13">What type of animals do you keep?
                            <span
                                (click)="helpTextPopup(content,'This policy does not cover domestic animals nor guard dogs, nor animals in the Dangerous Wild Animals Act 1976.')"><i
                                    class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <mat-checkbox name="is_alpaca"
                                                    [(ngModel)]="quote.quote_versions[0].animal_type.is_alpaca"
                                                    (change)="onAnimalTypeChange($event)" [value]="1">
                                                    Alpaca/ Llama
                                                </mat-checkbox>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <mat-checkbox name="is_sheep"
                                                    [(ngModel)]="quote.quote_versions[0].animal_type.is_sheep"
                                                    (change)="onAnimalTypeChange($event)" [value]="2">
                                                    Sheep
                                                </mat-checkbox>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <mat-checkbox name="is_poultry"
                                                    [(ngModel)]="quote.quote_versions[0].animal_type.is_poultry"
                                                    (change)="onAnimalTypeChange($event)" [value]="3">
                                                    Poultry
                                                </mat-checkbox>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <mat-checkbox name="is_pig"
                                                    [(ngModel)]="quote.quote_versions[0].animal_type.is_pig"
                                                    (change)="onAnimalTypeChange($event)" [value]="4">
                                                    Pigs
                                                </mat-checkbox>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <mat-checkbox name="is_cattle"
                                                    [(ngModel)]="quote.quote_versions[0].animal_type.is_cattle"
                                                    (change)="onAnimalTypeChange($event)" [value]="5">
                                                    Cattle
                                                </mat-checkbox>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <mat-checkbox name="is_horse"
                                                    [(ngModel)]="quote.quote_versions[0].animal_type.is_horse"
                                                    (change)="onAnimalTypeChange($event)" [value]="6">
                                                    Horses
                                                </mat-checkbox>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <mat-checkbox name="is_other"
                                                    [(ngModel)]="quote.quote_versions[0].animal_type.is_other"
                                                    (change)="onAnimalTypeChange($event)" [value]="7">
                                                    Other farm animals
                                                </mat-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group" *ngIf="quote.quote_versions[0].animal_type.is_other == 1">
                                        <textarea name="other_farm_animal" placeholder="List the other animals"
                                            class="form-control" [(ngModel)]="quote.quote_versions[0].animal_type.other"
                                            #other_farm_animal="ngModel" required
                                            [ngClass]="{ 'is-invalid': f.submitted && other_farm_animal.invalid }">
                                            </textarea>
                                        <div *ngIf="f.submitted && other_farm_animal.invalid" class="invalid-feedback">
                                            Value is required.
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>
            <div class="form_sections" *ngIf="quote.quote_versions[0].alpaca_public_liability_cover == 1">
                <mat-expansion-panel expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            More about your employers liability cover
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="form-group">
                        <label class="text-white f13">How many employees and/or unpaid volunteers do you regularly
                            have?
                            <span
                                (click)="helpTextPopup(content,'This would include regular employees and/or unpaid volunteers plus if you have people looking after the animals when you are away from the premises, e.g., while you are away on holidays etc.')"><i
                                    class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-4">
                                <input type="text" name="number_of_employees" placeholder="Number"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="quote.quote_versions[0].employer_liability.number_of_employees"
                                    #number_of_employees="ngModel" required
                                    [ngClass]="{ 'is-invalid': f.submitted && number_of_employees.invalid }">
                                <div *ngIf="f.submitted && number_of_employees.invalid" class="invalid-feedback">
                                    Number is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="text-white f13">If you are not ERN exempt please enter your ERN number below
                            <span (click)="helpTextPopup(content,'If you pay wages under the PAYE Tax System you will have an Employer Reference Number (ERN number) <br/>
 
                                The ERN <br/>
                                •	Starts with 3 numbers between 001 and 999 (representing the tax office)<br/>
                                •	Then slash character<br/>
                                •	1 or 2 alpha characters<br/>
                                •	From 1 up to 6 numeric characters (e.g., 083/WY12345)<br/>
                                
                                If you do not enter an ERN number, you are confirming that you are ERN Exempt')"><i
                                    class="mdi mdi-comment-question-outline ml-1"></i></span>
                        </label>
                        <div class="row">
                            <div class="col-md-4">
                                <input type="text" name="ern_number" placeholder="ERN Number"
                                    class="form-control form-control-sm"
                                    pattern="^((\d{3})(\b/)([a-zA-Z]{1,2})(\d{1,6}))$"
                                    [(ngModel)]="quote.quote_versions[0].employer_liability.ern_number"
                                    #ern_number="ngModel"
                                    [ngClass]="{ 'is-invalid': f.submitted && ern_number.invalid }">
                                <div *ngIf="f.submitted  && ern_number.errors?.pattern" class="invalid-feedback">
                                    The ERN is not in correct format (e.g., 083/WY12345)

                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>
            <div class="form_sections" *ngIf="quote.quote_versions[0].alpaca_trailer_cover == 1 && quote.quote_versions[0].trailer_cover.number_of_trailers > 0">
                <mat-expansion-panel expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            More about your trailer cover
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="form_sections">
                        <mat-expansion-panel expanded
                            *ngFor="let trailer of quote.quote_versions[0].alpaca_trailor_items; let idx = index;">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Trailer {{idx + 1}}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text"
                                            name="make_model_{{quote.quote_versions[0].alpaca_trailor_items.length + idx}}"
                                            placeholder="Enter make & Model" class="form-control form-control-sm"
                                            required [(ngModel)]="trailer.brand"
                                            [ngClass]="{ 'is-invalid': f.submitted && trailer_brand.invalid }"
                                            #trailer_brand="ngModel">
                                        <div *ngIf="f.submitted && trailer_brand.invalid" class="invalid-feedback">
                                            Brand is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <input type="text"
                                            name="serial_number_{{quote.quote_versions[0].alpaca_trailor_items.length + idx}}"
                                            placeholder="Entre serial no:" class="form-control form-control-sm" required
                                            [(ngModel)]="trailer.chasis_number"
                                            [ngClass]="{ 'is-invalid': f.submitted && serial_number.invalid }"
                                            #serial_number="ngModel">
                                        <div *ngIf="f.submitted && serial_number.invalid" class="invalid-feedback">
                                            Serial number is required.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text bg-success text-white">&pound;</span>
                                            </div>
                                            <input type="text"
                                                name="sum_insured_{{quote.quote_versions[0].alpaca_trailor_items.length + idx}}"
                                                placeholder="How much is the trailer worth?"
                                                class="form-control form-control-sm" required
                                                [(ngModel)]="trailer.sum_insured" step="50"
                                                [ngClass]="{ 'is-invalid': f.submitted && sum_insured.invalid }"
                                                #sum_insured="ngModel">
                                            <div *ngIf="f.submitted && sum_insured.invalid" class="invalid-feedback">
                                                Value is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <input type="text"
                                            name="manufactured_year_{{quote.quote_versions[0].alpaca_trailor_items.length + idx}}"
                                            placeholder="Year of Manufacter" class="form-control form-control-sm"
                                            required [(ngModel)]="trailer.manufactured_year"
                                            [ngClass]="{ 'is-invalid': f.submitted && manufactured_year.invalid }"
                                            #manufactured_year="ngModel">
                                        <div *ngIf="f.submitted && manufactured_year.invalid" class="invalid-feedback">
                                            Year of manufacter is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group" style="margin-bottom: 0 !important;">
                                        <input type="text"
                                            name="trailer_postcode_{{quote.quote_versions[0].alpaca_trailor_items.length + idx}}"
                                            placeholder="Enter Postcode" class="form-control form-control-sm" required
                                            [(ngModel)]="trailer.postcode"
                                            [ngClass]="{ 'is-invalid': f.submitted && trailer_postcode.invalid }"
                                            #trailer_postcode="ngModel">
                                        <div *ngIf="f.submitted && trailer_postcode.invalid" class="invalid-feedback">
                                            Postcode is required.
                                        </div>
                                    </div>
                                    <mat-form-field class="w-100 mt-2">
                                        <mat-label>What security is in place</mat-label>
                                        <mat-select [(ngModel)]="trailer.trailer_security_id"
                                            class="form-control form-control-sm"
                                            name="security_{{quote.quote_versions[0].alpaca_trailor_items.length + idx}}"
                                            #security="ngModel" (selectionChange)="notrailersecurty($event,content)"
                                            required [ngClass]="{ 'is-invalid': f.submitted && security.invalid }">
                                            <mat-option [value]="security.id" *ngFor="let security of traierSecurity;">
                                                {{ security.name }}</mat-option>
                                        </mat-select>
                                        <div *ngIf="f.submitted && security.invalid" class="invalid-feedback">
                                            Please select an option.
                                        </div>
                                    </mat-form-field>
                                    <div class="form-group  mt-2"
                                        *ngIf="trailer.trailer_security_id == '54d8c0bb-b933-4f28-bc70-b5cc47fafb95'">
                                        <input type="text"
                                            name="security_other_{{quote.quote_versions[0].alpaca_trailor_items.length + idx}}"
                                            placeholder="Other Value" class="form-control form-control-sm" required
                                            [(ngModel)]="trailer.trailer_security_other_text"
                                            [ngClass]="{ 'is-invalid': f.submitted && trailer_security_other_text.invalid }"
                                            #trailer_security_other_text="ngModel">

                                        <div *ngIf="f.submitted && trailer_security_other_text.invalid"
                                            class="invalid-feedback">
                                            Value is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </div>
                </mat-expansion-panel>
            </div>
            <div class="form-group">
                <a (click)="backToQuoteDetailsTab()" class="btn btn-defaut btn-gray btn-sm">Back</a>
                <button type="submit" class="btn btn-success btn-sm float-right" [disabled]="is_disabled">Proceed with
                    Quote</button>
            </div>
        </div>
        <div class="col-md-4">
            <app-admin-al-calculations [Calculations]="Calculations" [quoteDetails]="quoteDetails">
            </app-admin-al-calculations>
        </div>
    </div>
</form>

<ng-template #content let-modal>
    <div class="modal-body">
        <div [innerHTML]="helptext"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn {{modelBtnClass}}" (click)="modal.close()">{{modelBtnText}}</button>
    </div>
</ng-template>
import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { QuoteService } from '../../quote/quote.service';
import { Email } from '../email.model';

@Component({
  selector: 'app-quote-email-view',
  templateUrl: './quote-email-view.component.html',
  styleUrls: ['./quote-email-view.component.css']
})
export class QuoteEmailViewComponent implements OnInit {
  @Output() backToEmailsList = new EventEmitter<any>();
  @Input() email_id: string;
  emailDetails = new Email();

  constructor(private quoteService: QuoteService) { }

  ngOnInit(): void {
  }

  attachment_details = [];
  ngOnChanges(changes: SimpleChanges) {
    // API - GET EMAILS DETAILS  
    if (this.email_id) {
      this.quoteService.getEmailsDetails(this.email_id)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.emailDetails = response.result[0];
              let attachments = response.result[0].attachments;
              if (response.result[0].attachments) {
                let attachments_array = attachments.split(';');
                console.log(attachments_array);
                attachments_array.forEach((res) => {
                  let splittedContent = res.split('|');
                  this.attachment_details.push(splittedContent);
                })
                console.log(this.attachment_details);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  returnToEmailsList() {
    this.backToEmailsList.emit();
  }

  downloadAttachments(attachments) {
    let data = {
      'id': attachments[1],
      'file_name': attachments[0],
      'title': attachments[0],
    }
    this.quoteService.downloadDocs(data);
  }
}

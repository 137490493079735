<div class="card pl-0 pr-0">
    <div class="card-body sidebar">
        <ul class="list-unstyled">
            <li><a routerLink="/dashboard"><i class="mdi mdi-view-dashboard"></i> Dashboard</a></li>
            <li *ngIf="UserType == 2"><a routerLink="/company"><i class=" mdi mdi-factory "></i> Company</a></li>
            <li *ngIf="UserType == 3"><a [routerLink]="['/user-list',userInfo.company_id]"><i
                        class="mdi mdi-account-multiple-outline"></i> Users</a></li>
            <li><a routerLink="/policy-list"><i class="mdi mdi-receipt"></i> Policy</a></li>
            <li><a (click)="submenu($event)" class="submenu" id="quote"><i class="mdi mdi-message-text-outline"></i>
                    Quote</a>
                <ul class="list-unstyled submenu_quote submenu_items">
                    <li><a routerLink="/quote-list"><i class="mdi mdi-message-text-outline"></i> Quote List</a></li>
                    <li><a routerLink="/quote-new"><i class="mdi mdi-message-text-outline"></i> Equine Quote</a></li>
                    <li *ngIf="isAlpaca"><a [routerLink]="['/alpaca-admin/pre-qualifier']"><i
                                class="mdi mdi-message-text-outline"></i> Alpaca Quote</a></li>
                </ul>
            </li>
            <li *ngIf="UserType == 2"><a (click)="submenu($event)" class="submenu" id="reports"><i class="mdi mdi-file-excel"></i>
                    Reports</a>
                <ul class="list-unstyled submenu_reports submenu_items">
                    <li><a routerLink="/bordereau"><i class="mdi mdi-file-excel"></i>
                            Bordereau</a></li>
                    <li><a routerLink="/live-policy-report"><i class="mdi mdi-file-excel"></i>
                        Live Policy Report</a></li>
                    <li><a routerLink="/lapse-report"><i class="mdi mdi-file-excel"></i>
                        Lapse Report</a></li>
                </ul>
            </li>

            <!-- <li><a href="dashboard"><i class="mdi mdi-alert-circle"></i> Claim</a></li> -->
        </ul>
    </div>
</div>
<!-- Policy Holder Details-->
<div class="form_sections">
    <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
            <mat-panel-title>
                About The Proposer
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row form-group">
            <div class="col-md-4"><label class="text-white f13">Proposer Type</label></div>
            <div class="col-md-6">
                <input type="text" name="proposer_type" placeholder="Proposer Type" class="form-control form-control-sm"
                    [value]="(quote.policy_holder.proposer_type == 1)?'Individual':((quote.policy_holder.proposer_type == 2)?'Micro Enterprise':'Commercial')"
                    readonly>
            </div>
        </div>
        <div *ngIf="quote.policy_holder.proposer_type == 1">
            <div class="row form-group">
                <div class="col-md-4"><label class="text-white f13">Name</label></div>
                <div class="col-md-6">
                    <input type="text" name="first_name" placeholder="Name" class="form-control form-control-sm"
                        [value]="quote.policy_holder.first_name + ' ' + quote.policy_holder.last_name" readonly>
                </div>
            </div>
        </div>
        <div *ngIf="quote.policy_holder.proposer_type != 1">
            <div class="row form-group">
                <div class="col-md-4"><label class="text-white f13">Name of Company</label></div>
                <div class="col-md-6">
                    <input type="text" name="company_name" placeholder="Name of Company"
                        class="form-control form-control-sm" [(ngModel)]="quote.policy_holder.company_name" readonly>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label class="text-white f13">Trading Name</label></div>
                <div class="col-md-6">
                    <input type="text" name="trading_name" placeholder="Trading Name"
                        class="form-control form-control-sm" [(ngModel)]="quote.policy_holder.trading_name" readonly>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-4"><label class="text-white f13">Email</label></div>
            <div class="col-md-6">
                <input type="email" name="email" placeholder="Email Address" class="form-control form-control-sm"
                    [(ngModel)]="quote.policy_holder.email" readonly>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-4"><label class="text-white f13">Contact Number 1</label></div>
            <div class="col-md-6">
                <input required type="text" name="phone" placeholder="Contact Number 1"
                    class="form-control form-control-sm" readonly [(ngModel)]="quote.policy_holder.phone">
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-4"><label class="text-white f13">Contact Number 2</label></div>
            <div class="col-md-6">
                <input required type="text" name="mobile" placeholder="Contact Number 2"
                    class="form-control form-control-sm" readonly [(ngModel)]="quote.policy_holder.mobile">
            </div>
        </div>
        <h5 class="text-white">Address Details</h5>
        <div class="form-group row">
            <div class="col-md-4"><label class="text-white f13">Address Line 1</label></div>
            <div class="col-md-6">
                <input type="text" name="policy_holder_address_line_1" placeholder="Line 1"
                    [(ngModel)]="quote.policy_holder.address.address1" class="form-control form-control-sm" readonly>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-4"><label class="text-white f13">Address Line 2</label></div>
            <div class="col-md-6">
                <input type="text" name="policy_holder_address_line_2" placeholder="Line 2"
                    [(ngModel)]="quote.policy_holder.address.address2" class="form-control form-control-sm" readonly>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-4"><label class="text-white f13">Town</label></div>
            <div class="col-md-6">
                <input type="text" name="policy_holder_city" placeholder="Town"
                    [(ngModel)]="quote.policy_holder.address.city" class="form-control form-control-sm" readonly>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-4"><label class="text-white f13">County</label></div>
            <div class="col-md-6">
                <input type="text" name="policy_holder_county" placeholder="County"
                    [(ngModel)]="quote.policy_holder.address.country" class="form-control form-control-sm" readonly>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-4"><label class="text-white f13">Postcode</label></div>
            <div class="col-md-6">
                <input type="text" name="postcode" placeholder="Postcode"
                    [(ngModel)]="quote.policy_holder.address.postcode" class="form-control form-control-sm" readonly>
            </div>
        </div>
    </mat-expansion-panel>
</div>

<!-- Alpac/Llama Details -->
<div class="form_sections" *ngIf="quote.is_animal_location_same_as_proposer == 2 && quote.animal_address">
    <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Animal Address
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row form-group">
            <div class="col-md-4"><label class="text-white f13">Address 1</label></div>
            <div class="col-md-6">
                <input type="text" name="animal_address_address1" placeholder="Address 1"
                    [(ngModel)]="quote.animal_address.address1" class="form-control form-control-sm" readonly>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-md-4"><label class="text-white f13">Address 2</label></div>
            <div class="col-md-6">
                <input type="text" name="animal_address_address2" placeholder="Address 2"
                    [(ngModel)]="quote.animal_address.address2" class="form-control form-control-sm" readonly>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-md-4"><label class="text-white f13">City</label></div>
            <div class="col-md-6">
                <input type="text" name="animal_address_city" placeholder="City" [(ngModel)]="quote.animal_address.city"
                    class="form-control form-control-sm" readonly>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-md-4"><label class="text-white f13">County</label></div>
            <div class="col-md-6">
                <input type="text" name="animal_address_county" placeholder="County"
                    [(ngModel)]="quote.animal_address.country" class="form-control form-control-sm" readonly>
            </div>
        </div>
    </mat-expansion-panel>
</div>

<!-- Alpac/Llama Details -->
<div class="form_sections" *ngIf="quote.alpaca_theft_cover == 1">
    <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
            <mat-panel-title>
                About the Alpaca's/ Llama's
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row form-group">
            <div class="col-md-4"><label class="text-white f13">Between 7 and 90 days old</label></div>
            <div class="col-md-6">
                <input type="number" appNumbers name="seven_to_ninety_days" placeholder="How many cria?"
                    class="form-control form-control-sm" [(ngModel)]="quote.about_alpaca.alpaca_7dto90d" readonly>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-md-4"><label class="text-white f13">Between 91 days & 12 years old</label></div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6">
                        <input type="number" appNumbers name="ninetyone_to_twelve_days" placeholder="How many alpacas?"
                            class="form-control form-control-sm" readonly
                            [(ngModel)]="quote.about_alpaca.alpaca_91dto12y">
                    </div>
                    <div class="col-md-6">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-success text-white">&pound;</span>
                            </div>
                            <input type="number" appNumbers name="sum_insured" style="font-size: 12px;"
                                placeholder="Enter the total value of insurance cover required"
                                class="form-control form-control-sm" readonly
                                [(ngModel)]="quote.about_alpaca.sum_insured">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="quote.type == 2">
            <div class="row form-group">
                <div class="col-md-4"><label class="text-white f13">Aged 13 years old</label></div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <input type="number" appNumbers name="alpaca_13y" placeholder="How many alpacas?"
                                class="form-control form-control-sm" readonly
                                [(ngModel)]="quote.about_alpaca.alpaca_13y">
                        </div>
                        <div class="col-md-6">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">&pound;</span>
                                </div>
                                <input type="number" appNumbers name="sum_insured" style="font-size: 12px;"
                                    placeholder="Enter the total value of insurance cover required"
                                    class="form-control form-control-sm" readonly
                                    [(ngModel)]="quote.about_alpaca.sum_insured_13y">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4"><label class="text-white f13">Aged 14 years old</label></div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <input type="number" appNumbers name="alpaca_14y" placeholder="How many alpacas?"
                                class="form-control form-control-sm" readonly
                                [(ngModel)]="quote.about_alpaca.alpaca_14y">
                        </div>
                        <div class="col-md-6">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">&pound;</span>
                                </div>
                                <input type="number" appNumbers name="sum_insured" style="font-size: 12px;"
                                    placeholder="Enter the total value of insurance cover required"
                                    class="form-control form-control-sm" readonly
                                    [(ngModel)]="quote.about_alpaca.sum_insured_14y">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row form-group" *ngIf="quote.about_alpaca.does_economic_slaughter_required == 1">
            <div class="col-md-4"><label class="text-white f13">Do you need Economic Slaughter/Loss of Use for STUD
                    MALES ONLY</label>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6">
                        <input type="number" appNumbers name="economic_slaughter_required_for" readonly
                            placeholder="How many stud males?" class="form-control form-control-sm"
                            [(ngModel)]="quote.about_alpaca.economic_slaughter_required_for">
                    </div>
                    <div class="col-md-6">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-success text-white">&pound;</span>
                            </div>
                            <input type="number" appNumbers name="economic_slaughter_sum_insured"
                                placeholder="Enter the total value of insurance cover required"
                                class="form-control form-control-sm" readonly
                                [(ngModel)]="quote.about_alpaca.economic_slaughter_sum_insured">

                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="row form-group">
            <div class="col-md-4"><label class="text-white f13">Do you need life saving surgical fee cover?</label>
            </div>
            <div class="col-md-6">
                <mat-radio-group class="row" required aria-label="Select an option" disabled name="fd_lifeSavingCover"
                    #lifeSavingCover ngmodel [(ngModel)]="quote.about_alpaca.does_life_saving_cover_required">
                    <div class="col-md-6">
                        <div class="quote_radio">
                            <mat-radio-button [value]="1">
                                Yes</mat-radio-button>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div>
                            <div class="quote_radio">
                                <mat-radio-button [value]="2">
                                    No</mat-radio-button>
                            </div>
                        </div>
                    </div>
                </mat-radio-group>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-md-4"><label class="text-white f13">Do you need all reasonable and normal vets treatment
                    costs
                    covered?</label></div>
            <div class="col-md-6">
                <mat-radio-group class="row" required aria-label="Select an option" name="fd_vetsTreatmentCost"
                    [(ngModel)]="quote.about_alpaca.does_vet_treatments_covered" disabled>
                    <div class="col-md-6">
                        <div class="quote_radio">
                            <mat-radio-button [value]="1">Yes
                            </mat-radio-button>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div>
                            <div class="quote_radio">
                                <mat-radio-button [value]="2">
                                    No</mat-radio-button>
                            </div>
                        </div>
                    </div>
                </mat-radio-group>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-md-4"><label class="text-white f13">How many claims have you made in the past 2 years for
                    the
                    animals?</label></div>
            <div class="col-md-6">
                <input type="number" appNumbers name="claims" placeholder="Claims" class="form-control form-control-sm"
                    [value]="quote.number_of_claims + ((quote.number_of_claims < 2)?' Claim':' Claims')" readonly>
            </div>
        </div>
    </mat-expansion-panel>
</div>

<!----- About the Public and Product liability cover ----->
<div class="form_sections" *ngIf="quote.alpaca_public_liability_cover == 1">
    <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
            <mat-panel-title>
                About the Public and Product liability cover
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row form-group">
            <div class="col-md-4"><label class="text-white f13">What is the acreage of the farm?</label></div>
            <div class="col-md-6">
                <input type="text" appNumbers name="acerage" readonly
                    placeholder="Enter the total acreage of the Smallholding" class="form-control form-control-sm"
                    [(ngModel)]="quote.alpaca_liability.acerage">
            </div>
        </div>
        <div class="row form-group">
            <div class="col-md-4"><label class="text-white f13">What is the limit of Public liability cover
                    required?</label></div>
            <div class="col-md-6">
                <mat-radio-group class="row" required aria-label="Select an option" disabled
                    name="fd_publicLiabilityCover" [(ngModel)]="quote.alpaca_liability.public_liability_cover">
                    <div class="col-md-4">
                        <div class="quote_radio">
                            <mat-radio-button [value]="1">&pound;1m
                            </mat-radio-button>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="quote_radio">
                            <mat-radio-button [value]="2">&pound;2m
                            </mat-radio-button>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="quote_radio">
                            <mat-radio-button [value]="5">&pound;5m
                            </mat-radio-button>
                        </div>
                    </div>
                </mat-radio-group>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-md-4"><label class="text-white f13">Do the public interact with the animals</label></div>
            <div class="col-md-6">
                <mat-radio-group class="row" required aria-label="Select an option" name="fd_publicInteractionAllowed"
                    disabled [(ngModel)]="quote.alpaca_liability.is_public_interaction_allowed">
                    <div class="col-md-4">
                        <div class="quote_radio">
                            <mat-radio-button [value]="1">Yes
                            </mat-radio-button>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="quote_radio">
                            <mat-radio-button [value]="2">No
                            </mat-radio-button>
                        </div>
                    </div>
                </mat-radio-group>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-md-4"><label class="text-white f13">What are the main activities undertaken by
                    Smallholding?</label></div>
            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-4" [hidden]="liabilities.is_visible_on_ui == 0"
                        *ngFor="let liabilities of alpacaLiabiities; let i = index;">
                        <div class="form-group">
                            <mat-checkbox name="smallholding_activities_{{i}}" disabled value="{{liabilities.id}}"
                                [checked]="liabilities.is_checked">
                                {{liabilities.policy_cover}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</div>

<!----- Animal Details ----->
<div class="form_sections" [hidden]="quote.alpaca_theft_cover == 2"
    *ngFor="let animals of quote.alpaca_details; let i = index;">
    <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{(animals.name)?animals.name:'Animal ' + (i+1)}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row form-group">
            <div class="col-md-4"><label class="text-white f13">Name</label></div>
            <div class="col-md-6">
                <input type="text" name="animal_name_{{i}}" placeholder="Name" readonly
                    class="form-control form-control-sm" [(ngModel)]="animals.name">
            </div>
        </div>
        <div class="row form-group">
            <div class="col-md-4"><label class="text-white f13">Sex</label></div>
            <div class="col-md-6">
                <mat-form-field class="w-100" style="margin-top: -5px;">
                    <mat-label>Sex</mat-label>
                    <mat-select [(ngModel)]="animals.gender" class="form-control form-control-sm"
                        name="gender_{{quote.alpaca_details.length + i}}" disabled>
                        <mat-option [value]="1">Male</mat-option>
                        <mat-option [value]="2">Female</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-md-4"><label class="text-white f13">Value of the animal</label></div>
            <div class="col-md-6">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text bg-success text-white">&pound;</span>
                    </div>
                    <input type="text" appNumbers name="animal_value_{{quote.alpaca_details.length + i}}"
                        placeholder="Value of this animal" class="form-control form-control-sm"
                        [(ngModel)]="animals.value" readonly>
                </div>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-md-4"><label class="text-white f13">Tag/ Microchip no.</label></div>
            <div class="col-md-6">
                <input type="text" name="microchip_no_{{quote.alpaca_details.length + i}}"
                    placeholder="Tag/ Microchip no." class="form-control form-control-sm"
                    [(ngModel)]="animals.microchip" readonly>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-md-4"><label class="text-white f13">Date of Birth</label></div>
            <div class="col-md-6">
                <input type="text" readonly name="animal_dob_{{quote.alpaca_details.length + i}}"
                    placeholder="Date of Birth" class="form-control form-control-sm"
                    [value]="animals.horse_purchase_date | date:'dd-MM-yyyy'">
            </div>
        </div>
    </mat-expansion-panel>
</div>

<!----- Smallholders Public and Product liability covers ----->
<div class="form_sections" *ngIf="quote.alpaca_public_liability_cover == 1">
    <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
            <mat-panel-title>
                More about your Smallholders Public and Product liability cover
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="form-group">
            <label class="text-white f13">What type of animals do you keep?</label>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <mat-checkbox name="is_alpaca" [(ngModel)]="quote.animal_type.is_alpaca" [value]="1"
                                        disabled>
                                        Alpaca/ Llama
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <mat-checkbox name="is_sheep" [(ngModel)]="quote.animal_type.is_sheep" [value]="2"
                                        disabled>
                                        Sheep
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <mat-checkbox name="is_poultry" [(ngModel)]="quote.animal_type.is_poultry"
                                        [value]="3" disabled>
                                        Poultry
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <mat-checkbox name="is_pig" [(ngModel)]="quote.animal_type.is_pig" [value]="4"
                                        disabled>
                                        Pigs
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <mat-checkbox name="is_cattle" [(ngModel)]="quote.animal_type.is_cattle" [value]="5"
                                        disabled>
                                        Cattle
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <mat-checkbox name="is_horse" [(ngModel)]="quote.animal_type.is_horse" [value]="6"
                                        disabled>
                                        Horses
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <mat-checkbox name="is_other" [(ngModel)]="quote.animal_type.is_other" [value]="7"
                                        disabled>
                                        Other farm animals
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="quote.animal_type.is_other == 1">
                            <textarea name="other_farm_animal" placeholder="List the other animals" class="form-control"
                                [(ngModel)]="quote.animal_type.other" readonly>
                                    </textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</div>

<!----- Smallholders Public and Product liability covers ----->
<div class="form_sections" *ngIf="quote.alpaca_public_liability_cover == 1 && quote.employer_liability">
    <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
            <mat-panel-title>
                More about your employers liability cover
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row form-group">
            <div class="col-md-4"><label class="text-white f13">How many employees and/or unpaid volunteers do you
                    regularly
                    have?</label></div>
            <div class="col-md-6">
                <input type="text" name="number_of_employees" placeholder="Number" class="form-control form-control-sm"
                    readonly [(ngModel)]="quote.employer_liability.number_of_employees">
            </div>
        </div>
        <div class="row form-group">
            <div class="col-md-4"><label class="text-white f13">If you are not ERN exempt please enter your ERN number
                    below</label></div>
            <div class="col-md-6">
                <input type="text" name="ern_number" placeholder="ERN Number" class="form-control form-control-sm"
                    readonly [(ngModel)]="quote.employer_liability.ern_number">
            </div>
        </div>
    </mat-expansion-panel>
</div>

<!-- Trailer Cover -->
<div class="form_sections" *ngIf="quote.alpaca_trailer_cover == 1">
    <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
            <mat-panel-title>
                More about your trailer cover
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="form_sections">
            <mat-expansion-panel expanded *ngFor="let trailer of quote.alpaca_trailor_items; let idx = index;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Trailer {{idx + 1}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="make_model_{{quote.alpaca_trailor_items.length + idx}}"
                                placeholder="Enter make & Model" class="form-control form-control-sm"
                                [(ngModel)]="trailer.brand" readonly>
                        </div>
                        <div class="form-group">
                            <input type="text" name="serial_number_{{quote.alpaca_trailor_items.length + idx}}"
                                placeholder="Entre serial no:" class="form-control form-control-sm"
                                [(ngModel)]="trailer.chasis_number" readonly>
                        </div>
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-success text-white">&pound;</span>
                                </div>
                                <input type="text" name="sum_insured_{{quote.alpaca_trailor_items.length + idx}}"
                                    placeholder="How much is the trailer worth?" class="form-control form-control-sm"
                                    readonly [(ngModel)]="trailer.sum_insured">
                            </div>
                        </div>
                        <div class="form-group">
                            <input type="text" name="manufactured_year_{{quote.alpaca_trailor_items.length + idx}}"
                                placeholder="Year of Manufacter" class="form-control form-control-sm"
                                [(ngModel)]="trailer.manufactured_year" readonly>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" name="trailer_postcode_{{quote.alpaca_trailor_items.length + idx}}"
                                placeholder="Enter Postcode" class="form-control form-control-sm" readonly
                                [(ngModel)]="trailer.postcode">
                        </div>
                        <mat-form-field class="w-100">
                            <mat-label>What security is in place</mat-label>
                            <mat-select [(ngModel)]="trailer.trailer_security_id" class="form-control form-control-sm"
                                name="security" disabled>
                                <mat-option [value]="security.id" *ngFor="let security of traierSecurity;">
                                    {{ security.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="form-group"
                            *ngIf="trailer.trailer_security_id == '54d8c0bb-b933-4f28-bc70-b5cc47fafb95'">
                            <input type="text" name="security_other_{{quote.alpaca_trailor_items.length + idx}}"
                                placeholder="Other Value" class="form-control form-control-sm" required
                                [(ngModel)]="trailer.trailer_security_other_text" readony>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
    </mat-expansion-panel>
</div>

<!-- Endorsement -->
<app-quote-endorsement [quote]="quote"></app-quote-endorsement>
<!-- <div class="form_sections">
    <p>Endorsement <a (click)="addEndorsement(content)" *ngIf="isAdmin" class="btn btn-success btn-sm ml-3">Add
            Endorsement</a></p>
    <ag-grid-angular style="width: 100%;min-height: 300px;" class="ag-theme-bootstrap mt-3" [gridOptions]="gridOptions"
        [rowData]="rowData" [columnDefs]="columnDefs" animateRows pagination=false paginationPageSize=5 rowHeight=100
        [defaultColDef]="defaultColDef" headerHeight=35 domLayout='autoHeight' [editType]='editType'
        (columnResized)="onColumnResized($event)" (rowClicked)='onGridRowClicked($event,content,deleteModel, endorsementViewModel)'>
    </ag-grid-angular>
</div>

<ng-template #content let-modal>
    <form #f="ngForm" (ngSubmit)="f.form.valid && onEndorsementSubmit(f)">
        <div class="modal-body">
            <div class="form-group">
                <label>Title</label>
                <input type="text" name="title" class="form-control form-control-sm" [(ngModel)]="endorsement.title"
                    [ngClass]="{ 'is-invalid': f.submitted && title.invalid }" #title="ngModel" required>
                <div *ngIf="f.submitted && title.invalid" class="invalid-feedback">
                    Title is required.
                </div>
            </div>
            <div class="form-group">
                <label>Type</label>
                <select [(ngModel)]="endorsement.type" class="form-control form-control-sm" name="endorsement_type"
                    [ngClass]="{ 'is-invalid': f.submitted && endorsement_type.invalid }" #endorsement_type="ngModel"
                    required style="border: 1px solid #f1f6f8;">
                    <option [value]="">Please select</option>
                    <option [value]="1">Endorsement</option>
                    <option [value]="2">Subjectivity</option>
                    <option [value]="3">Exclusion</option>
                </select>
                <div *ngIf="f.submitted && endorsement_type.invalid" class="invalid-feedback">
                    Type is required.
                </div>
            </div>
            <div class="form-group">
                <label>Endorsement</label>
                <textarea name="endorsement_text" class="form-control" [(ngModel)]="endorsement.endorsement_text"
                    rows="5" required #endorsement_text="ngModel"
                    [ngClass]="{ 'is-invalid': f.submitted && endorsement_text.invalid }"></textarea>
                <div *ngIf="f.submitted && endorsement_text.invalid" class="invalid-feedback">
                    Endorsement text is required.
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" name="submit" class="btn btn-success btn-sm pl-4 pr-4 pt-2 pb-2">Save</button>
            <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
        </div>
    </form>
</ng-template>
<ng-template #deleteModel let-modal>
    <div class="modal-body">
        <p>Are you sure you want to delete endorsement?</p>
    </div>

    <div class="modal-footer">
        <button type="button" name="ok" (click)="deleteEndorsement(endorsement.id,selectedRow)"
            class="btn btn-success btn-sm pl-4 pr-4 pt-2 pb-2">OK</button>
        <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
    </div>
</ng-template>
<ng-template #endorsementViewModel let-modal>
    <div class="modal-body">
        {{ endorsementText }}
    </div>
</ng-template> -->
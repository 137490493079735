import { Component, OnInit, Input, ElementRef, SimpleChanges, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { QuoteVersions } from '../../model/quote/quote_versions.model';
import { PolicyService } from '../policy.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { formatDate } from '@angular/common';
import { QuoteService } from '../../quote/quote.service';
import { EventEmitter } from 'events';

@Component({
  selector: 'app-policy-payment',
  templateUrl: './policy-payment.component.html',
  styleUrls: ['../../quote/quote.component.css'],
})
export class PolicyPaymentComponent implements OnInit {
  @Input() quote: QuoteVersions;
  @Output() quoteDetailsUpdated = new EventEmitter();

  constructor(private el: ElementRef, private policyService: PolicyService,
    private _snackBar: MatSnackBar, private quoteService: QuoteService) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.quote && !this.quote.paid_date) {
      this.quote.paid_date = new Date();
    }
  }

  onSubmit(f: NgForm) {
    if (!f.form.valid) {
      for (const key of Object.keys(f.controls)) {
        if (f.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('input[name=' + key + '], mat-select[name=' + key + ']');
          invalidControl.focus();
          invalidControl.scrollIntoView({ behavior: 'smooth' });
          break;
        }
      }

      return false;
    }

    let data = {
      quote_id: this.quote.id,
      is_paid: this.quote.is_paid,
      paid_date: formatDate(this.quote.paid_date, 'yyyy-MM-dd', 'en-US')
    };

    this.policyService.savePaidStatus(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-success'
            });
           window.location.reload();
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
        });
  }

}

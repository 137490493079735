<div class="card-header bg-success">Dashboard</div>
<div class="card-body">
    <h5 class="main_title text-center" style="font-size: 28px;">
        <span style="line-height: 38px;">Hi <b>{{userinfo.userinfo.first_name}}</b>,</span> <br>
        Welcome to Peliwica’s Broker Insurance Marketplace
    </h5>

    <div class="row mt-4">
        <div class="col-md-12">
            <h5 class="mb-4">Management Information</h5>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="form-group mb-0">
                <mat-form-field class="w-100">
                    <mat-label>Frequency </mat-label>
                    <mat-select class="form-control form-control-sm" [(ngModel)]="frequency" name="product"
                        (selectionChange)="onFrequencyChange($event)">
                        <mat-option [value]="1">Daily</mat-option>
                        <mat-option [value]="2">Monthly</mat-option>
                        <mat-option [value]="3">Quarterly</mat-option>
                        <mat-option [value]="4">Bi-Annual</mat-option>
                        <mat-option [value]="5">Annual</mat-option>
                        <mat-option [value]="6">Month to date</mat-option>
                        <mat-option [value]="7">Year to date</mat-option>
                        <mat-option [value]="8">Other</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col-md-8" *ngIf="frequency == 8">
            <div class="row">
                <div class="col-md-4 mt-2">
                    <div class="form-group">
                        <input type="text" required name="start_date" placeholder="Start Date"
                            class="form-control form-control-sm datepicker" matInput (focus)="myDatepicker.open()"
                            [matDatepicker]="myDatepicker" [(ngModel)]="mi_start_date" style="width: 82%;">
                        <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #myDatepicker></mat-datepicker>
                    </div>
                </div>
                <div class="col-md-4 mt-2">
                    <div class="form-group">
                        <input type="text" required name="end_date" placeholder="End Date"
                            class="form-control form-control-sm datepicker" matInput (focus)="endDateDatepicker.open()"
                            [matDatepicker]="endDateDatepicker" [(ngModel)]="mi_end_date" style="width: 82%;">
                        <mat-datepicker-toggle [for]="endDateDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #endDateDatepicker></mat-datepicker>
                    </div>
                </div>
                <div class="col-md-4 mt-2">
                    <a (click)="filterMIByDate()" class="btn btn-success btn-sm">Get Details</a>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="miData">
        <div class="col-md-12">
            <div class="row text-green text-center">
                <div class="col">
                    <div class="bg-white p-3">No. of issued quotes<br><b>{{ miData.no_of_issued_quotes }}</b></div>
                </div>
                <div class="col">
                    <div class="bg-white p-3">No. of live policies<br><b>{{ miData.no_of_live_policies }}</b></div>
                </div>
                <div class="col">
                    <div class="bg-white p-3">Conversion Rate<br><b>{{ miData.conversion_rate }}</b>
                    </div>
                </div>
                <div class="col">
                    <div class="bg-white p-3">No. of lapsed policies<br><b>{{ miData.lapsed_policy_count }}</b></div>
                </div>
                <div class="col">
                    <div class="bg-white p-3">No. of Cancelled policies<br><b>{{ miData.cancelled_policy_count }}</b>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-center"><a [routerLink]="['/dashboard-mi',frequency]"
                        class="btn btn-success btn-sm">View More</a></div>
            </div>

        </div>
    </div>

    <div class="row mt-5">
        <div class="col-md-4 text-center">
            <div class="dashboard_images">
                <a routerLink="/quote-new">
                    <img src="assets/images/equine.jpg" class="img-fluid" alt="New Horse Insurance Quote">
                    <span class="mt-2 text-white">New Horse Insurance Quote</span>
                </a>
            </div>
        </div>
        <div class="col-md-4 text-center">
            <div class="dashboard_images">
                <a routerLink="/alpaca-admin/pre-qualifier">
                    <img src="assets/images/alpaca.jpg" class="img-fluid" alt="Alpaca">
                    <span class="mt-2 text-white">Alpaca</span>
                </a>
            </div>
        </div>
        <div class="col-md-4 text-center">
            <div class="dashboard_images">
                <a><img src="assets/images/dashboard_3.jpg" class="img-fluid" alt="Caption" style="min-height: 218px;">
                    <span class="mt-2 text-white">Caption</span>
                </a>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-4 text-center">
            <div class="dashboard_images">
                <a><img src="assets/images/dashboard_4.jpg" class="img-fluid" alt="Caption">
                    <span class="mt-2 text-white">Caption</span></a>
            </div>
        </div>
        <div class="col-md-4 text-center">
            <div class="dashboard_images">
                <a><img src="assets/images/dashboard_5.jpg" class="img-fluid" alt="Caption">
                    <span class="mt-2 text-white">Caption</span></a>
            </div>
        </div>
        <div class="col-md-4 text-center">
            <div class="dashboard_images">
                <a><img src="assets/images/dashboard_6.jpg" class="img-fluid" alt="Caption">
                    <span class="mt-2 text-white">Caption</span></a>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { formatDate, TitleCasePipe } from '@angular/common';
import { GridOptions } from "ag-grid-community";
import { QuoteService } from '../../quote/quote.service';
import { LocalStorageService } from 'ngx-webstorage';
import { QuoteList } from '../../model/quote/quote-list.model';
import { PolicyService } from '../policy.service';
import { Router } from "@angular/router";
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserInfoService } from '../../services/user-info.service';

@Component({
  selector: 'app-policy-list',
  templateUrl: './policy-list.component.html',
  styleUrls: ['../../quote/quote.component.css']
})
export class PolicyListComponent implements OnInit {
  quote = new QuoteList();
  EQUINE_ID = environment.EQUINE_ID;
  ALPACA_ID = environment.ALPACA_ID;

  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
    }
  };
  columnDefs = [
    {
      headerName: 'Inception Date', field: 'policy_start_date', sortable: true, filter: false, width: 230,
      cellRenderer: function (params) {
        return formatDate(params.data.policy_start_date, 'dd-MM-yyyy', 'en-US');
      }
    },
    {
      headerName: 'Policy No.', field: 'policy_ref_number', sortable: true, filter: false, width: 250,
      cellRenderer: function (params) {
        return params.data.policy_ref_number;
      }
    },
    { headerName: 'Ver. No.', field: 'current_version', sortable: true, filter: false, width: 100 },
    {
      headerName: 'Customer Name', field: 'first_name', sortable: true, filter: false, width: 260,
      cellRenderer: function (params) {
        //return params.data.first_name + ' ' + params.data.last_name;
        return params.data.customer_name;
      },
      tooltip: function (params) {
        //return params.data.first_name + ' ' + params.data.last_name;
        return params.data.customer_name;
      },
    },

    {
      headerName: 'Broker Company', field: 'broker_name', sortable: true, width: 300,
      hide: (this.sessionSt.retrieve('company_role') == 'Broker' && this.sessionSt.retrieve('user_group') == 'Administrator') ? true : false,
      tooltip: function (params) {
        return params.data.broker_name;
      },
    },
    {
      headerName: 'Policy Type', field: 'quote_type', sortable: true, filter: false,
      tooltip: function (params) {
        return params.data.quote_type;
      },
    },
    // {
    //   headerName: 'Status', field: 'quote_status', sortable: true,
    //   tooltip: function (params) {
    //     return params.data.quote_status;
    //   },
    // },
    {
      headerName: 'Product Name', field: 'product_name', sortable: true,
      tooltip: function (params) {
        return params.data.product_name;
      },
    },
    {
      headerName: 'Transaction Date', field: 'transactional_date', sortable: true, filter: false, width: 270,
      cellRenderer: function (params) {
        if (params.data.transactional_date) {
          return formatDate(params.data.transactional_date, 'dd-MM-yyyy', 'en-US');
        } else {
          return '';
        }
      }
    },
    {
      headerName: 'Gross Premium', field: 'policy_premium', sortable: true, filter: false,
      cellRenderer: function (params) {
        let amount = 0.00;
        if (params.data.policy_premium != null) {
          amount = params.data.policy_premium;
        }
        return '&pound;' + amount;
      },
      tooltip: function (params) {
        return params.data.policy_premium;
      },
    },
    /*{
      headerName: 'IPT', field: 'ipt_fee', sortable: true, filter: false,
      cellRenderer: function (params) {
        let amount = 0.00;
        if (params.data.ipt_fee != null) {
          amount = params.data.ipt_fee;
        }
        return amount + '%';
      },
      tooltip: function (params) {
        return params.data.ipt_fee + '%';
      },
    },*/
    {
      headerName: 'Fees', field: 'fees', sortable: true, filter: false,
      cellRenderer: function (params) {
        let amount = 0.00;
        if (params.data.fees != null) {
          amount = params.data.fees;
        }
        return '&pound;' + amount;
      },
      tooltip: function (params) {
        return params.data.fees;
      },
    },
    {
      headerName: 'Total Premium', field: 'total_policy_cost', sortable: true, filter: false,
      cellRenderer: function (params) {
        let amount = 0.00;
        if (params.data.total_policy_cost != null) {
          amount = params.data.total_policy_cost;
        }
        return '&pound;' + amount;
      },
      tooltip: function (params) {
        return params.data.total_policy_cost;
      },
    },
    {
      headerName: 'Action', width: 200,
      cellRenderer: function (params) {
        let links = '';
        if (params.data.product_id == environment.ALPACA_ID) {
          links += '<a class="text-primary" target="_blank" href="alpaca-admin/policy/' + params.data.quote_id + '" title="View Quote Details" style="font-size: 18px;"><i class="mdi mdi-eye"></i></a>';
        } else {
          links += '<a class="text-primary" target="_blank" href="policy/' + params.data.quote_id + '" title="View Policy Details" style="font-size: 18px;"><i class="mdi mdi-eye"></i></a>';
        }
        if (params.data.isAdmin) {
          links += '<a title="Delete Quote" style="font-size: 18px;" class="text-danger ml-2"><i class="mdi mdi-delete" data-action-type="delete"></i></a>';
        }
        return links;
      }
    }
  ];
  rowData: any = [];

  constructor(private quoteService: QuoteService, private sessionSt: LocalStorageService, private modalService: NgbModal, private userService: UserInfoService,
    private router: Router, private policyService: PolicyService, private titlecasePipe: TitleCasePipe, private _snackBar: MatSnackBar) { }

  brokers;
  quoteTypes;
  policyStatuses;
  ngOnInit(): void {
    this.userService.getUserDetails();
    this.quote.company_id = this.sessionSt.retrieve('userInfo').company_id;
    this.searchRecords();

    // Get All Brokers
    this.quoteService.getBrokers()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.brokers = response.result;
          }
        },
        (error) => {
          console.log(error);
        }
      );

    // Get Quote Types
    this.quoteService.getQuoteTypes(1)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quoteTypes = response.result.types;
          }
        },
        (error) => {
          console.log(error);
        }
      );

    // Get Policy Statuses
    this.policyService.getPolicyStatuses()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.policyStatuses = response.result.policy_insurance_status;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /*
  * SEARCHING
  */
  searchRecords() {
    if (!this.quote.company_id) {
      this.quote.company_id = this.sessionSt.retrieve('userInfo').company_id;
    }
    if (this.quote.from != '') {
      let formattedDate = formatDate(this.quote.from, 'yyyy-MM-dd', 'en-US');
      this.quote.from = formattedDate;
    }
    if (this.quote.to != '') {
      let formattedDateTo = formatDate(this.quote.to, 'yyyy-MM-dd', 'en-US');
      this.quote.to = formattedDateTo;
    }
    this.policyService.searchPolicy(this.quote)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.rowData = response.result;
            response.result.forEach((res) => {
              res.first_name = this.titlecasePipe.transform(res.first_name);
              res.last_name = this.titlecasePipe.transform(res.last_name);
              if (this.sessionSt.retrieve('company_role') == 'Peliwica' && this.sessionSt.retrieve('user_group') == 'Administrator') {
                res.isAdmin = true;
              }
            })
            this.quote.total_records = response.total_records;
            this.quote.total_net_rate = response.total_net_rate;
            this.quote.total_gross_premium = response.total_gross_premium;
            this.quote.total_fees = response.total_fees;
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
        });
  }

  /*
  * GRID BUTTONS ACTION
  */
  msg: string = '';
  quote_id: string = '';
  rowIndex: number = 0;
  onGridRowClicked(e: any, content) {
    if (e) {
      let quote_meta_id = e.event.target.getAttribute("id");
      this.quote_id = e.data.quote_id;
      this.rowIndex = e.rowIndex;
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "edit":
          {
            this.router.navigate(['/quote-new', quote_meta_id]);
            break;
          }
        case "view":
          {
            this.router.navigate(['/policy', quote_meta_id]);
            break;
          }
        case "delete":
          {
            this.msg = 'Are you sure you want to delete this policy?';
            this.modalService.open(content, { centered: true });
            break;
          }
      }
    }
  }

  /*
 * DELETE QUOTE
 */
  deleteQuote(quote_id, rowIndex) {
    let data: any = '';
    data = {
      quote_id: quote_id
    };

    this.quoteService.deleteQuote(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            this.rowData.splice(rowIndex, 1);
            this.gridOptions.api.setRowData(this.rowData)
            this._snackBar.open(response.message, '', {
              duration: 5000
            });

          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  /*
  * CLEAR FILTERS
  */
  clearFilters(f) {
    f.reset({});
    this.quote.from = '';
    this.quote.to = '';
    this.searchRecords();
  }
}

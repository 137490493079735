import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { QuoteVersions } from '../../../quote/alpaca/model/quote/quote_versions.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuoteService } from '../../../quote/quote.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GridOptions } from "ag-grid-community";
import { QuoteNotes } from '../../../model/quote/quote_notes.model';
import { formatDate } from '@angular/common';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-al-policy-notes',
  templateUrl: './notes.component.html',
  styles: [
  ]
})
export class AlPolicyNotesComponent implements OnInit {
  @Input() quoteData: QuoteVersions;
  @Output() hasImportantNotes = new EventEmitter();
  notes = new QuoteNotes();
  quote;

  constructor(private quoteService: QuoteService, private modalService: NgbModal, 
    private sessionSt: LocalStorageService, private _snackBar: MatSnackBar) { }
  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteData;
    this.isImportantNote();
  }
  ngOnInit(): void {

  }

  ngDoCheck() {
    if (this.quoteData && this.quoteData.notes) {
      if (this.sessionSt.retrieve('company_role') == 'Peliwica' && this.sessionSt.retrieve('user_group') == 'Administrator') {
        this.quoteData.notes.forEach((res) => {
          res.isAdmin = true;
        })

      }
      this.rowData = this.quoteData.notes;
    }
  }

  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
    }
  };
  columnDefs = [
    {
      headerName: ' ', field: 'notes', sortable: true, width: 500,
      cellRenderer: function (params) {
        return ((params.data.is_important == 1) ? '<label class="badge badge-danger">Important</label> ' : '') + '<a data-action-type="view">' + params.data.notes + '</a>';
      },
      tooltip: function (params) {
        return params.data.notes;
      },
    },
    {
      headerName: 'Created By', sortable: false, width: 120,
      cellRenderer: function (params) {
        if (params.data.user) {
          return params.data.user.first_name + ' ' + params.data.user.last_name;
        }
      }
    },
    {
      headerName: 'Created At', sortable: false, width: 200,
      cellRenderer: function (params) {
        return formatDate(params.data.created_at, 'dd-MM-yyyy hh:mm a', 'en-US');
      }
    },
    {
      headerName: null,
      width: 120, type: 'rightAligned',
      cellRenderer: function (params) {
        let links = '';
        if (params.data.isAdmin) {
          links += '<a title="Edit Notes" style="font-size: 18px;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
        }
        // links += '<a title="Delete" style="font-size: 18px; margin-left: 20px;"><i class="mdi mdi-close-circle-outline text-danger" data-action-type="delete" id="' + params.data.id + '"></i></a>';
        return links;
      }
    }
  ];
  rowData: any;

  selectedRow;
  notesText;
  onGridRowClicked(e: any, content, deleteModel, notesViewModel) {
    if (e) {

      let id = e.event.target.getAttribute("id");
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "edit":
          {

            const modalRef = this.modalService.open(content, { centered: true });
            this.notes = e.data;
            break;
          }
        case "view":
          {
            this.modalService.open(notesViewModel, { centered: true });
            this.notesText = e.data.notes;
            break;
          }
        case "delete": {
          this.modalService.open(deleteModel, { centered: true });
          this.selectedRow = this.gridOptions.api.getFocusedCell();
          this.notes = e.data;
        }
      }
    }
  }

  deleteNotes(id, selectedRow) {
    this.quoteService.deleteNotes(id)
      .subscribe(
        (response: any) => {
          this.modalService.dismissAll();
          this.gridOptions.rowData.splice(selectedRow.rowIndex, 1);
          this.gridOptions.api.setRowData(this.gridOptions.rowData);
        });
  }

  /*
  * ADD NOTES
  */
  openNotesPopup(content) {
    this.modalService.open(content, { centered: true });
    this.notes = new QuoteNotes();
  }

  saveNotes() {
    let data = {
      id: this.notes.id,
      quote_id: this.quote.id, //this.route.snapshot.params.quote_meta_id,
      notes: this.notes.notes,
      is_important: this.notes.is_important
    };
    this.quoteService.saveNotes(data)
      .subscribe(
        (response: any) => {
          if(response.success) {
            response.result.notes_list.forEach((res) => {
              res.isAdmin = (this.sessionSt.retrieve('company_role') == 'Peliwica' && this.sessionSt.retrieve('user_group') == 'Administrator') ? true : false;
            })
            this.quote.notes = response.result.notes_list;
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
          // if (!this.notes.id) {
          //   this.gridOptions.rowData.push({
          //     'id': response.result.note_id,
          //     'notes': this.notes.notes,
          //     'user': response.result.user,
          //     'created_at': response.result.created_at,
          //     'is_important': this.notes.is_important,
          //     'isAdmin': (this.sessionSt.retrieve('company_role') == 'Peliwica' && this.sessionSt.retrieve('user_group') == 'Administrator') ? true : false
          //   });
          //   this.gridOptions.api.setRowData(this.gridOptions.rowData);
          // } else {
          //   this.rowData.forEach((res) => {
          //     if (this.notes.id == res.id) {
          //       res.notes = this.notes.notes
          //     }
          //   });
          //   this.gridOptions.api.setRowData(this.rowData);
          // }
          this.isImportantNote();
          //this.gridOptions.api.refreshCells();
          this.modalService.dismissAll();
        });
  }

  isImportantNote() {
    let is_important = false;
    if (this.rowData) {
      this.rowData.forEach((res) => {
        if (res.is_important == 1) {
          is_important = true;
        }
      })
      this.hasImportantNotes.emit(is_important);
    }
  }
}

export class AlCalculationsDetails {
    age_7_90_rate: number = 0.0;
    age_7_90_sum_insured: number = 0.0;
    age_7_90_premium: number = 0.0;
    age_7_90_total_count: number = 0.0;
    age_above_90_rate: number = 0.0;
    age_above_90_sum_insured: number = 0.0;
    age_above_90_premium: number = 0.0;
    age_above_90_total_count: number = 0.0;

    age_13_rate: number = 0.0;
    age_13_sum_insured: number = 0.0;
    age_13_premium: number = 0.0;
    age_13_total_count: number = 0.0;

    age_14_rate: number = 0.0;
    age_14_sum_insured: number = 0.0;
    age_14_premium: number = 0.0;
    age_14_total_count: number = 0.0;

    male_economic_slaughter_rate: number = 0.0;
    male_economic_slaughter_sum_insured: number = 0.0;
    male_economic_slaughter_premium: number = 0.0;
    life_saving_surgical_fees: number = 0.0;
    life_saving_surgical_premium: number = 0.0;


    veterinary_fees: number = 0.0;
    veterinary_premium: number = 0.0;
    livestock_premium: number = 0.0;
    total_premium: number = 0.0;
    total_gross_premium: number = 0.0;
    acrage: number = 0.0;
    minimum_acerage: number = 0.0;

    trailer_sum_insured: number = 0.0;
    trailer_rate: number = 0.0;
    trailer_minimum_premium: number = 0.0;
    trailer_premium: number = 0.0;


    basic_liabilty_premium: number = 0.0;
    basic_liabilty_minimum_premium: number = 0.0;
    basic_liabilty_excess_acrage: number = 0.0;

    alpaca_experience_session_premium: number = 0.0;
    alpaca_experience_minimum_premium: number = 0.0;
    alpaca_excess_acrage: number = 0.0;

    visit_insured_premises: number = 0.0;
    visit_to_extrenal_venues: number = 0.0;
    trade_stand: number = 0.0;
    employer_liability: number = 0.0;
    promoting_selling_related_products: number = 0.0;

    ipt_charges: number = 0.0;
    ipt_amount: number = 0.0;
    administrative_charges: number = 0.0;

    alpaca_moroality_premium: number = 0.0;
    public_liability_premium: number = 0.0;
    total_premium_without_fee: number = 0.0;
    claim_loading_percentage: number = 0.0;
    claim_loading_amount: number = 0.0;

    minimum_mortality_premium: number = 0.0;

    is_mta: boolean = false;
    is_cancel: boolean = false;
    is_lapse: boolean = false;
    is_NTU: boolean = false;
    mta_date;
    public calculate_data(mta_quote_data) {
        // this.age_7_90_sum_insured = 1500 and rate will be 5%
        if (Number(this.age_7_90_total_count) > 0) {
            this.age_7_90_premium = this.calculate_percentage_amount(Number(this.age_7_90_sum_insured), Number(this.age_7_90_rate));
        }
        else {
            this.age_7_90_premium = 0;
        }

        if (Number(this.age_above_90_total_count) > 0) {
            this.age_above_90_premium = this.calculate_percentage_amount(Number(this.age_above_90_sum_insured), Number(this.age_above_90_rate));

        }
        else {
            this.age_above_90_premium = 0;
        }

        if (this.age_13_total_count > 0) {
            this.age_13_premium = this.calculate_percentage_amount(Number(this.age_13_sum_insured), Number(this.age_13_rate));
        }
        else {
            this.age_13_premium = 0;
        }
        if (this.age_14_total_count > 0) {
            this.age_14_premium = this.calculate_percentage_amount(Number(this.age_14_sum_insured), Number(this.age_14_rate));
        }
        else {
            this.age_14_premium = 0;
        }
        let total_animal_count: number = 0;
        total_animal_count = Number(this.age_7_90_total_count) + Number(this.age_above_90_total_count) + Number(this.age_13_total_count) + Number(this.age_14_total_count);
        this.male_economic_slaughter_premium = this.calculate_percentage_amount(Number(this.male_economic_slaughter_sum_insured), Number(this.male_economic_slaughter_rate));
        this.life_saving_surgical_premium = (Number(total_animal_count)) * Number(this.life_saving_surgical_fees);
        this.veterinary_premium = (Number(total_animal_count)) * Number(this.veterinary_fees);

        this.basic_liabilty_premium = this.calculate_liabilty_amount(this.acrage, this.basic_liabilty_minimum_premium, this.basic_liabilty_excess_acrage);
        this.alpaca_experience_session_premium = this.calculate_liabilty_amount(this.acrage, this.alpaca_experience_minimum_premium, this.alpaca_excess_acrage);
        if (!Number.isNaN(this.visit_insured_premises)) {
            this.visit_insured_premises = Number(this.visit_insured_premises);
        }
        else {
            this.visit_insured_premises = 0;
        }


        if (!Number.isNaN(this.visit_to_extrenal_venues)) {
            this.visit_to_extrenal_venues = Number(this.visit_to_extrenal_venues);
        }
        else {
            this.visit_to_extrenal_venues = 0;
        }

        if (!Number.isNaN(this.trade_stand)) {
            this.trade_stand = Number(this.trade_stand);
        }
        else {
            this.trade_stand = 0;
        }
        if (!Number.isNaN(this.employer_liability)) {
            this.employer_liability = Number(this.employer_liability);
        }
        else {
            this.employer_liability = 0;
        }
        if (!Number.isNaN(this.promoting_selling_related_products)) {
            this.promoting_selling_related_products = Number(this.promoting_selling_related_products);

        }
        else {
            this.promoting_selling_related_products = 0;
        }
        this.trailer_premium = this.calculate_percentage_amount(Number(this.trailer_sum_insured), Number(this.trailer_rate));

        if (this.trailer_premium < Number(this.trailer_minimum_premium)) {
            this.trailer_premium = this.convert_to_2_decimal(Number(this.trailer_minimum_premium));
        }
        this.alpaca_moroality_premium = this.convert_to_2_decimal(Number(this.age_7_90_premium) + Number(this.age_above_90_premium) +
            Number(this.age_13_premium) + Number(this.age_14_premium) + Number(this.male_economic_slaughter_premium) +
            Number(this.life_saving_surgical_premium) + Number(this.veterinary_premium));

        if (total_animal_count > 0) {
            if (this.alpaca_moroality_premium < Number(this.minimum_mortality_premium)) {
                this.alpaca_moroality_premium = Number(this.minimum_mortality_premium);
            }
        }
        else {
            this.alpaca_moroality_premium = 0;
        }

        if (Number(this.claim_loading_percentage) > 0) {
            this.claim_loading_amount = this.convert_to_2_decimal(Number(this.alpaca_moroality_premium) * Number(this.claim_loading_percentage) / 100);
            this.alpaca_moroality_premium = this.convert_to_2_decimal(Number(this.alpaca_moroality_premium) + Number(this.claim_loading_amount));
        }
        else {
            this.claim_loading_amount = 0;
        }

        this.public_liability_premium = this.convert_to_2_decimal(Number(this.basic_liabilty_premium) + Number(this.alpaca_experience_session_premium) + Number(this.visit_insured_premises) +
            Number(this.visit_to_extrenal_venues) + Number(this.trade_stand) + Number(this.promoting_selling_related_products) + Number(this.employer_liability));


        this.total_premium = this.convert_to_2_decimal(Number(this.alpaca_moroality_premium) + Number(this.public_liability_premium) + Number(this.trailer_premium));


        var Difference_In_Days_cancle;
        var Difference_In_Days_old;
        if (this.is_mta) {
            if (parseFloat(Number(mta_quote_data.mta_sum_policy_premium).toFixed(2)) != parseFloat(Number(this.total_premium).toFixed(2))) {


                this.total_premium = parseFloat(Number(Number(this.total_premium) - Number(mta_quote_data.mta_sum_policy_premium)).toFixed(2));

                this.alpaca_moroality_premium = parseFloat(Number(Number(this.alpaca_moroality_premium) - Number(mta_quote_data.sum_alpaca_total_premium)).toFixed(2));

                this.public_liability_premium = parseFloat(Number(Number(this.public_liability_premium) - Number(mta_quote_data.sum_liability_total_premium)).toFixed(2));

                this.trailer_premium = parseFloat(Number(Number(this.trailer_premium) - Number(mta_quote_data.sum_trailer_premium)).toFixed(2));

                let newDate = new Date(this.mta_date);
                let policy_end_date = new Date(mta_quote_data.latest_policy_end_date)
                var Difference_In_Time = (policy_end_date.getTime() - newDate.getTime()) + 86400000;
                var Difference_In_Days_MTA = Difference_In_Time / (1000 * 3600 * 24);

                let oldDate = new Date(mta_quote_data.latest_policy_start_date);
                // let policy_end_date = new Date(mta_quote_data.policy_end_date)
                var Difference_In_Time_old = (policy_end_date.getTime() - oldDate.getTime()) + 86400000;
                Difference_In_Days_old = Difference_In_Time_old / (1000 * 3600 * 24);

                this.total_premium = parseFloat(Number(this.total_premium / Number(Difference_In_Days_old) * Number(Difference_In_Days_MTA)).toFixed(2));

                this.alpaca_moroality_premium = parseFloat(Number(this.alpaca_moroality_premium / Number(Difference_In_Days_old) * Number(Difference_In_Days_MTA)).toFixed(2));

                this.public_liability_premium = parseFloat(Number(this.public_liability_premium / Number(Difference_In_Days_old) * Number(Difference_In_Days_MTA)).toFixed(2));
                this.trailer_premium = parseFloat(Number(this.trailer_premium / Number(Difference_In_Days_old) * Number(Difference_In_Days_MTA)).toFixed(2));

            }
            else {
                this.total_premium = 0
                this.alpaca_moroality_premium = 0;
                this.public_liability_premium = 0;
                this.trailer_premium = 0;
            }
        }

        if (this.is_cancel) {
            // this.full_total_gross_primum =parseFloat(Number(Number(this.full_total_gross_primum)-Number(mta_quote_data.quote_versions[0].financial_details.policy_premium)).toFixed(2));
            let newDate = new Date(this.mta_date);
            let policy_end_date = new Date(mta_quote_data.latest_policy_end_date)
            var Difference_In_Time = (policy_end_date.getTime() - newDate.getTime()) + 86400000;
            Difference_In_Days_cancle = Difference_In_Time / (1000 * 3600 * 24);

            let policy_ntu_date = new Date()


            let oldDate = new Date(mta_quote_data.latest_policy_start_date);
            // let policy_end_date = new Date(mta_quote_data.policy_end_date)
            var Difference_In_ntu_Time = policy_ntu_date.getTime() - oldDate.getTime();
            Difference_In_Days_cancle = Difference_In_Time / (1000 * 3600 * 24);
            var Difference_In_Time_old = policy_end_date.getTime() - oldDate.getTime() + 86400000;
            Difference_In_Days_old = Difference_In_Time_old / (1000 * 3600 * 24);
            Difference_In_Days_old =  Math.floor(Difference_In_Days_old);
            Difference_In_Days_cancle =  Math.floor(Difference_In_Days_cancle);
            if (newDate >= oldDate) {
                if (parseFloat(Number(mta_quote_data.mta_sum_policy_premium).toFixed(2)) == Number(this.total_premium)) {
                    this.total_premium = - parseFloat(Number(this.total_premium / Number(Difference_In_Days_old) * Number(Difference_In_Days_cancle)).toFixed(2));
                    this.alpaca_moroality_premium = - parseFloat(Number(this.alpaca_moroality_premium / Number(Difference_In_Days_old) * Number(Difference_In_Days_cancle)).toFixed(2));
                    this.public_liability_premium = - parseFloat(Number(this.public_liability_premium / Number(Difference_In_Days_old) * Number(Difference_In_Days_cancle)).toFixed(2));
                    this.trailer_premium = - parseFloat(Number(this.trailer_premium / Number(Difference_In_Days_old) * Number(Difference_In_Days_cancle)).toFixed(2));
                }
                else {
                    this.total_premium = - parseFloat(Number(mta_quote_data.mta_sum_policy_premium / Number(Difference_In_Days_old) * Number(Difference_In_Days_cancle)).toFixed(2));
                    this.alpaca_moroality_premium = - parseFloat(Number(mta_quote_data.sum_alpaca_moroality_premium / Number(Difference_In_Days_old) * Number(Difference_In_Days_cancle)).toFixed(2));
                    this.public_liability_premium = - parseFloat(Number(mta_quote_data.sum_public_liability_premium / Number(Difference_In_Days_old) * Number(Difference_In_Days_cancle)).toFixed(2));
                    this.trailer_premium = - parseFloat(Number(mta_quote_data.sum_trailer_premium / Number(Difference_In_Days_old) * Number(Difference_In_Days_cancle)).toFixed(2));

                }
            }
        }

        if (this.is_mta) {
            this.administrative_charges = 0;
        }
        if (this.is_cancel && !this.is_NTU) {
            this.administrative_charges = 0;
        }
        this.ipt_amount = this.convert_to_2_decimal(Number(this.total_premium) * Number(this.ipt_charges) / 100);
        this.total_premium_without_fee = this.convert_to_2_decimal(Number(this.total_premium) + Number(this.ipt_amount));
        this.total_gross_premium = this.convert_to_2_decimal(Number(this.total_premium) + Number(this.ipt_amount) + Number(this.administrative_charges));

        if (this.is_lapse || this.is_NTU) {
            this.total_premium = - Number(this.total_premium);
            this.public_liability_premium = -  Number(this.public_liability_premium)
            this.trailer_premium = - Number(this.trailer_premium);
            this.alpaca_moroality_premium = -Number(this.alpaca_moroality_premium);
            this.administrative_charges = - Number(this.administrative_charges);
            this.ipt_amount = -Number(this.ipt_amount);
            this.total_premium_without_fee = -Number(this.total_premium_without_fee);
            this.total_gross_premium = -Number(this.total_gross_premium);
            //  this.full_underwriting_fee = - Number(this.full_underwriting_fee);
        }


    }

    private calculate_percentage_amount(value: number, percentage: number): number {

        value = (Number(value) * Number(percentage)) / 100;
        return parseFloat(Number(value).toFixed(2));
    }

    private calculate_liabilty_amount(acrage: number, minimum_premuium: number, excess_acrage: number): number {
        var return_value: number = 0.0;
        return_value = Number(acrage) * Number(excess_acrage);
        if (return_value < Number(minimum_premuium)) {
            return parseFloat(Number(minimum_premuium).toFixed(2));
        }
        return parseFloat(Number(return_value).toFixed(2));
    }

    private convert_to_2_decimal(value: number): number {
        return parseFloat(Number(value).toFixed(2));
    }

}


import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { GridOptions } from "ag-grid-community";
import { QuoteService } from '../quote/quote.service';
import { formatDate } from '@angular/common';
import { QuoteVersions } from '../model/quote/quote_versions.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'ngx-webstorage';
import { PolicyService } from '../policy/policy.service';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-quote-documents',
  templateUrl: './quote-documents.component.html',
  styleUrls: ['./quote-documents.component.css']
})
export class QuoteDocumentsComponent implements OnInit {

  constructor(private quoteService: QuoteService, private policyService: PolicyService, private sharedService: SharedService,
    private _snackBar: MatSnackBar, private modalService: NgbModal, private sessionSt: LocalStorageService) { }

  @Input() quote: QuoteVersions;
  isPolicy: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (this.sessionSt.retrieve('quote_ref_id')) {
      this.isPolicy = true;
    }
    this.quote.documents.forEach((res) => {
      res.is_policy = this.quote.is_policy;
    });
    this.rowData = this.quote.documents;
  }

  isAdmin: boolean = false;
  ngOnInit(): void {
    if (this.sessionSt.retrieve('company_role') != 'Broker') {
      this.isAdmin = true;
    }
  }

  /*
  * Get Quotes List
  * Call this api after quote save to update grid
  */
  getDocuments() {
    let type = (this.quote.is_policy == 1)? 2: 1;
    this.quoteService.getQuoteDocuments(this.quote.id, type)
      .subscribe(
        (response: any) => {
          if (response.success) {
            response.result.forEach((res) => {
              res.is_policy = this.quote.is_policy;
            });
            this.rowData = response.result;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // List Quote Versions
  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
    }
  };
  columnDefs = [
    { headerName: 'Document Name', field: 'title', sortable: true, width: 500, filter: false },
    { headerName: 'Document Version', field: 'quote_version', sortable: true, filter: false },
    {
      headerName: 'Creation Date', field: 'created_date', sortable: true, filter: false,
      comparator: this.sharedService.agGridDteSortingComparator,
      cellRenderer: function (params) {
        return formatDate(params.data.created_date, 'dd-MM-yyyy HH:mm a', 'en-US');
      }
    },
    {
      headerName: null,
      width: 130, filter: false, type: 'rightAligned',
      cellRenderer: function (params) {
        let links = '';
        if (params.data.is_static == 0 && params.data.is_policy == 0) {
          links += '<a  title="Edit Documents" style="font-size: 18px;"><i class="mdi mdi-grease-pencil" data-action-type="edit"></i></a>';
        }
        // links += '<a class="ml-2" target="_blank" href="' + environment.imageURL + params.data.download_path + '" title="Download Document" style="font-size: 18px;color: #138a51;"><i class="mdi mdi-cloud-download"  download_path="' + params.data.download_path + '"></i></a>';
        links += '<a class="ml-2" title="Download Document" style="font-size: 18px;color: #138a51;"><i class="mdi mdi-cloud-download"  data-action-type="download"></i></a>';
        if (params.data.is_static == 0 && params.data.is_policy == 0) {
          links += '<a class="ml-2" title="Delete Documents" style="font-size: 18px;"><i class="mdi mdi-close-circle-outline text-danger" data-action-type="delete"></i></a>';
        }
        return links;
      }
    }
  ];
  rowData: any;

  /*
   * GRID BUTTONS ACTION
   */
  documentsEditMode: boolean = false;
  documentID: string = '';
  onGridRowClicked(e: any, content, documentsDelete) {
    if (e) {
      let download_path = e.event.target.getAttribute("download_path");
      let actionType = e.event.target.getAttribute("data-action-type");
      this.documentID = e.data.id;
      this.docTitle = e.data.title;
      switch (actionType) {
        case "download":
          {
            this.quoteService.downloadDocs(e.data);
            break;
          }
        case "edit": {
          this.documentsEditMode = true;
          this.modalService.open(content, { centered: true });
          break
        }
        case "delete": {
          this.modalService.open(documentsDelete, { centered: true });
          break;
        }
      }
    }
  }

  /* 
   * Upload Documents
   */
  docTitle: string = '';
  fileToUpload: File = null;
  openDocumentsPopup(content) {
    this.documentID = '';
    this.docTitle = '';
    this.fileToUpload = null;
    this.quote.quote_document = '';
    this.documentsEditMode = false;
    this.modalService.open(content, { centered: true });
  }

  // UPLOAd DOCUMENT
  getUploadedFile(files) {
    this.fileToUpload = files.item(0);
    this.quote.quote_document = this.fileToUpload.name;
  }


  documentUpload() {
    let formData = new FormData();
    formData.append("file_name", this.fileToUpload);
    formData.append("quote_id", this.quote.id);
    formData.append("broker_id", this.quote.broker_id);
    formData.append("title", this.docTitle);

    this.quoteService.saveQuoteDocument(formData, this.quote.is_policy)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.getDocuments();
            this.modalService.dismissAll();
            this._snackBar.open('Document uploaded.', '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open('Unable to upload document.', '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  /*
   * UPDATE DOCUMENT
   */
  updateDocument() {
    let data: any = '';
    data = {
      document_id: this.documentID,
      title: this.docTitle
    };
    this.quoteService.updateDocument(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            this.rowData.forEach((res) => {
              if (this.documentID == res.id && res.is_static == 0) {
                res.title = this.docTitle
              }
            });
            this.gridOptions.api.setRowData(this.rowData);
            this.gridOptions.api.refreshCells();
            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open('Unable to update title. Please try again later.', '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  /*
    * Delete Document
    */
  deleteDocument() {
    let data: any = '';
    data = {
      document_id: this.documentID
    };
    this.quoteService.deleteDocument(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.modalService.dismissAll();
            let rowsDoc = [];
            this.rowData.forEach((res) => {
              if (this.documentID != res.id) {
                rowsDoc.push(res);
              }
            });
            const selectedRow = this.gridOptions.api.getFocusedCell()
            const id = this.gridOptions.rowData[selectedRow.rowIndex].i

            this.gridOptions.rowData.splice(selectedRow.rowIndex, 1)
            this.gridOptions.api.setRowData(this.gridOptions.rowData)

            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

  /*
  * Regenerate Schedule
  */
  schMsg: string = '';
  regenerate_schedule(reSchedule) {
    this.policyService.regenerateSchedule(this.quote.id, this.quote.is_policy)
      .subscribe(
        (response: any) => {
          this.getDocuments();
          this.schMsg = response.message;
          this.modalService.open(reSchedule, { centered: true });
        });
  }

  vetCertMsg;
  certMsgStyle;
  is_vet_cert_uploaded(event, vetCert) {
    if (event.target.checked) {
      this.certMsgStyle = true;
      this.quote.vet_certificate_submitted = 1;
      this.vetCertMsg = "Are you sure you have uploaded the vet certificate?"
    } else {
      this.certMsgStyle = false;
      this.quote.vet_certificate_submitted = 0;
      this.vetCertMsg = "Are you sure you did not upload the vet certificate yet?"
    }
    this.modalService.open(vetCert, { centered: true });
  }

  submit_vet_certificate() {
    let data: any = '';
    data = {
      quote_id: this.quote.id,
      vet_certificate_submitted: this.quote.vet_certificate_submitted
    };
    this.quoteService.submitVetCertificate(data)
      .subscribe(
        (response: any) => {
          this.modalService.dismissAll();
          if (response.success) {
            this.quote.vet_cert_upload_date = formatDate(new Date(), 'dd-MM-yyyy HH:mm a', 'en-US');
            this._snackBar.open(response.message, '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });
  }

}

import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { QuoteVersions } from '../../model/quote/quote_versions.model';
import { QuoteEndorsement } from '../../../../model/quote/quote_endorsement.model';
import { AlQuoteService } from '../../al-quote/al-quote.service';
import { QuoteService } from '../../../quote.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GridOptions } from "ag-grid-community";
import { NgForm } from '@angular/forms';
import { LocalStorageService } from 'ngx-webstorage';
import { UserInfoService } from '../../../../services/user-info.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-admin-al-quote-full-details',
  templateUrl: './quote-full-details.component.html',
  styleUrls: ['../../../quote.component.css'],
})
export class AlQuoteFullDetailsComponent implements OnInit {
  @Input() quoteData: QuoteVersions;
  endorsement = new QuoteEndorsement();
  editType = 'fullRow';

  constructor(private quoteService: AlQuoteService, private adminQuoteService: QuoteService, private userService: UserInfoService,
    private modalService: NgbModal, private _snackBar: MatSnackBar, private sessionSt: LocalStorageService) {

  }

  quote;
  ngOnChanges(changes: SimpleChanges) {
    this.quote = this.quoteData;

    if (this.quote.company_id) {
      this.sessionSt.store('al_broker_company_id', this.quote.company_id);
    }
    // GET STATIC DATA
    this.quoteService.getStaticData()
      .subscribe(
        (response: any) => {
          if (response.success) {
            if (response.result.alpaca_liability) {
              response.result.alpaca_liability.forEach((res) => {
                res.is_checked = false;
              })
            }
            this.alpacaLiabiities = response.result.alpaca_liability;
            this.setLiabilityLimitValues();

            //Trailer Security
            this.traierSecurity = response.result.trailer_security;
          }
        },
        (error) => {
          console.log(error);
        }
      );


    // GET ENDORSEMENT
    this.rowData = this.quoteData.endorsements;

    this.setLiabilityLimitValues();


  }

  alpacaLiabiities;
  traierSecurity;
  isAdmin: boolean = false;
  ngOnInit(): void {

    if (this.sessionSt.retrieve('company_role') != 'Broker') {
      this.isAdmin = true;
    }
  }

  /*
  * Set Liability Limit Financials
  */
  setLiabilityLimitValues() {
    if (this.quote.alpaca_liability && this.alpacaLiabiities) {
      switch (this.quote.alpaca_liability.public_liability_cover) {
        case 1:
          this.alpacaLiabiities = this.alpacaLiabiities.filter(x => x.indeminity_limit == '1 Million');
          break;
        case 2:
          this.alpacaLiabiities = this.alpacaLiabiities.filter(x => x.indeminity_limit == '2 Million');
          break;
        case 5:
          this.alpacaLiabiities = this.alpacaLiabiities.filter(x => x.indeminity_limit == '5 Million');
          break;
        default:
          this.alpacaLiabiities = this.alpacaLiabiities.filter(x => x.indeminity_limit == '1 Million');
          break;
      }

      this.alpacaLiabiities.forEach((res) => {
        let id = res.id;
        if ((id == '52f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '62f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '72f635f8-c5c6-41ad-a75a-463f82b73a0e') && this.quote.smallholding_activities.is_alpaca_experience_sessions == 1) {
          res.is_checked = true;
        }
        if ((id == '53f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '63f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '73f635f8-c5c6-41ad-a75a-463f82b73a0e') && this.quote.smallholding_activities.is_group_visits_insured_premises == 1) {
          res.is_checked = true;
        }
        if ((id == '54f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '64f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '74f635f8-c5c6-41ad-a75a-463f82b73a0e') && this.quote.smallholding_activities.is_visits_external_venues == 1) {
          res.is_checked = true;
        }
        if ((id == '56f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '66f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '76f635f8-c5c6-41ad-a75a-463f82b73a0e') && this.quote.smallholding_activities.is_promoting_selling_products == 1) {
          res.is_checked = true;
        }
        if ((id == '55f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '65f635f8-c5c6-41ad-a75a-463f82b73a0e' || id == '75f635f8-c5c6-41ad-a75a-463f82b73a0e') && this.quote.smallholding_activities.is_attending_events_trade_stand == 1) {
          res.is_checked = true;
        }

      });
    }
  }

  // SAVE ENDORSEMENT
  addEndorsement(content) {
    this.modalService.open(content, { centered: true });
    this.endorsement = new QuoteEndorsement();
  }
  onEndorsementSubmit(f: NgForm) {
    let data = {
      id: this.endorsement.id,
      quote_id: this.quote.id,
      title: this.endorsement.title,
      endorsement_text: this.endorsement.endorsement_text,
      type: this.endorsement.type
    };
    this.adminQuoteService.saveEndorsement(data)
      .subscribe(
        (response: any) => {
          if(response.success) {
            this.rowData = response.result.endorsement_list;
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
          // if (this.endorsement.id == '') {
          //   this.gridOptions.rowData.push({
          //     'title': this.endorsement.title,
          //     'endorsement_text': this.endorsement.endorsement_text,
          //     'id': response.result.endorsement_id,
          //     'type': this.endorsement.type,
          //     'created_at':new Date()
          //   });
          //   this.gridOptions.api.setRowData(this.gridOptions.rowData);
          // } else {
          //   this.gridOptions.api.refreshCells();
          // }
          this.modalService.dismissAll();
        });
  }

  // GET ENDORSEMENT
  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
      var cellDefs = this.gridOptions.api.getEditingCells();
    }


  };
  columnDefs = [
    { headerName: 'Title', field: 'title', sortable: true, width: 200, editable: false },
    {
      headerName: 'Endorsement', field: 'endorsement_text', sortable: true, editable: false, width: 500,
      cellRenderer: function (param) {
        return '<a data-action-type="view">' + param.data.endorsement_text.replace('\r\n', '<br>') + '</a>';
      },
      tooltip: function (param) {
        return param.data.endorsement_text;
      },
    },
    {
      headerName: 'Created At', field: 'created_at', sortable: false, width: 200,
      cellRenderer: function (params) {
        return formatDate(params.data.created_at, 'dd-MM-yyyy hh:mm a', 'en-US');
      }
    },
    {
      headerName: null,
      width: 120,
      cellRenderer: function (params) {
        if (!params.data.isBroker) {
          let links = '<div class="text-right">';
          links += '<a title="Edit Endorsement" style="font-size: 18px;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
          links += '<a title="Delete" style="font-size: 18px; margin-left: 20px;"><i class="mdi mdi-close-circle-outline text-danger" data-action-type="delete" id="' + params.data.id + '"></i></a>';
          links += '</div>';
          return links;
        } else {
          return '';
        }
      }
    }
  ];
  rowData: any;

  selectedRow;
  defaultColDef = {
    flex: 1,
    wrapText: true,
    autoHeight: true,
    sortable: true,
    resizable: true,
  };
  onColumnResized(params) {
    params.api.resetRowHeights();
  }
  endorsementText;
  onGridRowClicked(e: any, content, deleteModel, endorsementViewModel) {
    if (e) {

      let id = e.event.target.getAttribute("id");
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "edit":
          {

            const modalRef = this.modalService.open(content, { centered: true });
            e.data.type = Number(e.data.type);
            this.endorsement = e.data;
            break;
          }
        case "view":
          {
            this.modalService.open(endorsementViewModel, { centered: true });
            this.endorsementText = e.data.endorsement_text.replace('\r\n', '<br>');
            break;
          }
        case "delete": {
          this.modalService.open(deleteModel, { centered: true });
          this.selectedRow = this.gridOptions.api.getFocusedCell();
          this.endorsement = e.data;
        }
      }
    }
  }

  deleteEndorsement(id, selectedRow) {
    this.adminQuoteService.deleteEndorsement(id)
      .subscribe(
        (response: any) => {
          this.modalService.dismissAll();
          this.gridOptions.rowData.splice(selectedRow.rowIndex, 1);
          this.gridOptions.api.setRowData(this.gridOptions.rowData);
        });
  }

}

import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { QuoteVersions } from '../../model/quote/quote_versions.model';
import { formatDate } from '@angular/common';
import { CalculationsDetails } from '../../quote/calculations/calculations.model';
import { NgForm } from '@angular/forms';
import { QuoteService } from '../../quote/quote.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserInfoService } from '../../services/user-info.service';

@Component({
  selector: 'app-policy-financials',
  templateUrl: './policy-financials.component.html',
  styles: [
  ] 
})
export class PolicyFinancialsComponent implements OnInit {
  @Input() quote: QuoteVersions;
  calculations = new CalculationsDetails();
  co_pay_discount_details;
  vetFeesCover = [];
  vetFeesCoverBloodStock = [];
  vetFeeCoverSpecial = [];
  vet_minimum_premium = 0;
  @Input()
  set staticData(value: any) {
    if (value) {
      // Company Co Pay Discount
      if (value.company_co_pay_discount.length > 0) {
        this.co_pay_discount_details = value.company_co_pay_discount[0];

      }
      // Vet Fee Cover
      value.policy_cover_details.forEach((res, index) => {
        if (res.is_vet_fee_cat == 1) {
          res.section_indeminity_limit.forEach((res1) => {
            if (res1.is_special_vet == 0) {
              if (res1.is_blood_stock == 0) {
                if (res1.id == this.quote.vet_fee_sum_insured_id) {
                  this.quote.full_vet_fee_cover = res1.description;
                  res1.is_checked = true;                }
                this.vetFeesCover.push(res1);
              } else {
                this.vetFeesCoverBloodStock.push(res1);
              }
            } else {
              this.vetFeeCoverSpecial.push(res1);
            }
          });
        }
      });
    }
  }

  quote_end_date;
  constructor(private quoteService: QuoteService, private _snackBar: MatSnackBar, private userService: UserInfoService) { }

  quote_type: string = '';
  max_broker_comission: number = 0;
  max_ppa_comission: number = 0;
  max_prt_comission: number = 0;
  max_uw_fee: number = 0;
  ngOnChanges(changes: SimpleChanges) {
    this.quote_end_date = this.getQuoteEndDate();
    switch (this.quote.type) {
      case 1:
        this.quote_type = 'New';
        break;
      case 2:
        this.quote_type = 'Renew';
        break;
      case 3:
        this.quote_type = 'MTA';
        break;
      case 4:
        this.quote_type = 'Cancel';
        break;
      case 5:
        this.quote_type = 'Lapse';
        break;
      default:
        this.quote_type = '';
        break;
    }

    this.max_broker_comission = this.quote.financial_details.broker_commission;
    this.max_ppa_comission = this.quote.financial_details.peliwica_pay_away_commission;
    this.max_prt_comission = this.quote.financial_details.peliwica_retained_commission;
    this.max_uw_fee = this.quote.financial_details.uw_fee;
    //this.quote.financial_details.total_premium_loading = Number(this.quote.financial_details.policy_premium) + Number(this.quote.financial_details.premium_loading);
    if (this.quote && this.quote.financial_details) {


      this.quoteCalculations();
    }

    // Calculate Gross Premium
    this.calculateGrossPremium();

  }

  ngOnInit(): void {
    
  }

  /*
  * Calculate Gross Premium
  */
 GrossPremium: number = 0.0;
 calculateGrossPremium() {
   let total_commissions = Number(this.quote.financial_details.broker_commission) + Number(this.quote.financial_details.peliwica_pay_away_commission) + Number(this.quote.financial_details.peliwica_retained_commission);
   this.GrossPremium = Number(this.quote.financial_details.policy_premium) * (100 - total_commissions)/100;
 }

  // Get Quote End Date
  getQuoteEndDate() {
    if (this.quote.policy_start_date != '') {
      let quote_end_date = new Date(this.quote.policy_start_date);
      quote_end_date.setUTCFullYear(quote_end_date.getUTCFullYear() + 1);
      return formatDate(quote_end_date, 'dd-MM-yyyy', 'en-US');
    }
  }

  /*
 * Commission Slider Number Formatter
 */
  formatSliderLabel(value: number) {
    return value + '%';
  }
  formatUWSliderLabel(value: number) {
    return '£' + value;
  }

  /*
   * On Broker Comission Slider Change
   */
  onBrokerComissionChange(event) {
    this.quote.financial_details.broker_commission = event.value;
    this.calculations.broker_commission = event.value;
    this.quoteCalculations();
  }
  /*
   * On Peliwica Pay Away Comission Slider Change
   */
  onPPayAwayComissionChange(event) {
    this.quote.financial_details.peliwica_pay_away_commission = event.value;
    this.calculations.peliwica_pay_away_commission = event.value;
    this.quoteCalculations();
  }
  /*
   * On Peliwica Retained Comission Slider Change
   */
  onPRetainedComissionChange(event) {
    this.quote.financial_details.peliwica_retained_commission = event.value;
    this.calculations.peliwica_retained_commission = event.value;
    this.quoteCalculations();
  }
  /*
   * On UW Fee Slider Change
   */
  onUWFeeChange(event) {
    this.quote.financial_details.uw_fee = event.value;
    this.quoteCalculations();
  }
  /*
   * On Premium Loading Slider Change
   */
  onPreLoadingChange(event) {
    this.quote.financial_details.premium_loading_percentage = event.value;
    this.quoteCalculations();
  }

  /*
  * Calculations
  */
  quoteCalculations() {
    this.assignCalculationValues();

    this.calculations.full_calculate_data(this.quote);

  }

  assignCalculationValues() {
    if(this.quote.horse_details) {
    this.calculations.sum_insured = parseFloat(Number(this.quote.horse_details.value).toFixed(2));
    }
    if (this.quote.is_aeio == 0) {
      this.calculations.full_class_net_rate = parseFloat(Number(this.quote.financial_details.class_net_rate).toFixed(2));
      this.calculations.full_main_discount = parseFloat(Number(this.quote.financial_details.main_discount).toFixed(2));
      this.calculations.full_vet_fee_excess_discount = parseFloat(Number(this.quote.financial_details.vet_excess_discount).toFixed(2));
      this.calculations.full_public_liability_amount = parseFloat(Number(this.quote.financial_details.public_liability_amount).toFixed(2));
      this.calculations.full_personal_accident_amount = parseFloat(Number(this.quote.financial_details.personal_accident_amount).toFixed(2));
      this.calculations.peliwica_pay_away_commission = parseFloat(Number(this.quote.financial_details.peliwica_pay_away_commission).toFixed(2));
      this.calculations.broker_commission = parseFloat(Number(this.quote.financial_details.broker_commission).toFixed(2));
      this.calculations.peliwica_retained_commission = parseFloat(Number(this.quote.financial_details.peliwica_retained_commission).toFixed(2));
      this.calculations.full_class_net_rate_amount = parseFloat(Number(this.quote.financial_details.net_rate_amount).toFixed(2));
      this.calculations.class_minimum_premium = parseFloat(Number(this.quote.financial_details.class_minimum_premium).toFixed(2));
      this.calculations.class_age_loading_amount = parseFloat(Number(this.quote.financial_details.class_section_loading).toFixed(2));
      this.calculations.sat_net_rate = parseFloat(Number(this.quote.financial_details.saddlery_net_rate).toFixed(2));
      this.calculations.sat_minimum_premium = parseFloat(Number(this.quote.financial_details.saddlery_minimum_premium).toFixed(2));
      this.calculations.trailer_net_rate = parseFloat(Number(this.quote.financial_details.trailer_net_rate).toFixed(2));
      this.calculations.trailer_minimum_premium = parseFloat(Number(this.quote.financial_details.trailer_minimum_premium).toFixed(2));
      this.calculations.full_ipt_gross_amount = parseFloat(Number(this.quote.financial_details.policy_premium * this.quote.financial_details.ipt_fee / 100).toFixed(2));
      this.calculations.full_total_gross_primum = parseFloat(Number(this.quote.financial_details.policy_premium).toFixed(2));
      this.calculations.full_total_premium = parseFloat(Number(this.quote.financial_details.total_premium).toFixed(2));
      this.calculations.full_total_amount_payable = parseFloat(Number(this.quote.financial_details.total_policy_cost).toFixed(2));
      this.calculations.full_underwriting_fee = parseFloat(Number(this.quote.financial_details.fees).toFixed(2));
      this.calculations.full_underwriting_fee = parseFloat(Number(this.quote.financial_details.uw_fee).toFixed(2));
      this.calculations.full_broker_amount = parseFloat(Number(this.quote.financial_details.broker_amount).toFixed(2));
      this.calculations.full_vet_gross_amount = parseFloat(Number(this.quote.financial_details.full_vet_gross_amount).toFixed(2));
      this.calculations.full_net_vet_fee_amount = parseFloat(Number(this.quote.financial_details.net_vet_fee_amount).toFixed(2));
      this.calculations.full_net_Premium_amount = parseFloat(Number(this.quote.financial_details.total_net_amount).toFixed(2));
    } else {
      this.calculations.aeio_class_net_rate = parseFloat(Number(this.quote.financial_details.class_net_rate).toFixed(2));
      this.calculations.aeio_main_discount = parseFloat(Number(this.quote.financial_details.main_discount).toFixed(2));
      this.calculations.aeio_vet_fee_excess_discount = parseFloat(Number(this.quote.financial_details.vet_excess_discount).toFixed(2));
      this.calculations.aeio_public_liability_amount = parseFloat(Number(this.quote.financial_details.public_liability_amount).toFixed(2));
      this.calculations.aeio_personal_accident_amount = parseFloat(Number(this.quote.financial_details.personal_accident_amount).toFixed(2));
      this.calculations.peliwica_pay_away_commission = parseFloat(Number(this.quote.financial_details.peliwica_pay_away_commission).toFixed(2));
      this.calculations.broker_commission = parseFloat(Number(this.quote.financial_details.broker_commission).toFixed(2));
      this.calculations.peliwica_retained_commission = parseFloat(Number(this.quote.financial_details.peliwica_retained_commission).toFixed(2));
      this.calculations.aeio_class_net_rate_amount = parseFloat(Number(this.quote.financial_details.net_rate_amount).toFixed(2));
      this.calculations.class_minimum_premium = parseFloat(Number(this.quote.financial_details.class_minimum_premium).toFixed(2));
      this.calculations.class_age_loading_amount = parseFloat(Number(this.quote.financial_details.class_section_loading).toFixed(2));
      this.calculations.sat_net_rate = parseFloat(Number(this.quote.financial_details.saddlery_net_rate).toFixed(2));
      this.calculations.sat_minimum_premium = parseFloat(Number(this.quote.financial_details.saddlery_minimum_premium).toFixed(2));
      this.calculations.trailer_net_rate = parseFloat(Number(this.quote.financial_details.trailer_net_rate).toFixed(2));
      this.calculations.trailer_minimum_premium = parseFloat(Number(this.quote.financial_details.trailer_minimum_premium).toFixed(2));
      this.calculations.aeio_ipt_gross_amount = parseFloat(Number(this.quote.financial_details.policy_premium * this.quote.financial_details.ipt_fee / 100).toFixed(2));;;
      this.calculations.aeio_total_gross_primum = parseFloat(Number(this.quote.financial_details.policy_premium).toFixed(2));
      this.calculations.aeio_total_premium = parseFloat(Number(this.quote.financial_details.total_premium).toFixed(2));
      this.calculations.aeio_total_amount_payable = parseFloat(Number(this.quote.financial_details.total_policy_cost).toFixed(2));
      this.calculations.aeio_underwriting_fee = parseFloat(Number(this.quote.financial_details.fees).toFixed(2));
      this.calculations.aeio_underwriting_fee = parseFloat(Number(this.quote.financial_details.uw_fee).toFixed(2));
      this.calculations.aeio_broker_amount = parseFloat(Number(this.quote.financial_details.broker_amount).toFixed(2));
      this.calculations.aeio_vet_gross_amount = parseFloat(Number(this.quote.financial_details.full_vet_gross_amount).toFixed(2));
      this.calculations.aeio_net_vet_fee_amount = parseFloat(Number(this.quote.financial_details.net_vet_fee_amount).toFixed(2));
      this.calculations.aeio_net_Premium_amount = parseFloat(Number(this.quote.financial_details.total_net_amount).toFixed(2));
    }
  }


  /*
  * Save Financial Details
  */
  onSubmit(f: NgForm) {
    if (this.quote.is_aeio) {
      this.quote.financial_details.class_net_rate = this.calculations.full_class_net_rate;
      this.quote.financial_details.main_discount = this.calculations.full_main_discount;
      this.quote.financial_details.vet_excess_discount = this.calculations.full_vet_fee_excess_discount;
      this.quote.financial_details.public_liability_amount = this.calculations.full_public_liability_amount;
      this.quote.financial_details.personal_accident_amount = this.calculations.full_personal_accident_amount;
      this.quote.financial_details.peliwica_pay_away_commission = this.calculations.peliwica_pay_away_commission;
      this.quote.financial_details.broker_commission = this.calculations.broker_commission;
      this.quote.financial_details.peliwica_retained_commission = this.calculations.peliwica_retained_commission;
      this.quote.financial_details.net_rate_amount = this.calculations.full_class_net_rate_amount;
      this.quote.financial_details.class_minimum_premium = this.calculations.class_minimum_premium;
      this.quote.financial_details.class_section_loading = this.calculations.class_age_loading_amount;
      this.quote.financial_details.saddlery_net_rate = this.calculations.sat_net_rate;
      this.quote.financial_details.saddlery_minimum_premium = this.calculations.sat_minimum_premium;
      this.quote.financial_details.trailer_net_rate = this.calculations.trailer_net_rate;
      this.quote.financial_details.trailer_minimum_premium = this.calculations.trailer_minimum_premium;
      this.quote.financial_details.ipt_fee = this.calculations.ipt_fee;
      this.quote.financial_details.policy_premium = this.calculations.full_total_gross_primum;
      this.quote.financial_details.total_premium = this.calculations.full_total_premium;
      this.quote.financial_details.total_policy_cost = this.calculations.full_total_amount_payable;
      this.quote.financial_details.fees = this.calculations.full_underwriting_fee;
      this.quote.financial_details.uw_fee = this.calculations.full_underwriting_fee;
      this.quote.financial_details.broker_amount = this.calculations.full_broker_amount;
      this.quote.financial_details.full_vet_gross_amount = this.calculations.full_vet_gross_amount;
      this.quote.financial_details.net_vet_fee_amount = this.calculations.full_net_vet_fee_amount;
      this.quote.financial_details.total_net_amount = this.calculations.full_net_Premium_amount;

    } else {
      this.quote.is_aeio = 1;
      this.quote.financial_details.is_aeio = 1;
      this.quote.financial_details.class_net_rate = this.calculations.aeio_class_net_rate;
      this.quote.financial_details.main_discount = this.calculations.aeio_main_discount;
      this.quote.financial_details.vet_excess_discount = this.calculations.aeio_vet_fee_excess_discount;
      this.quote.financial_details.public_liability_amount = this.calculations.aeio_public_liability_amount;
      this.quote.financial_details.personal_accident_amount = this.calculations.aeio_personal_accident_amount;
      this.quote.financial_details.peliwica_pay_away_commission = this.calculations.peliwica_pay_away_commission;
      this.quote.financial_details.broker_commission = this.calculations.broker_commission;
      this.quote.financial_details.peliwica_retained_commission = this.calculations.peliwica_retained_commission;
      this.quote.financial_details.net_rate_amount = this.calculations.aeio_class_net_rate_amount;
      this.quote.financial_details.class_minimum_premium = this.calculations.class_minimum_premium;
      this.quote.financial_details.class_section_loading = this.calculations.class_age_loading_amount;
      this.quote.financial_details.saddlery_net_rate = this.calculations.sat_net_rate;
      this.quote.financial_details.saddlery_minimum_premium = this.calculations.sat_minimum_premium;
      this.quote.financial_details.trailer_net_rate = this.calculations.trailer_net_rate;
      this.quote.financial_details.trailer_minimum_premium = this.calculations.trailer_minimum_premium;
      this.quote.financial_details.ipt_fee = this.calculations.ipt_fee;
      this.quote.financial_details.policy_premium = this.calculations.aeio_total_gross_primum;
      this.quote.financial_details.total_premium = this.calculations.aeio_total_premium;
      this.quote.financial_details.total_policy_cost = this.calculations.aeio_total_amount_payable;
      this.quote.financial_details.fees = this.calculations.aeio_underwriting_fee;
      this.quote.financial_details.uw_fee = this.calculations.aeio_underwriting_fee;
      this.quote.financial_details.broker_amount = this.calculations.aeio_broker_amount;
      this.quote.financial_details.full_vet_gross_amount = this.calculations.aeio_vet_gross_amount;
      this.quote.financial_details.net_vet_fee_amount = this.calculations.aeio_net_vet_fee_amount;
      this.quote.financial_details.total_net_amount = this.calculations.aeio_net_Premium_amount;
    }
    let data = {
      quote_data: this.quote
    };
    this.quoteService.saveIndicativeQuote(data)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.quote = response.result.quote_data;
            this._snackBar.open('Financial details saved', '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
        });
  }

}

import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { QuoteVersions } from '../../model/quote/quote_versions.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'ngx-webstorage';
import { formatDate, TitleCasePipe } from '@angular/common';
import { GridOptions } from "ag-grid-community";
import { QuoteService } from '../../quote/quote.service';
import { PolicyService } from '../policy.service';
import { ActivatedRoute, Router } from "@angular/router";
import { HorseSubClass } from '../../model/quote/horse_sub_class.model';
import { UserInfoService } from '../../services/user-info.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-policy-overview',
  templateUrl: './policy-overview.component.html',
  styleUrls: ['../../quote/quote.component.css'],
})
export class PolicyOverviewComponent implements OnInit {
  @Output() quoteDetailsUpdated = new EventEmitter<QuoteVersions>();
  @Output() hasImportantNotes = new EventEmitter();
  @Output() staticData = new EventEmitter();
  quote_end_date: string = '';
  quote;
  quote_type;
  isAdmin: boolean = false;
  @Input() set quoteData(value: any) {
    if (value) {
      this.quote = value;
    }
  }


  constructor(private sessionSt: LocalStorageService, private quoteService: QuoteService, private modalService: NgbModal, private _snackBar: MatSnackBar,
    private route: ActivatedRoute, private policyService: PolicyService, private router: Router, private titlecasePipe: TitleCasePipe) { }

  minPolicyDate;
  maxPolicyDate;
  underwriters;
  insurers;
  policyStatuses;

  selectedUnderwriterID = this.sessionSt.retrieve('userInfo').id;
  quoteTypes: any = [];
  ngOnInit(): void {
    if (this.sessionSt.retrieve('company_role') == 'Peliwica' && this.sessionSt.retrieve('user_group') == 'Administrator') {
      this.isAdmin = true;
    }
    // API - GET UNDERWRITERS
    this.quoteService.getUnderwriters()
      .subscribe(
        (response: any) => {
          this.underwriters = response.result;


        });

    // API - GET INSURERS
    this.quoteService.getInsurers()
      .subscribe(
        (response: any) => {
          this.insurers = response.result;
        });

    // Get Policy Statuses
    this.policyService.getPolicyStatuses()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.policyStatuses = response.result.policy_insurance_status;
          }
        },
        (error) => {
          console.log(error);
        }
      );

  }

  policyReason: string = '';
  ngOnChanges(changes: SimpleChanges) {
    this.policyService.getPolicyMTAReasons()
      .subscribe(
        (response: any) => {
          if (response.success) {
            if (response.result.types) {
              let reasons = response.result.types.filter(x => x.id == this.quote.mta_reason_id);
              if (reasons.length > 0) {
                this.policyReason = reasons[0].reasons;
              }
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );

    this.getQuoteTypes();
  }

  // List Quote Versions
  quoteVersionsUpdated(qudatedQuote) {
    this.quote = qudatedQuote;
    this.quoteDetailsUpdated.emit(qudatedQuote);
    this.transction_type = '';
    this.getQuoteTypes();
    
  }
  onhasImportantNotesChange(isImportant) {
    this.hasImportantNotes.emit(isImportant);    
  }

  transction_type = '';
  getQuoteTypes() {
    if (!this.transction_type) {
      // Get Quote Types
      this.quoteService.getQuoteTypes(1)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.transction_type = response.result.types[this.quote.type];
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }



  /*
  * View Quote Details
  */
  quoteDetails() {
    this.sessionSt.store('policy_meta_id', this.quote.id);
    this.sessionSt.store('quote_ref_id', this.quote.id);
    this.router.navigate(['/quote-details', this.quote.id]);
  }
}

<form name="full_details_form" #fd="ngForm" (ngSubmit)="onSubmit(fd)" novalidate>
    <div class="row">
        <div class="col-md-8">
            <div class="form_sections">
                <div class="row">
                    <div class="col-md-6">
                        <h6>Quote Details</h6>
                        <div class="form-group w-100">
                            <input type="text" name="policy_start_date" readonly
                                [(ngModel)]="quote.policy_start_date" #policy_start_date="ngModel"
                                required placeholder="Policy Start Date" class="form-control form-control-sm datepicker"
                                [ngClass]="{ 'is-invalid': fd.submitted && policy_start_date.invalid }" matInput
                                (focus)="myDatepicker.open()" [matDatepicker]="myDatepicker" [min]="minPolicyDate"
                                [max]="maxPolicyDate">
                            <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                            <mat-datepicker #myDatepicker></mat-datepicker>
                            <div *ngIf="fd.submitted && policy_start_date.invalid" class="invalid-feedback">
                                Policy date is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group" *ngIf="!isBroker">
                            <mat-form-field class="mt-4 w-100">
                                <mat-label>Broker</mat-label>
                                <mat-select [(ngModel)]="quote.company_id" class="form-control form-control-sm"
                                    name="broker_id" [ngClass]="{ 'is-invalid': fd.submitted && broker_id.invalid }"
                                    #broker_id="ngModel" required disabled>
                                    <mat-option [value]="">Select Broker</mat-option>
                                    <mat-option *ngFor="let brk of brokers" [value]="brk.id">
                                        {{brk.name}}
                                    </mat-option>
                                </mat-select>
                                <div *ngIf="fd.submitted && broker_id.invalid" class="invalid-feedback">
                                    Broker field is required.
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <!-- PERSONAL DETAILS -->
            <div class="form_sections">
                <mat-expansion-panel expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Personal Details
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="first_name" placeholder="First Name"
                                    class="form-control form-control-sm"   
                                    [(ngModel)]="quote.policy_holder.first_name"
                                    [ngClass]="{ 'is-invalid': fd.submitted && first_name.invalid }"
                                    #first_name="ngModel" required appTitleCase>
                                <div *ngIf="fd.submitted && first_name.invalid" class="invalid-feedback">
                                    Firstname is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="last_name" placeholder="Last Name"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="quote.policy_holder.last_name"
                                    [ngClass]="{ 'is-invalid': fd.submitted && last_name.invalid }" #last_name="ngModel"
                                    required appTitleCase>
                                <div *ngIf="fd.submitted && last_name.invalid" class="invalid-feedback">
                                    Lastname is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="email"
                                    [(ngModel)]="quote.policy_holder.email" #email="ngModel" required
                                    pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                    [ngClass]="{ 'is-invalid': fd.submitted && email.invalid }"
                                    class="form-control form-control-sm" placeholder="Email"
                                    oninput="this.value = this.value.toLowerCase()">
                                <div *ngIf="fd.submitted && email.errors && (email.invalid || email.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="email.invalid && !email.touched">Email is required.</div>
                                    <div *ngIf="email.errors.pattern && email.touched">Please provide a valid email
                                        address
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="phone" placeholder="Contact Number"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="quote.policy_holder.phone" #phone="ngModel" required
                                    [ngClass]="{ 'is-invalid': fd.submitted && phone.invalid }">
                                <div *ngIf="fd.submitted && phone.invalid" class="invalid-feedback">
                                    Contact number is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" required name="dob"
                                    [(ngModel)]="quote.policy_holder.dob" #dob="ngModel"
                                    placeholder="DOB" class="form-control form-control-sm datepicker" matInput
                                    (focus)="dobDatepicker.open()" [matDatepicker]="dobDatepicker"
                                    [ngClass]="{ 'is-invalid': fd.submitted && dob.invalid }" [max]="today">
                                <mat-datepicker-toggle [for]="dobDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #dobDatepicker></mat-datepicker>
                                <div *ngIf="fd.submitted && phone.invalid" class="invalid-feedback">
                                    DOB is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>
            <!-- ADDRESS DETAILS -->
            <div class="form_sections">
                <mat-expansion-panel expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Address Details
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="postcode_lookup" placeholder="Postcode Lookup"
                                    class="form-control form-control-sm"
                                    (keyup)="postcode_lookup.errors?'':postcodeLookup($event)"
                                    pattern="^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$"
                                    #postcode_lookup="ngModel"
                                    [(ngModel)]="quote.policy_holder.address.postcode"
                                    [ngClass]="{ 'is-invalid': postcode_lookup.invalid }" [matAutocomplete]="auto"
                                    appUpperCase>
                                <div *ngIf="postcode_lookup.errors?.pattern" class="invalid-feedback">
                                    Invalid postcode.
                                </div>
                                <div>
                                    <mat-autocomplete #auto="matAutocomplete">
                                        <div
                                            *ngIf="!postcode_lookup.errors && quote.policy_holder.address.postcode != ''">
                                            <mat-option [value]="quote.policy_holder.address.postcode"
                                                (click)="onSelectAddress(adrs)" *ngFor="let adrs of addresses">
                                                {{adrs.formatted_address.join(', ')}}</mat-option>
                                        </div>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" name="address_line_1" placeholder="Line 1"
                                    [(ngModel)]="quote.policy_holder.address.address1"
                                    class="form-control form-control-sm" appTitleCase
                                    [ngClass]="{ 'is-invalid': fd.submitted && address1.invalid }" required
                                    #address1="ngModel">
                                <div *ngIf="fd.submitted && address1.invalid" class="invalid-feedback">
                                    Address 1 is required.
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" name="address_line_2" placeholder="Line 2"
                                    [(ngModel)]="quote.policy_holder.address.address2"
                                    class="form-control form-control-sm" appTitleCase>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="city" placeholder="Town"
                                    [(ngModel)]="quote.policy_holder.address.city"
                                    class="form-control form-control-sm" appTitleCase
                                    [ngClass]="{ 'is-invalid': fd.submitted && city.invalid }" required #city="ngModel">
                                <div *ngIf="fd.submitted && city.invalid" class="invalid-feedback">
                                    Town is required.
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" name="county" placeholder="County"
                                    [(ngModel)]="quote.policy_holder.address.country"
                                    class="form-control form-control-sm" appTitleCase
                                    [ngClass]="{ 'is-invalid': fd.submitted && county.invalid }" required
                                    #county="ngModel">
                                <div *ngIf="fd.submitted && county.invalid" class="invalid-feedback">
                                    County is required.
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" name="postcode" placeholder="Postcode"
                                    [(ngModel)]="quote.policy_holder.address.postcode"
                                    class="form-control form-control-sm"
                                    [ngClass]="{ 'is-invalid': fd.submitted && postcode.invalid }" required
                                    #postcode="ngModel" appUpperCase>
                                <div *ngIf="fd.submitted && postcode.invalid" class="invalid-feedback">
                                    Postcode is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>

            <!-- HORSE DETAILS -->
            <div class="form_sections">
                <mat-expansion-panel expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Horse Details
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row">
                        <div class="col-md-6 mt-2">
                            <div class="form-group">
                                <label class="text-white">Date of Purchase/Loan Commencement Date ( If Home Bred
                                    please enter Date of Birth)</label>
                                <input type="text" name="purchase_date" placeholder="Horse Purchase/Loan Date"
                                    class="form-control form-control-sm datepicker" matInput
                                    (dateInput)="formatDate($event)" (focus)="purchaseDateDatepicker.open()"
                                    [matDatepicker]="purchaseDateDatepicker"
                                    [(ngModel)]="quote.horse_details.horse_purchase_date"
                                    #purchase_date="ngModel" required readonly
                                    [ngClass]="{ 'is-invalid': fd.submitted && purchase_date.invalid }">
                                <mat-datepicker-toggle [for]="purchaseDateDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #purchaseDateDatepicker></mat-datepicker>
                                <div *ngIf="fd.submitted && purchase_date.invalid" class="invalid-feedback">
                                    Purchase date is required.
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="text-white">Horse Name</label>
                                <input type="text" name="horse_name" placeholder="Horse Name" readonly
                                    class="form-control form-control-sm"
                                    [(ngModel)]="quote.horse_details.name">
                            </div>
                            <div class="form-group">
                                <label class="text-white">Horse Age</label>
                                <input type="text" name="horse_age" placeholder="Horse Age" readonly
                                    class="form-control form-control-sm"
                                    [(ngModel)]="quote.horse_details.age">
                            </div>
                            <div class="form-group">
                                <label class="text-white">Horse Value</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-success text-white">&pound;</span>
                                    </div>
                                    <input type="text" name="horse_value" placeholder="Horse Value" readonly
                                        class="form-control form-control-sm"
                                        [(ngModel)]="quote.horse_details.value">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="text-white">Horse Height</label>
                                <mat-form-field class="w-100">
                                    <mat-select [(ngModel)]="quote.horse_details.height"
                                        class="form-control form-control-sm" name="horse_height">
                                        <mat-option [value]="">Select Horse Height</mat-option>
                                        <mat-option *ngFor="let hh of horseHeight" [value]="hh.height">
                                            {{hh.height}} HH
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-group">
                                <label class="text-white">Horse Breed</label>
                                <mat-form-field class="w-100">
                                    <mat-select [(ngModel)]="quote.horse_details.breed_id"
                                        class="form-control form-control-sm" name="horse_breed"
                                        [disabled]="quote.blood_stock_id && quote.horse_details.breed_id == 'dad1ba6a-fdf1-4ca8-86fd-69a080a09887'">
                                        <mat-option [value]="">Select Horse Breed</mat-option>
                                        <mat-option *ngFor="let hb of horseBreed" [value]="hb.id">
                                            {{hb.breed}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-group">
                                <label class="text-white">Horse Colour </label>
                                <mat-form-field class="w-100">
                                    <mat-select [(ngModel)]="quote.horse_details.color_id"
                                        class="form-control form-control-sm" name="color_id">
                                        <mat-option [value]="">Select Colour </mat-option>
                                        <mat-option *ngFor="let color of horseColor" [value]="color.id">
                                            {{color.color}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-group">
                                <label class="text-white">Horse Passport Number</label>
                                <input type="text" name="passport" placeholder="Horse Passport Number"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="quote.horse_details.passport">
                            </div>
                            <div class="form-group">
                                <label class="text-white">Horse Microchip Number</label>
                                <input type="text" name="microchip" placeholder="Horse Microchip Number"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="quote.horse_details.microchip">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <h6 class="text-white">Cover Selected <span class="ml-1"
                                    matTooltip="If you wish to change the cover selcted please return to page 1"><i
                                        class="mdi mdi-comment-question-outline"></i></span></h6>
                            <div class="form-group">
                                <label class="text-white">Class of Use</label>
                                <input type="text" name="class_of_use" placeholder="Class of Use"
                                    [(ngModel)]="quote.full_class_of_use" class="form-control form-control-sm" readonly>
                            </div>
                            <div class="form-group">
                                <label class="text-white">Primary Cover</label>
                                <input type="text" name="horse_class_sub_id" placeholder="Primary Cover"
                                    [(ngModel)]="quote.primaryCover" class="form-control form-control-sm" readonly>
                            </div>
                            <div class="form-group">
                                <label class="text-white">Policy Cover</label>
                                <input type="text" name="policy_cover" placeholder="Policy Cover"
                                    [(ngModel)]="quote.full_policy_cover" class="form-control form-control-sm" readonly>
                            </div>
                            <div class="form-group" *ngIf="quote.full_vet_fee_cover">
                                <label class="text-white">Vets Fees Cover</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-success text-white">&pound;</span>
                                    </div>
                                    <input type="text" name="vet_fees_cover" placeholder="Vets Fees Cover"
                                        [value]="(quote.full_vet_fee_cover != '')?quote.full_vet_fee_cover.replace('£',''):''"
                                        class="form-control form-control-sm" readonly>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="quote.full_life_saving_surgery">
                                <label class="text-white"> Life Saving Surgery/Colic Surgery</label>
                                <input type="text" name="life_saving_surgery"
                                    placeholder="Life Saving Surgery/Colic Surgery"
                                    [value]="quote.full_life_saving_surgery" class="form-control form-control-sm"
                                    readonly>
                            </div>
                            <div class="form-group" [hidden]="quote.LifeSaving7500">
                                <label class="text-white">Vets Fees Excess</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-success text-white">&pound;</span>
                                    </div>
                                    <input type="text" name="vet_fees_excess" [value]="quote.full_vet_fee_excess"
                                        placeholder="Vets fees excess" class="form-control form-control-sm" readonly>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="text-white">Public Liability</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-success text-white">&pound;</span>
                                    </div>
                                    <input type="text" name="public_liability_cover" placeholder="Public liability"
                                        [value]="(quote.full_public_liability != '')?quote.full_public_liability.replace('£',''):''"
                                        class="form-control form-control-sm" readonly>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="text-white">Personal Accident</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-success text-white">&pound;</span>
                                    </div>
                                    <input type="text" name="personal_accident_cover" placeholder="Personal Accident"
                                        [value]="(quote.full_personal_accident != '')?quote.full_personal_accident.replace('£',''):''"
                                        class="form-control form-control-sm" readonly>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>

            <div class="form_sections">
                <div *ngIf="!quote.hide_sections_for_blood_stock">
                    <h6>Co-insurance For Lameness Diagnostics</h6>
                    <div class="form-group quote_radio disabled">
                        <mat-checkbox name="company_co_pay_excess_id"
                            [value]="quote.company_co_pay_excess_id"
                            [checked]="quote.company_co_insurance">
                            Add 20%
                            Co-insurance For Lameness Diagnostics
                        </mat-checkbox>
                    </div>
                </div>
            </div>

            <div class="form_sections">
                <h6>Horse Gender</h6>
                <mat-radio-group class="row" required name="gender" #gender ngmodel
                    [(ngModel)]="quote.horse_details.gender">
                    <div class="col-md-6">
                        <div class="quote_radio">
                            <mat-radio-button [value]="1" name="gender" ngDefaultControl
                                [(ngModel)]="quote.horse_details.gender" required
                                #genderControl="ngModel">Male Horse</mat-radio-button>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="quote_radio">
                            <mat-radio-button [value]="2" name="gender" ngDefaultControl
                                [(ngModel)]="quote.horse_details.gender" required
                                #genderControl="ngModel">Female Horse</mat-radio-button>
                        </div>
                    </div>
                    <div *ngIf="fd.submitted && genderControl.invalid" class="invalid-feedback col-md-12">
                        Please select horse gender.
                    </div>
                </mat-radio-group>



            </div>

            <!-- HORSE OWNERSHIP DETAILS -->
            <div class="form_sections">
                <mat-expansion-panel expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Horse Ownership Details
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-radio-group class="row mb-3" aria-label="Select an option" name="horse_ownership" required
                        #horseownership ngmodel 
                        (change)="onHorseOwnershipChange($event)"
                        [(ngModel)]="quote.does_policy_owner_owns_horse">
                        <div class="col-md-6">
                            <div class="quote_radio">
                                <mat-radio-button [value]="1" name="horse_ownership" ngDefaultControl
                                    [(ngModel)]="quote.does_policy_owner_owns_horse" required
                                    #horseownershipControl="ngModel">Policyholder owns the horse</mat-radio-button>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="quote_radio">
                                <mat-radio-button [value]="2" name="horse_ownership" ngDefaultControl
                                    [(ngModel)]="quote.does_policy_owner_owns_horse" required
                                    #horseownershipControl="ngModel">Someone else owns the horse</mat-radio-button>
                            </div>
                        </div>
                        <div *ngIf="fd.submitted && horseownershipControl.invalid" class="invalid-feedback col-md-12">
                            Please select horse ownership details.
                        </div>
                    </mat-radio-group>
                    <div class="quote_radio" *ngIf="quote.does_policy_owner_owns_horse == 2">
                        <div class="form-group">
                            <label>
                                <ul class="list-ticked">
                                    <li>It is your responsibility to provide us with the name, contact and address
                                        details
                                        of the owner of the horse, as well as the loan agreement and any other
                                        supporting
                                        documentation for the horse on loan if you make a claim, please tick the box to
                                        accept these terms. </li>
                                </ul>
                            </label>
                        </div>
                        <div class="form-group quote_radio"> 
                            <mat-checkbox name="accept_terms" [value]="1" (change)="acceptTerms($event)"
                                [(ngModel)]="quote.is_accepted">
                                I accept the terms
                            </mat-checkbox>
                            <div *ngIf="fd.submitted && validateAcceptTerms" class="invalid-feedback">
                                Please accept terms.
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>

            <!-- Horse Address Details -->
            <div class="form_sections">
                <mat-expansion-panel expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Is the stabling address different to the correspondance address?
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-radio-group aria-label="Select an option" name="horse_stable_address"
                        [(ngModel)]="quote.horse_details.is_stable_address_different"
                        (change)="onHorseAddressChange($event)" required #stableAddress="ngModel">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="quote_radio mb-3">
                                    <mat-radio-button [value]="1" name="horse_stable_address" ngDefaultControl
                                        [(ngModel)]="quote.horse_details.is_stable_address_different"
                                        required #stableAddressControl="ngModel">Yes</mat-radio-button>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="quote_radio mb-3">
                                    <mat-radio-button [value]="2" name="horse_stable_address" ngDefaultControl
                                        [(ngModel)]="quote.horse_details.is_stable_address_different"
                                        required #stableAddressControl="ngModel">No</mat-radio-button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="fd.submitted && stableAddressControl.invalid" class="invalid-feedback col-md-12">
                            Please choose an option.
                        </div>
                    </mat-radio-group>

                    <div class="row" *ngIf="quote.horse_details.is_stable_address_different == 1">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="stable_address_postcode_lookup" placeholder="Postcode Lookup"
                                    class="form-control form-control-sm"
                                    (keyup)="stable_address_postcode_lookup.errors?'':postcodeLookup($event)"
                                    pattern="^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$"
                                    #stable_address_postcode_lookup="ngModel"
                                    [(ngModel)]="quote.horse_details.address.postcode"
                                    [ngClass]="{ 'is-invalid': stable_address_postcode_lookup.invalid }"
                                    [matAutocomplete]="horse_postcode" appUpperCase>
                                <div *ngIf="stable_address_postcode_lookup.errors?.pattern" class="invalid-feedback">
                                    Invalid postcode.
                                </div>
                                <div>
                                    <mat-autocomplete #horse_postcode="matAutocomplete">
                                        <div
                                            *ngIf="!stable_address_postcode_lookup.errors && quote.horse_details.address.postcode != ''">
                                            <mat-option [value]="quote.horse_details.address.postcode"
                                                (click)="onSelectStableAddress(adrs)" *ngFor="let adrs of addresses">
                                                {{adrs.formatted_address.join(', ')}}</mat-option>
                                        </div>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" name="horse_address_line_1" placeholder="Line 1"
                                    [(ngModel)]="quote.horse_details.address.address1"
                                    class="form-control form-control-sm" appTitleCase
                                    [ngClass]="{ 'is-invalid': fd.submitted && horse_address_line_1.invalid }" required
                                    #horse_address_line_1="ngModel">
                                <div *ngIf="fd.submitted && horse_address_line_1.invalid" class="invalid-feedback">
                                    Address 1 is required.
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" name="horse_address_line_2" placeholder="Line 2"
                                    [(ngModel)]="quote.horse_details.address.address2"
                                    class="form-control form-control-sm" appTitleCase>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" name="horse_city" placeholder="Town"
                                    [(ngModel)]="quote.horse_details.address.city"
                                    class="form-control form-control-sm" appTitleCase
                                    [ngClass]="{ 'is-invalid': fd.submitted && horse_town.invalid }" required
                                    #horse_town="ngModel">
                                <div *ngIf="fd.submitted && horse_town.invalid" class="invalid-feedback">
                                    Town is required.
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" name="horse_county" placeholder="County"
                                    [(ngModel)]="quote.horse_details.address.country"
                                    class="form-control form-control-sm" appTitleCase
                                    [ngClass]="{ 'is-invalid': fd.submitted && horse_country.invalid }" required
                                    #horse_country="ngModel">
                                <div *ngIf="fd.submitted && horse_country.invalid" class="invalid-feedback">
                                    County is required.
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" name="horse_postcode" placeholder="Postcode"
                                    [(ngModel)]="quote.horse_details.address.postcode"
                                    class="form-control form-control-sm"
                                    [ngClass]="{ 'is-invalid': fd.submitted && stable_postcode.invalid }" required
                                    #stable_postcode="ngModel" appUpperCase>
                                <div *ngIf="fd.submitted && stable_postcode.invalid" class="invalid-feedback">
                                    Postcode is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>

            </div>

            <!-- Horse Domicile Details -->
            <div class="form_sections d-none">
                <mat-expansion-panel expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Horse Domicile Address Details
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-radio-group class="row mb-3" aria-label="Select an option" name="domicile" #domicile ngmodel
                        [(ngModel)]="quote.is_domicile_in_UK">
                        <div class="col-md-6">
                            <div class="quote_radio">
                                <mat-radio-button [value]="1" name="domicile" ngDefaultControl
                                    [(ngModel)]="quote.is_domicile_in_UK" #domicileControl="ngModel">
                                    The horse's main domiciled address is in the UK?
                                </mat-radio-button>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="quote_radio">
                                <mat-radio-button [value]="2" name="domicile" ngDefaultControl
                                    [(ngModel)]="quote.is_domicile_in_UK" #domicileControl="ngModel">
                                    The horse's main domiciled address is outside of the UK?
                                </mat-radio-button>
                            </div>
                        </div>
                        <div *ngIf="fd.submitted && domicileControl.invalid" class="invalid-feedback col-md-12">
                            Please select horse domicile details.
                        </div>
                    </mat-radio-group>
                    <div class="row" *ngIf="quote.is_domicile_in_UK == 2">
                        <div class="col-md-12">
                            <div class="form-group">
                                <textarea name="domicile_details" placeholder="Please provide further details"
                                    class="form-control"
                                    [(ngModel)]="quote.domicile_details"></textarea>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </div>


            <!-- SADDLERY & TACK DETAILS -->
            <div class="form_sections" *ngIf="quote.does_addon_saddlery_on == 1">
                <mat-expansion-panel expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Saddlery & Tack Details
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p class="text-white"><small>Unspecified Item Sum Insured:
                            &pound;{{quote.addon_saddlery_value - saddleryTotalSumInsured}}</small>
                    </p>
                    <div class="text-white">Single item of saddlery/tack worth over &pound;500</div>
                    <mat-radio-group aria-label="Select an option" name="saddlery_items"
                        [(ngModel)]="quote.is_saddlery_tack" required
                        #saddleryItems="ngModel" (change)="onSaddleryTackChange($event)">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="quote_radio mb-3">
                                    <mat-radio-button [value]="1" name="saddlery_items" ngDefaultControl
                                        [(ngModel)]="quote.is_saddlery_tack" required #saddleryItemsControl="ngModel">Yes
                                    </mat-radio-button>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="quote_radio mb-3">
                                    <mat-radio-button [value]="2" name="saddlery_items" ngDefaultControl
                                        [(ngModel)]="quote.is_saddlery_tack" required #saddleryItemsControl="ngModel">No
                                    </mat-radio-button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="fd.submitted && saddleryItemsControl.invalid" class="invalid-feedback col-md-12">
                            Please choose an option.
                        </div>
                    </mat-radio-group>


                </mat-expansion-panel>

                <!-- Items -->
                <div *ngIf="quote.is_saddlery_tack == 1">
                    <mat-expansion-panel expanded
                        *ngFor="let saddlery_tack_item of quote.horse_details.saddlery_items; let i = index;">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Item {{i + 1}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text"
                                        name="saddlery_items_brand_{{quote.horse_details.saddlery_items.length + i}}"
                                        placeholder="Make & Model" class="form-control form-control-sm" required
                                        [(ngModel)]="saddlery_tack_item.brand"
                                        [ngClass]="{ 'is-invalid': fd.submitted && brand.invalid }" #brand="ngModel">
                                    <div *ngIf="fd.submitted && brand.invalid" class="invalid-feedback">
                                        Brand is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text bg-success text-white">&pound;</span>
                                        </div>
                                        <input type="text"
                                            name="saddlery_sum_insured_{{quote.horse_details.saddlery_items.length + i}}"
                                            placeholder="Sum Insured" class="form-control form-control-sm"
                                            appDecimalNumbers [(ngModel)]="saddlery_tack_item.sum_insured" required
                                            [ngClass]="{ 'is-invalid': fd.submitted && sum_insured.invalid }"
                                            #sum_insured="ngModel">
                                    </div>
                                    <div *ngIf="fd.submitted && sum_insured.invalid" class="invalid-feedback">
                                        Sum insured is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="text"
                                        name="saddlery_color_{{quote.horse_details.saddlery_items.length + i}}"
                                        placeholder="Color" class="form-control form-control-sm"
                                        [(ngModel)]="saddlery_tack_item.color" required
                                        [ngClass]="{ 'is-invalid': fd.submitted && color.invalid }" #color="ngModel">
                                    <div *ngIf="fd.submitted && color.invalid" class="invalid-feedback">
                                        Color is required.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="text"
                                        name="saddlery_purchase_date_{{quote.horse_details.saddlery_items.length + i}}"
                                        placeholder="Purchase Date" class="form-control form-control-sm datepicker"
                                        matInput (focus)="saddleryPurchaseDateDatepicker.open()" required
                                        [matDatepicker]="saddleryPurchaseDateDatepicker" readonly
                                        [(ngModel)]="saddlery_tack_item.purchase_date"
                                        [ngClass]="{ 'is-invalid': fd.submitted && saddlery_purchase_date.invalid }"
                                        #saddlery_purchase_date="ngModel">
                                    <mat-datepicker-toggle [for]="saddleryPurchaseDateDatepicker">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #saddleryPurchaseDateDatepicker></mat-datepicker>
                                    <div *ngIf="fd.submitted && saddlery_purchase_date.invalid" class="invalid-feedback">
                                        Purchase date is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <textarea
                                        name="saddlery_description_{{quote.horse_details.saddlery_items.length + i}}"
                                        placeholder="Description" class="form-control" rows="7"
                                        [(ngModel)]="saddlery_tack_item.description"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="i>0">
                            <button type="button" class="btn btn-danger btn-sm"
                                (click)="removeSaddleryItems(i)">Remove</button>
                        </div>
                        <div class="form-group" *ngIf="i==0">
                            <button type="button" class="btn btn-success btn-sm" (click)="addSaddleryItems()">Add More
                                Items</button>
                        </div>
                    </mat-expansion-panel>
                </div>
            </div>

            <!-- HORSE TRAILER DETAILS -->
            <div class="form_sections" *ngIf="quote.does_addon_trailer_on == 1">
                <h3>Horse Trailer Details</h3>
                <p class="text-white"><small>Unspecified Item Sum Insured:
                        &pound;{{quote.addon_trailer_value - trailerTotalSumInsured}}</small></p>
                <mat-expansion-panel expanded
                    *ngFor="let trailer of quote.horse_details.trailor_items; let idx = index;">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Item {{idx + 1}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text"
                                    name="chasis_number_{{quote.horse_details.trailor_items.length + idx}}"
                                    placeholder="Chasis Number" class="form-control form-control-sm" required
                                    [(ngModel)]="trailer.chasis_number"
                                    [ngClass]="{ 'is-invalid': fd.submitted && chasis_number.invalid }"
                                    #chasis_number="ngModel">
                                <div *ngIf="fd.submitted && chasis_number.invalid" class="invalid-feedback">
                                    Chasis number is required.
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text"
                                    name="make_model_{{quote.horse_details.trailor_items.length + idx}}"
                                    placeholder="Make & Model" class="form-control form-control-sm" required
                                    [(ngModel)]="trailer.brand"
                                    [ngClass]="{ 'is-invalid': fd.submitted && trailer_brand.invalid }"
                                    #trailer_brand="ngModel">
                                <div *ngIf="fd.submitted && trailer_brand.invalid" class="invalid-feedback">
                                    Brand is required.
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text"
                                    name="year_of_manufacter_{{quote.horse_details.trailor_items.length + idx}}"
                                    placeholder="Year of Manufacter" class="form-control form-control-sm" required
                                    [(ngModel)]="trailer.manufactured_year"
                                    [ngClass]="{ 'is-invalid': fd.submitted && manufactured_year.invalid }"
                                    #manufactured_year="ngModel">
                                <div *ngIf="fd.submitted && manufactured_year.invalid" class="invalid-feedback">
                                    Year of manufacter is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text"
                                    name="trailer_purchase_date_{{quote.horse_details.trailor_items.length + idx}}"
                                    placeholder="Purchase Date" class="form-control form-control-sm datepicker" matInput
                                    (focus)="trailerPurchaseDateDatepicker.open()" required readonly
                                    [matDatepicker]="trailerPurchaseDateDatepicker" [(ngModel)]="trailer.purchase_date"
                                    [ngClass]="{ 'is-invalid': fd.submitted && trailer_purchase_date.invalid }"
                                    #trailer_purchase_date="ngModel">
                                <mat-datepicker-toggle [for]="trailerPurchaseDateDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #trailerPurchaseDateDatepicker></mat-datepicker>
                                <div *ngIf="fd.submitted && trailer_purchase_date.invalid" class="invalid-feedback">
                                    Purchase date is required.
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-success text-white">&pound;</span>
                                    </div>
                                    <input type="text"
                                        name="sum_insured_{{quote.horse_details.trailor_items.length + idx}}"
                                        placeholder="Trailer Sum Insured" class="form-control form-control-sm"
                                        appDecimalNumbers required [(ngModel)]="trailer.sum_insured"
                                        [ngClass]="{ 'is-invalid': fd.submitted && trailer_sum_insured.invalid }"
                                        #trailer_sum_insured="ngModel">
                                </div>
                                <div *ngIf="fd.submitted && trailer_sum_insured.invalid" class="invalid-feedback">
                                    Sum insured is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="idx>0">
                        <button type="button" class="btn btn-danger btn-sm"
                            (click)="removeTrailerItems(idx)">Remove</button>
                    </div>
                    <div class="form-group" *ngIf="idx==0">
                        <button type="button" class="btn btn-success btn-sm" (click)="addTrailerItems()">Add More
                            Items</button>
                    </div>
                </mat-expansion-panel>
            </div>


            <div class="form-group">
                <a (click)="backToQuoteDetailsBtn()" class="btn btn-default btn-gray mr-3">Back</a>
                <input type="submit" name="proceed" class="btn btn-success btn-gray"
                    value="Proceed to Statement of Fact">
            </div>
        </div>
        <div class="col-md-4">
            <app-calculations [indicativeCalculationsDetails]="indicativeCalculationsDetails"
                [quoteInfo]="quoteDetails"></app-calculations>
        </div>
    </div>
</form>